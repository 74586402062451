<template>
<div id="cloudsvg">
<svg data-name="cloudsvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500">
  <defs>

    <linearGradient id="linear-gradient-cloud" x1="218.18061" y1="401.98728" x2="263.16258" y2="285.60536" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#c3e0fc"/>
      <stop offset="0.99956" stop-color="#c3e4fc"/>
    </linearGradient>
    <linearGradient id="linear-gradient-cloud-2" x1="345.17105" y1="427.75354" x2="201.50021" y2="93.46991" gradientUnits="userSpaceOnUse">
      <stop offset="0.04212" stop-color="#5076e6"/>
      <stop offset="1" stop-color="#66a5ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-cloud-3" x1="300.7378" y1="438.53468" x2="136.67803" y2="157.83126" gradientUnits="userSpaceOnUse">
      <stop offset="0.03997" stop-color="#7297ff"/>
      <stop offset="1" stop-color="#80c0ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-cloud-4" x1="212.51194" y1="367.86834" x2="259.82299" y2="245.46039" gradientUnits="userSpaceOnUse">
      <stop offset="0.03997" stop-color="#4772d1" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#4772d1"/>
    </linearGradient>
  </defs>
  <g>
    <path class="cls-1" d="M79.59346,277.05845,171.44314,223.829,408.76877,354.23734a21.27053,21.27053,0,0,1,.38087,37.06875L347.116,428.276a69.06592,69.06592,0,0,1-67.70215.7267L80.0325,314.11286A21.27053,21.27053,0,0,1,79.59346,277.05845Z"/>
    <g>
      <g>
        <path class="cls-2" d="M378.14271,211.897v-.03569c0-39.358-27.81255-87.32169-62.11042-107.11652-17.38938-10.04886-33.08623-10.904-44.36482-4.20481l-.14229.08911-6.82417,3.9376-.0111.00639C246.69905,84.41072,229.25613,81.21618,218.5192,87.1772l-34.38662,19.74755a30.377,30.377,0,0,0-16.27276,4.29272L82.87893,160.01212c10.031-5.30938,23.30482-4.91735,38.02154,2.17379l25.26482-19.9195,6.96863-6.46676a47.05837,47.05837,0,0,1,8.02062,3.718,71.02938,71.02938,0,0,1,16.83735,13.95065c.07114.3386.12468.69493.21367,1.03342l1.08672.40975,14.34436-8.23321c10.18706-2.52928,22.68839-.26891,36.20288,7.53851,34.29811,19.7947,62.11042,67.7584,62.11042,107.11652v.03545h.01784c11.17152,7.53683,21.52305,17.95975,30.23583,29.93292,16.21344,22.25356,26.81458,49.88788,26.81458,74.22612,0,19.06431-6.50317,32.62306-17.10431,39.3223l85.961-49.32932c10.72584-6.62786,17.33609-20.258,17.33609-39.46484C435.211,278.60427,410.08892,233.40234,378.14271,211.897Z"/>
        <path class="cls-3" d="M293.25138,260.60471l.00089-.03979c0-39.36033-27.80654-87.32231-62.10768-107.12609-20.84662-12.0358-39.28025-10.88052-50.545.69721a72.66674,72.66674,0,0,0-18.1456-15.39132c-22.34937-12.90341-40.47539-2.64074-40.58591,22.91145-31.96083-15.41405-57.079.79157-57.079,38.25085,0,39.47347,27.88651,87.57333,62.28625,107.434l160.9646,92.933c34.39975,19.8607,62.28625,3.9614,62.28625-35.51207C350.32613,327.30429,325.21018,282.097,293.25138,260.60471Z"/>
        <path class="cls-4" d="M321.95384,408.31753l-.13819.03943c-9.72039,1.60029-21.31733-.88908-33.78393-8.08043L173.00817,333.85446,160.31453,179.31038l68.11114,85.05968,40.18127-27.67622Z"/>
      </g>
      <g>
        <path class="cls-5" d="M196.17238,244.855l-34.894-64.09846c-1.68234-3.09041-4.49972-4.803-6.30546-3.9769l.0895-.07406-.32424.18666c-.05009.03088-.10909.04-.15729.07545l-.0949.06979-20.96906,12.07191,24.93993,149.83615S179.658,326.70716,179.87076,326.531l.114-.066-.02059-.00992a3.90011,3.90011,0,0,0,1.09186-2.98484V258.666l14.94515-8.59726C197.20172,249.41811,197.48843,247.27269,196.17238,244.855Z"/>
        <path class="cls-6" d="M174.97215,257.051l-34.894-64.09847c-1.8051-3.316-4.93312-5.08544-6.69756-3.78877l-33.4791,24.60315c-1.88527,1.38545-.3542,6.13959,2.50974,7.79309l12.85473,7.42169v80.94064a11.43442,11.43442,0,0,0,5.18931,8.95075l34.2111,19.75179c2.866,1.6547,5.18938.33014,5.18938-2.95861V254.72565l12.65186,7.30455C175.40358,263.70217,176.92246,260.6336,174.97215,257.051Z"/>
      </g>
      <g>
        <path class="cls-5" d="M282.35886,329.20083,269.707,321.89628V240.9557a11.43406,11.43406,0,0,0-5.18939-8.95075l-34.21128-19.75181a3.715,3.715,0,0,0-3.55851-.41525v-.01845l-.08888.05109-.04117.02385-21.21924,12.24577,4.54341,64.36743v-.04782l-21.349,12.32071,39.828,72.46951s21.43716-12.369,21.50846-12.41774l34.89391-23.80641C286.77348,335.69522,285.25457,330.87276,282.35886,329.20083Z"/>
        <path class="cls-6" d="M263.53057,349.31437l-34.894,23.8064c-1.80511,1.23161-4.93313-.61085-6.69757-3.94492l-33.4791-63.26148c-1.88527-3.56237-.3542-6.5486,2.50974-4.8951l12.85473,7.42168V227.50031c0-3.28875,2.32335-4.61331,5.18931-2.95865l34.2111,19.75179a11.43444,11.43444,0,0,1,5.18938,8.95079v80.94057l12.65186,7.30455C263.962,343.16133,265.48088,347.98375,263.53057,349.31437Z"/>
      </g>
    </g>
  </g>
</svg>


</div>

</template>

<script>
export default {
   name: 'cloudsvg',
   created(){

   }
}
</script>

<style scoped>
      #cloudsvg .cls-1 {
        fill: url(#linear-gradient-cloud);
      }

      #cloudsvg .cls-2 {
        fill: url(#linear-gradient-cloud-2);
      }

      #cloudsvg .cls-3 {
        fill: url(#linear-gradient-cloud-3);
      }

      #cloudsvg .cls-4 {
        fill: url(#linear-gradient-cloud-4);
      }

      #cloudsvg .cls-5 {
        fill: #abd5ff;
      }

      #cloudsvg .cls-6 {
        fill: #cae8ff;
      }

</style>