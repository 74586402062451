<template>
<div id="pcsvg">
<svg data-name="pc desktop" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500">
  <defs>

    <linearGradient id="linear-gradient-pc" x1="289.32892" y1="436.22887" x2="376.53049" y2="436.22887" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#c3e0fc"/>
      <stop offset="0.99956" stop-color="#c3e4fc"/>
    </linearGradient>
    <linearGradient id="linear-gradient-pc-2" x1="51.40356" y1="369.50255" x2="323.29104" y2="369.50255" xlink:href="#linear-gradient-pc"/>
    <linearGradient id="linear-gradient-pc-3" x1="67.04062" y1="360.50494" x2="338.9281" y2="360.50494" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#69f"/>
      <stop offset="0.95788" stop-color="#547cf2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-pc-4" x1="67.04722" y1="339.59252" x2="338.92812" y2="339.59252" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#80c0ff"/>
      <stop offset="0.96003" stop-color="#7297ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-pc-5" x1="295.32731" y1="433.22967" x2="382.52888" y2="433.22967" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#518de6"/>
      <stop offset="0.95788" stop-color="#4769cc"/>
    </linearGradient>
    <linearGradient id="linear-gradient-pc-6" x1="365.72447" y1="447.06925" x2="307.42009" y2="411.07889" gradientUnits="userSpaceOnUse">
      <stop offset="0.03997" stop-color="#7297ff"/>
      <stop offset="1" stop-color="#80c0ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-pc-7" x1="226.13981" y1="305.05719" x2="373.97282" y2="305.05719" xlink:href="#linear-gradient-pc"/>
    <linearGradient id="linear-gradient-pc-8" x1="234.05771" y1="307.67733" x2="381.89073" y2="307.67733" xlink:href="#linear-gradient-pc-5"/>
    <linearGradient id="linear-gradient-pc-9" x1="234.05769" y1="288.05174" x2="381.8907" y2="288.05174" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#66a5ff"/>
      <stop offset="0.95788" stop-color="#5076e6"/>
    </linearGradient>
    <linearGradient id="linear-gradient-pc-10" x1="199.05142" y1="193.48177" x2="436.59644" y2="193.48177" xlink:href="#linear-gradient-pc-9"/>
    <linearGradient id="linear-gradient-pc-11" x1="195.84633" y1="199.99787" x2="415.88047" y2="199.99787" xlink:href="#linear-gradient-pc-4"/>
    <linearGradient id="linear-gradient-pc-12" x1="205.19742" y1="199.99713" x2="406.52938" y2="199.99713" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#487dcc"/>
      <stop offset="0.95788" stop-color="#3e5cb3"/>
    </linearGradient>
  </defs>
  <g>
    <g>
      <path class="cls-1" d="M375.8412,427.1108l.01523-.00437c-2.63335-8.92717-16.3141-20.173-33.33329-21.25171-15.79014-1.0007-32.05035,6.32592-42.1323,15.64491-17.18536,15.88484-11.604,32.61507-1.72068,38.6766,14.39483,8.82049,31.14865,8.561,47.41131-.0003l21.38444-12.34507A18.10631,18.10631,0,0,0,375.8412,427.1108Z"/>
      <path class="cls-2" d="M323.28862,404.69118l.00242-28.09509-19.39072,13.57457L127.98487,288.5674l-35.96,2.16068L55.23156,317.94762a9.4345,9.4345,0,0,0-3.82345,7.58045l-.00455,10.41036v.03228l.00362-.00241a5.92334,5.92334,0,0,0,2.98883,5.161l186.82185,107.8285a11.05177,11.05177,0,0,0,11.01526.01954L320.301,410.01157A5.90342,5.90342,0,0,0,323.28862,404.69118Z"/>
      <path class="cls-3" d="M338.92568,395.69359l.00242-28.09509-19.39072,13.57457L143.62193,279.56981,80.34748,315.85272l-13.30023-4.07566-.00663,15.16378v.03228l.00362-.00241a5.92337,5.92337,0,0,0,2.98883,5.161l186.822,107.82858a11.052,11.052,0,0,0,11.01513.01946L335.938,401.014A5.90342,5.90342,0,0,0,338.92568,395.69359Z"/>
      <path class="cls-4" d="M138.40086,254.45323,70.015,305.70075a7.4132,7.4132,0,0,0,.73985,12.35281l185.9569,107.32921a9.88429,9.88429,0,0,0,10.86356-.64728l68.38128-51.17807a7.41319,7.41319,0,0,0-.73427-12.35445L149.27185,253.80375A9.88423,9.88423,0,0,0,138.40086,254.45323Z"/>
      <g class="cls-5">
        <path class="cls-6" d="M145.251,287.67935l-13.32442-7.71818a1.48264,1.48264,0,0,1-.14766-2.46814l12.91634-9.70814a1.48262,1.48262,0,0,1,1.634-.09776l13.32442,7.71817a1.48264,1.48264,0,0,1,.14766,2.46814L146.885,287.58159A1.48266,1.48266,0,0,1,145.251,287.67935Z"/>
        <path class="cls-6" d="M167.77121,300.72418,154.44679,293.006a1.48264,1.48264,0,0,1-.14766-2.46814l12.91634-9.70815a1.48264,1.48264,0,0,1,1.634-.09775l13.32442,7.71817a1.48264,1.48264,0,0,1,.14766,2.46814l-12.91634,9.70815A1.48264,1.48264,0,0,1,167.77121,300.72418Z"/>
        <path class="cls-6" d="M190.29142,313.769,176.967,306.05085a1.48264,1.48264,0,0,1-.14766-2.46814l12.91634-9.70815a1.48264,1.48264,0,0,1,1.634-.09775l13.32442,7.71817a1.48264,1.48264,0,0,1,.14766,2.46814l-12.91634,9.70815A1.48264,1.48264,0,0,1,190.29142,313.769Z"/>
        <path class="cls-6" d="M212.81163,326.81386l-13.32442-7.71817a1.48264,1.48264,0,0,1-.14766-2.46814l12.91634-9.70815a1.48266,1.48266,0,0,1,1.634-.09776l13.32442,7.71818a1.48264,1.48264,0,0,1,.14766,2.46814l-12.91634,9.70814A1.48262,1.48262,0,0,1,212.81163,326.81386Z"/>
        <path class="cls-6" d="M235.33184,339.8587l-13.32442-7.71818a1.48263,1.48263,0,0,1-.14766-2.46813l12.91634-9.70815a1.48266,1.48266,0,0,1,1.634-.09776l13.32442,7.71818a1.48263,1.48263,0,0,1,.14766,2.46813l-12.91634,9.70815A1.48266,1.48266,0,0,1,235.33184,339.8587Z"/>
        <path class="cls-6" d="M257.85205,352.90354l-13.32442-7.71818a1.48264,1.48264,0,0,1-.14766-2.46814l12.91634-9.70814a1.48262,1.48262,0,0,1,1.634-.09776l13.32442,7.71817a1.48264,1.48264,0,0,1,.14766,2.46814L259.486,352.80578A1.48266,1.48266,0,0,1,257.85205,352.90354Z"/>
        <path class="cls-6" d="M302.89247,378.99321,289.56805,371.275a1.48264,1.48264,0,0,1-.14766-2.46814l12.91634-9.70815a1.48264,1.48264,0,0,1,1.634-.09775l13.32442,7.71817a1.48264,1.48264,0,0,1,.14766,2.46814l-12.91634,9.70815A1.48264,1.48264,0,0,1,302.89247,378.99321Z"/>
        <path class="cls-6" d="M123.62783,303.9317l-13.32443-7.71818a1.48264,1.48264,0,0,1-.14766-2.46813l12.91635-9.70815a1.48263,1.48263,0,0,1,1.63395-.09776l13.32442,7.71818a1.48263,1.48263,0,0,1,.14767,2.46813l-12.91635,9.70815A1.48264,1.48264,0,0,1,123.62783,303.9317Z"/>
        <path class="cls-6" d="M146.148,316.97653l-13.32443-7.71817a1.48265,1.48265,0,0,1-.14766-2.46814l12.91635-9.70815a1.48262,1.48262,0,0,1,1.63395-.09775l13.32442,7.71817a1.48264,1.48264,0,0,1,.14767,2.46814L147.782,316.87878A1.48262,1.48262,0,0,1,146.148,316.97653Z"/>
        <path class="cls-6" d="M168.66825,330.02137l-13.32442-7.71817a1.48264,1.48264,0,0,1-.14767-2.46814l12.91635-9.70815a1.48262,1.48262,0,0,1,1.634-.09775l13.32443,7.71817a1.48265,1.48265,0,0,1,.14766,2.46814l-12.91635,9.70815A1.48262,1.48262,0,0,1,168.66825,330.02137Z"/>
        <path class="cls-6" d="M279.81651,346.05386l-20.05393,15.07288a1.48264,1.48264,0,0,1-1.634.09776l-20.82151-12.06087a1.48264,1.48264,0,0,0-1.63395.09776l-12.91638,9.70817a1.48263,1.48263,0,0,0,.14767,2.46813l35.84462,20.763a1.48264,1.48264,0,0,0,1.634-.09775l34.53954-25.96052a1.48263,1.48263,0,0,0-.14766-2.46813l-13.32445-7.71819A1.48264,1.48264,0,0,0,279.81651,346.05386Z"/>
        <path class="cls-6" d="M191.18846,343.06621,177.864,335.348a1.48264,1.48264,0,0,1-.14767-2.46814l12.91635-9.70815a1.48264,1.48264,0,0,1,1.634-.09775l13.32442,7.71817a1.48265,1.48265,0,0,1,.14766,2.46814l-12.91635,9.70814A1.48261,1.48261,0,0,1,191.18846,343.06621Z"/>
        <path class="cls-6" d="M213.70867,356.11105l-13.32442-7.71818a1.48263,1.48263,0,0,1-.14767-2.46813l12.91635-9.70815a1.48266,1.48266,0,0,1,1.634-.09776l13.32442,7.71818a1.48264,1.48264,0,0,1,.14766,2.46813l-12.91635,9.70815A1.48262,1.48262,0,0,1,213.70867,356.11105Z"/>
        <path class="cls-6" d="M281.2693,395.24556l-13.32442-7.71817a1.48264,1.48264,0,0,1-.14767-2.46814l12.91635-9.70815a1.48264,1.48264,0,0,1,1.634-.09775l13.32442,7.71817a1.48264,1.48264,0,0,1,.14766,2.46814l-12.91634,9.70815A1.48264,1.48264,0,0,1,281.2693,395.24556Z"/>
        <path class="cls-6" d="M102.00465,320.18405l-13.32442-7.71818a1.48263,1.48263,0,0,1-.14766-2.46813l12.91634-9.70815a1.48266,1.48266,0,0,1,1.634-.09776L116.40729,307.91a1.48263,1.48263,0,0,1,.14766,2.46813l-12.91634,9.70815A1.48266,1.48266,0,0,1,102.00465,320.18405Z"/>
        <path class="cls-6" d="M192.08549,372.3634l-80.885-46.85269a1.48264,1.48264,0,0,1-.14766-2.46814l12.91634-9.70814a1.48262,1.48262,0,0,1,1.634-.09776l80.88505,46.85269a1.48264,1.48264,0,0,1,.14767,2.46814l-12.91635,9.70814A1.48262,1.48262,0,0,1,192.08549,372.3634Z"/>
        <path class="cls-6" d="M214.6057,385.40824l-13.32442-7.71818a1.48263,1.48263,0,0,1-.14766-2.46813L214.05,365.51378a1.48264,1.48264,0,0,1,1.63395-.09776l13.32442,7.71818a1.48263,1.48263,0,0,1,.14767,2.46813l-12.91635,9.70815A1.48266,1.48266,0,0,1,214.6057,385.40824Z"/>
        <path class="cls-6" d="M237.12591,398.45308l-13.32442-7.71818a1.48264,1.48264,0,0,1-.14766-2.46814l12.91635-9.70814a1.48261,1.48261,0,0,1,1.63395-.09776l13.32442,7.71817a1.48264,1.48264,0,0,1,.14767,2.46814l-12.91635,9.70815A1.48266,1.48266,0,0,1,237.12591,398.45308Z"/>
        <path class="cls-6" d="M259.64612,411.49791l-13.32442-7.71817a1.48265,1.48265,0,0,1-.14766-2.46814l12.91635-9.70815a1.48262,1.48262,0,0,1,1.63395-.09775l13.32442,7.71817a1.48264,1.48264,0,0,1,.14767,2.46814l-12.91635,9.70815A1.48264,1.48264,0,0,1,259.64612,411.49791Z"/>
      </g>
    </g>
    <g>
      <path class="cls-7" d="M381.83959,424.1116l.01524-.00437c-2.63336-8.92717-16.3141-20.173-33.33329-21.25171-15.79015-1.0007-32.05036,6.32593-42.1323,15.64491-17.18537,15.88485-11.604,32.61507-1.72068,38.6766,14.39482,8.82049,31.14865,8.561,47.4113-.0003l21.38444-12.34507A18.1063,18.1063,0,0,0,381.83959,424.1116Z"/>
      <path class="cls-8" d="M382.05328,425.02087a17.8429,17.8429,0,0,1-8.59926,11.614L352.07924,448.9655c-16.25965,8.57468-33.0134,8.82172-47.41975,0a19.77994,19.77994,0,0,1-8.92051-13.02241c1.0378-5.53516,4.29963-11.56457,10.65029-17.44572,10.082-9.316,26.34149-16.63037,42.13163-15.64188,17.02568,1.08727,30.69059,12.33059,33.33465,21.2511h-.02474A6.14877,6.14877,0,0,1,382.05328,425.02087Z"/>
      <path class="cls-9" d="M362.2045,406.01034c-4.05358-1.46115-12.70756-.98316-18.15626,2.13324-4.54844,2.01944,1.901,7.74613,8.37694,5.18926,4.82736-2.28171,14.46664-4.38588,18.81393-2.3267A36.40812,36.40812,0,0,0,362.2045,406.01034Z"/>
    </g>
    <g>
      <g>
        <path class="cls-10" d="M231.90117,278.60983,254.28383,265.699a34.5444,34.5444,0,0,1,34.53036.00559l79.4005,45.83419a11.5148,11.5148,0,0,1-.00032,19.94525L345.82,344.41032a34.54441,34.54441,0,0,1-34.54727-.00528l-79.37751-45.84987A11.5148,11.5148,0,0,1,231.90117,278.60983Z"/>
        <path class="cls-11" d="M381.89073,316.18872l-.00363-11.86129-147.82939-33.3631v12.60872l.02307-.00914a11.3994,11.3994,0,0,0,5.73233,10.345l79.37745,45.84989a34.54445,34.54445,0,0,0,34.54733.00527l22.39438-12.92628a11.40451,11.40451,0,0,0,5.71418-10.68595Z"/>
        <path class="cls-12" d="M239.81905,261.60438l22.38266-12.91081a34.5444,34.5444,0,0,1,34.53036.00559l79.4005,45.8342a11.51479,11.51479,0,0,1-.00032,19.94524l-22.39438,12.92627a34.54439,34.54439,0,0,1-34.54727-.00528l-79.37751-45.84986A11.5148,11.5148,0,0,1,239.81905,261.60438Z"/>
      </g>
      <g>
        <path class="cls-13" d="M425.474,160.46379,227.68123,46.30322a7.32309,7.32309,0,0,0-7.12787-.141l.00187-.01089-.12768.07356c-.10167.05564-.20147.11256-.30045.17277L199.05142,58.52669l17.51093,8.29477v129.558a22.23978,22.23978,0,0,0,11.11654,19.25822L412.41564,322.33587l-.02928,19.32229,20.44175-11.7479c.03853-.02179.07672-.04346.11491-.06595l.14559-.08375-.00972-.00644a7.32749,7.32749,0,0,0,3.51755-6.2947V179.72529A22.23981,22.23981,0,0,0,425.474,160.46379Z"/>
        <path class="cls-14" d="M195.84633,208.28571V64.63a7.4132,7.4132,0,0,1,11.11894-6.42051L404.7581,172.37008a22.23959,22.23959,0,0,1,11.12237,19.26153v143.7341a7.4132,7.4132,0,0,1-11.12085,6.41941L206.963,227.54394A22.23961,22.23961,0,0,1,195.84633,208.28571Z"/>
        <path class="cls-15" d="M205.19742,209.98047V73.73235a2.47106,2.47106,0,0,1,3.70631-2.14017L402.82192,183.51642a7.41319,7.41319,0,0,1,3.70746,6.42051v136.325a2.47107,2.47107,0,0,1-3.707,2.1398L208.903,216.39988A7.41319,7.41319,0,0,1,205.19742,209.98047Z"/>
      </g>
    </g>
  </g>
</svg>


</div>

</template>

<script>
export default {
   name: 'pcsvg',
   created(){

   }
}
</script>

<style scoped>

      #pcsvg .cls-1 {
        fill: url(#linear-gradient-pc);
      }

      #pcsvg .cls-2 {
        fill: url(#linear-gradient-pc-2);
      }

      #pcsvg .cls-3 {
        fill: url(#linear-gradient-pc-3);
      }

      #pcsvg .cls-4 {
        fill: url(#linear-gradient-pc-4);
      }

      #pcsvg .cls-5 {
        opacity: 0.7;
      }

      #pcsvg .cls-6, #pcsvg .cls-9 {
        fill: #cae8ff;
      }

      #pcsvg .cls-7 {
        fill: url(#linear-gradient-pc-5);
      }

      #pcsvg .cls-8 {
        fill: url(#linear-gradient-pc-6);
      }

      #pcsvg .cls-9 {
        opacity: 0.5;
      }

      #pcsvg .cls-10 {
        fill: url(#linear-gradient-pc-7);
      }

      #pcsvg .cls-11 {
        fill: url(#linear-gradient-pc-8);
      }

      #pcsvg .cls-12 {
        fill: url(#linear-gradient-pc-9);
      }

      #pcsvg .cls-13 {
        fill: url(#linear-gradient-pc-10);
      }

      #pcsvg .cls-14 {
        fill: url(#linear-gradient-pc-11);
      }

      #pcsvg .cls-15 {
        fill: url(#linear-gradient-pc-12);
      }
   
</style>