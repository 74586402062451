<template>
  <div id="dataprotection" class="margin-0">
    <h1>Erklärung zur Informationspflicht (Datenschutzerklärung)</h1>
    <p>
      Um Ihre persönlichen Daten zu schützen, werden Informationen ausschließlich 
      aufgrund der gesetzlichen Bestimmungen (DSGVO, TKG 2003) verarbeitet. Lesen Sie
      hier die wichtigsten Aspekte der Datenverarbeitung auf dieser Website.
    </p>
    <h2>Kontakt mit uns</h2>
    <p>
      Eine über das Formular auf der Website oder per Email eingereichte Anfrage
      wird samt ihren eingegebenen Daten für Anschlussfragen sechs Monate gespeichert.
      Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
    </p>
    <h2>Server-Log-Files</h2>
    <p>
      Der Provider der Seiten erhebt und speichert automatisch Informationen in
      so genannten Server-Log Files, die Ihr Browser automatisch an uns
      übermittelt. Diese sind:
    </p>
    <ul>
      <li>Hostname des zugreifenden Rechners</li>
      <li>Uhrzeit der Serveranfrage</li>
      <li>Browsertyp und Browserversion</li>
      <li>verwendetes Betriebssystem</li>
      <li>Referrer URL</li>
    </ul>

    <p>
      Diese Daten sind anonymisiert und werden nicht mit anderen Datenquellen
      zusammengeführt. Wenn wir konkrete Anhaltspunkte für eine rechtswiedrige Nutzung
      haben, behalten wir uns vor, diese Daten nachträglich zu überprüfen.
    </p>
    <h2>Services</h2>
    <p>
      Für die einwandfreie Funktion dieser Website verwenden wir in folgenden Bereichen
      Services
      von Drittanbietern:
    </p>

    <ul>
      <li>
        Webhosting (Google Hosting) zur Speicherung von Daten, zur Ausführung der Website
      </li>
      <li>
        Webanalyse (Google Analytics) zur statistischen Auswertung der Nutzung
        unserer Website
      </li>
    </ul>
    <h2>Skripte</h2>
    <p>
      Ein Skript ist eine Datei mit Befehlen, welche Funktionen zur Verfügung stellt,
      Daten verarbeitet und unsere Website an die Services von Drittanbietern
      wie z.B. die Webanalyse-Produkte Google Analytics anbindet. Zum Betrieb
      unserer Website verwenden wir Skripte von folgenden Drittanbietern:
    </p>
    <ul>
      <li>Google Analytics zur Webanalyse</li>
    </ul>

    <p>
      Da diese Website auch ohne diese Skripte einwandfrei funktioniert,
      werden diese erst nach Einwilligung gestartet.
    </p>

    <h2>Cookies</h2>
    <p>
      Wir verwenden Cookies. EIn Cookie ist eine kleine Textdatei, die im Browser 
      ihres Endgerätes gespeichert werden. Sie sind nicht schadhaft und haben lediglich
      die Aufgabe, die Nutzererfahrung reibungsloser zu gestalten. Manche Cookies haben 
      Ablaufdaten, und andere bleiben auf dem Gerät gespeichert, bis sie gelöscht werden.
      Eine Funktion, die Cookies erfüllen ist es, das Gerät beim nächsten Besuch wieder zu erkennen.
      Wenn Sie dies nicht
      wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das
      Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei
      der Deaktivierung von Cookies kann die Funktionalität unserer Website
      eingeschränkt sein. Zum Betrieb unserer Website verwenden wir zudem
      Cookies von folgenden Drittanbietern:
    </p>
    <ul>
      <li>Google Analytics zur Webanalyse</li>
    </ul>

    <p>
     Da diese Website auch ohne diese Skripte einwandfrei funktioniert,
      werden diese erst nach Einwilligung gestartet.
    </p>

    <h2>WEBANALYSE</h2>
    <p>
      Um die Nutzererfahrung optimal zu gestalten und die Website stetig zu optimieren,
       führen wir eine statistische
      Auswertung der Benutzung unserer Website mit Hilfe des
      Webanalyse-Produktes Google Analytics durch. Das Produkt hilft uns zum Beispiel dabei zu erkennen,
      welche Inhalte für Benutzer besonders relevant sind. Diese Skripte werden erst nach Einwilligung durch den Nutzer gestartet.
      Sie können eine bereits erteilte Einwilligung zur Erfassung
      Ihrer Nutzerdaten zur Webanalyse vollständig widerrufen, indem Sie auf den
      folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, das die
      Erfassung Ihrer Daten bei weiteren Besuchen dieser Website verhindert:  
      <span class="clickable blue-t" @click="removeCookies"
        > Widerruf der Einwilligung</span
      >
    </p>
    <h2>Google Analytics</h2>
    <p>
      Zum Zweck der Webanalyse verwendet diese Website Google Analytics 
      von Google Inc, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA
      („Google“). Wir haben dazu mit Google einen Vertrag zur
      Auftragsverarbeitung abgeschlossen. Die Übermittlung der Daten in die USA
      erfolgt auf Grundlage der Standardklauseln. Google Analytics verwendet
      Cookies zur Speicherung von Daten über den Website-Benutzer und zur
      Analyse der Benutzung der Website durch die Website-Benutzer. Google
      Analytics verwendet Scripte zur Verarbeitung von Daten über den
      Website-Benutzer. Beim Aufruf unserer Webseite stellen diese Skripte eine
      Verbindung zu den Servern von Google her und übermitteln Daten an teils in
      den USA befindliche Server von Google. Diese Website nutzt die Funktion
      „Aktivierung der IP-Anonymisierung“. Dadurch wird Ihre IP-Adresse von
      Google innerhalb von Mitgliedstaaten der Europäischen Union oder in
      anderen Vertragsstaaten des Abkommens über den Europäischen
      Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
      IP-Adresse an einen Server von Google in den USA übertragen und dort
      gekürzt. Laut Angaben von Google wird Google die erhobenen Daten benutzen,
      um die Nutzung der Website auszuwerten, um Reports über die
      Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung
      und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch
      wird Google diese Informationen gegebenenfalls an Dritte übertragen,
      sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten
      im Auftrag von Google verarbeiten. Detaillierte Informationen zum Umgang
      mit Nutzerdaten bei Google Analytics finden Sie in der
      Datenschutzerklärung von Google bzw. von Google Analytics.
    </p>
    <h2>Google Analytics deaktivieren / Widerruf der Einwilligung</h2>

    <p>
      Google Analytics startet erst nach ihrer Einwilligung zur Webanalyse. 
      Hier können Sie eine bereits erteilte Einwilligung wiederrufen. Es werden alle Cookies gelöscht:
      <span class="clickable blue-t" @click="removeCookies"
        >Widerruf der Einwilligung</span
      >
    </p>
    <h2>Ihre Rechte</h2>
    <p>
      Bezüglich der Datenspeicherung haben Sie die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung,
      Datenübertragbarkeit, Widerruf und Widerspruch. Wenn Sie der Ansicht sind, dass
      die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
      datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden
      sind, können Sie sich bei uns oder der Datenschutzbehörde Beschwerde einreichen.
    </p>

    <p>
      Kontaktieren Sie uns:<br>
      Florian Bachl Software & Webdesign <br />

      hello@florianbachl.at<br />

      06506779393<br />
    </p>
  </div>
</template>

<script>
export default {
  name: "dataprotection",
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  components: {},
  computed: {},
  watch: {},
  methods: {
    removeCookies() {
      this.$store.dispatch("setCookieConsent", false);
    },
  },
};
</script>
<style scoped>
#dataprotection {
  padding: 1em;
  padding-top: 10em;
}

p,
ul,
h1 {
  padding-bottom: 2em;
}

li {
  margin-left: 2em;
}
@media screen and (max-width: 1100px) {
  .margin-0 {
    margin: 0px;
    padding: 1em;
    max-width: calc(100% - 2em);
  }
}
@media screen and (max-width: 450px) {
  h1 {
    font-size: 1.5em;
  }
}
</style>
