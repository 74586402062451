<template>
  <div id="rocketsvg">
    <svg
      
      data-name="Rocket"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1000 1000"
    >
      <defs>
        <linearGradient
          id="linear-gradient-rocket"
          x1="98.71659"
          y1="703.65671"
          x2="931.28341"
          y2="703.65671"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00503" stop-color="#4d4dff" stop-opacity="0.7" />
          <stop offset="0.9983" stop-color="#57c3f9" stop-opacity="0.3" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-2"
          x1="617.53379"
          y1="577.97563"
          x2="859.60884"
          y2="577.97563"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#3232a6" />
          <stop offset="0.99534" stop-color="#457be6" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-3"
          x1="518.67765"
          y1="539.93612"
          x2="935.6128"
          y2="539.93612"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#3232a6" />
          <stop offset="0.99534" stop-color="#36c" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-4"
          x1="575.08892"
          y1="456.37645"
          x2="819.82646"
          y2="539.99511"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#4d6bff" />
          <stop offset="0.9983" stop-color="#57a8f9" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-5"
          x1="638.27394"
          y1="162.6554"
          x2="638.27394"
          y2="296.78926"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.20211" stop-color="#73acff" />
          <stop offset="1" stop-color="#6394db" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-6"
          x1="624.80218"
          y1="226.42418"
          x2="600.95227"
          y2="211.38837"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#f97d87" />
          <stop offset="0.8846" stop-color="#f29191" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-7"
          x1="614.58224"
          y1="152.80677"
          x2="619.62841"
          y2="152.80677"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#f28686" />
          <stop offset="1" stop-color="#f97d87" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-8"
          x1="627.66766"
          y1="168.63694"
          x2="627.66766"
          y2="139.04942"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#f29191" />
          <stop offset="1" stop-color="#f97d87" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-9"
          x1="637.10626"
          y1="153.01944"
          x2="642.15243"
          y2="153.01944"
          xlink:href="#linear-gradient-rocket-7"
        />
        <linearGradient
          id="linear-gradient-rocket-10"
          x1="223.44076"
          y1="764.63625"
          x2="806.55924"
          y2="764.63625"
          xlink:href="#linear-gradient-rocket-3"
        />
        <linearGradient
          id="linear-gradient-rocket-11"
          x1="223.44035"
          y1="672.54791"
          x2="806.55921"
          y2="672.54791"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#4d4dff" />
          <stop offset="0.9983" stop-color="#57c3f9" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-12"
          x1="317.18869"
          y1="671.12484"
          x2="712.80861"
          y2="671.12484"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0126" stop-color="#5983ff" />
          <stop offset="0.99857" stop-color="#89d4f9" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-13"
          x1="351.07779"
          y1="643.8435"
          x2="678.91951"
          y2="643.8435"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.02202" stop-color="#80aaff" />
          <stop offset="1" stop-color="#aee0f9" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-14"
          x1="617.0782"
          y1="599.66937"
          x2="730.90155"
          y2="599.66937"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#395cd9" />
          <stop offset="1" stop-color="#366dd9" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-15"
          x1="364.58704"
          y1="643.8435"
          x2="665.41026"
          y2="643.8435"
          xlink:href="#linear-gradient-rocket-14"
        />
        <radialGradient
          id="radial-gradient"
          cx="513.8448"
          cy="798.79117"
          r="172.47734"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.05756" stop-color="#a3e1ff" />
          <stop offset="0.32209" stop-color="#1596e6" />
          <stop offset="0.85115" stop-color="#1d2b73" />
        </radialGradient>
        <linearGradient
          id="linear-gradient-rocket-16"
          x1="427.0794"
          y1="517.80682"
          x2="427.0794"
          y2="694.09179"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#3c6af2" />
          <stop offset="1" stop-color="#4259cc" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-17"
          x1="603.95485"
          y1="493.43843"
          x2="603.95485"
          y2="619.32037"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#57c3f9" />
          <stop offset="1" stop-color="#4d89ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-18"
          x1="602.9179"
          y1="505.36339"
          x2="602.9179"
          y2="681.64836"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#3c88f2" />
          <stop offset="1" stop-color="#4e69f2" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-19"
          x1="515.00016"
          y1="618.90969"
          x2="515.00016"
          y2="213.60051"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#bde6ff" />
          <stop offset="0.2996" stop-color="#b2d6ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-20"
          x1="514.99865"
          y1="538.20022"
          x2="514.99865"
          y2="713.79628"
          xlink:href="#linear-gradient-rocket-18"
        />
        <linearGradient
          id="linear-gradient-rocket-21"
          x1="483.2763"
          y1="423.88958"
          x2="483.2763"
          y2="489.28965"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.38796" stop-color="#355ea0" />
          <stop offset="1" stop-color="#2e528c" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-22"
          x1="546.29998"
          y1="422.19005"
          x2="546.29998"
          y2="489.28965"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.10984" stop-color="#3f72bc" />
          <stop offset="1" stop-color="#355ea0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-23"
          x1="451.27574"
          y1="423.46539"
          x2="577.27305"
          y2="423.46539"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#355ea0" />
          <stop offset="1" stop-color="#4b86db" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-24"
          x1="514.99679"
          y1="221.65114"
          x2="514.99679"
          y2="623.99005"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00503" stop-color="#8fc8ff" />
          <stop offset="0.68041" stop-color="#c2e0ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-25"
          x1="514.99865"
          y1="261.57381"
          x2="514.99865"
          y2="364.78315"
          xlink:href="#linear-gradient-rocket-18"
        />
        <linearGradient
          id="linear-gradient-rocket-26"
          x1="514.99865"
          y1="264.80038"
          x2="514.99865"
          y2="344.06369"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#1b3f8c" />
          <stop offset="1" stop-color="#1c3059" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-28"
          x1="473.66286"
          y1="198.79178"
          x2="556.33105"
          y2="198.79178"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#2e528c" />
          <stop offset="0.61204" stop-color="#355ea0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-29"
          x1="498.39333"
          y1="198.29239"
          x2="548.16705"
          y2="82.1537"
          xlink:href="#linear-gradient-rocket-18"
        />
        <linearGradient
          id="linear-gradient-rocket-30"
          x1="258.19105"
          y1="297.11517"
          x2="394.37748"
          y2="383.52788"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01796" stop-color="#66f" />
          <stop offset="0.9983" stop-color="#4ba2f9" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-31"
          x1="291.57606"
          y1="290.98872"
          x2="387.8623"
          y2="190.0219"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#4e69f2" />
          <stop offset="1" stop-color="#3c88f2" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-32"
          x1="304.67265"
          y1="286.98308"
          x2="389.05859"
          y2="286.98308"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01796" stop-color="#667fff" />
          <stop offset="0.9983" stop-color="#4ba2f9" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-33"
          x1="304.67265"
          y1="305.77881"
          x2="389.05859"
          y2="305.77881"
          xlink:href="#linear-gradient-rocket-32"
        />
        <linearGradient
          id="linear-gradient-rocket-34"
          x1="304.67265"
          y1="324.57455"
          x2="389.05859"
          y2="324.57455"
          xlink:href="#linear-gradient-rocket-32"
        />
        <linearGradient
          id="linear-gradient-rocket-35"
          x1="304.67265"
          y1="356.37922"
          x2="344.00494"
          y2="356.37922"
          xlink:href="#linear-gradient-rocket-32"
        />
        <linearGradient
          id="linear-gradient-rocket-36"
          x1="364.48298"
          y1="382.49756"
          x2="389.05859"
          y2="382.49756"
          xlink:href="#linear-gradient-rocket-32"
        />
        <linearGradient
          id="linear-gradient-rocket-37"
          x1="778.01504"
          y1="808.46712"
          x2="778.01504"
          y2="786.2383"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1154" stop-color="#f29191" />
          <stop offset="1" stop-color="#f97d87" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-38"
          x1="745.43681"
          y1="566.83277"
          x2="785.56527"
          y2="566.83277"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#f97d87" />
          <stop offset="1" stop-color="#f29191" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-39"
          x1="692.90076"
          y1="574.67243"
          x2="659.65428"
          y2="528.98173"
          gradientTransform="translate(87.29553)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#a6d1ff" />
          <stop offset="0.7395" stop-color="#d4e9ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-40"
          x1="656.96639"
          y1="531.65103"
          x2="658.69347"
          y2="630.09455"
          gradientTransform="translate(87.29553)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#d4e9ff" />
          <stop offset="1" stop-color="#a6d1ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-41"
          x1="740.34877"
          y1="524.18759"
          x2="740.21915"
          y2="486.8573"
          xlink:href="#linear-gradient-rocket-8"
        />
        <linearGradient
          id="linear-gradient-rocket-42"
          x1="750.60549"
          y1="536.72518"
          x2="747.36501"
          y2="522.33746"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#c65c6d" />
          <stop offset="1" stop-color="#f97d87" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-43"
          x1="732.46064"
          y1="833.61322"
          x2="732.46064"
          y2="798.27592"
          xlink:href="#linear-gradient-rocket-37"
        />
        <linearGradient
          id="linear-gradient-rocket-44"
          x1="682.26099"
          y1="612.29607"
          x2="718.94319"
          y2="567.31826"
          xlink:href="#linear-gradient-rocket-8"
        />
        <linearGradient
          id="linear-gradient-rocket-45"
          x1="688.76767"
          y1="560.31048"
          x2="706.78472"
          y2="628.87896"
          xlink:href="#linear-gradient-rocket-8"
        />
        <linearGradient
          id="linear-gradient-rocket-46"
          x1="617.88364"
          y1="592.60449"
          x2="641.41956"
          y2="548.55008"
          gradientTransform="translate(87.29553)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#a6d1ff" />
          <stop offset="1" stop-color="#d4e9ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-47"
          x1="753.05007"
          y1="517.68948"
          x2="756.85223"
          y2="502.48085"
          gradientTransform="translate(153.53933 -174.30364) rotate(14.64635)"
          xlink:href="#linear-gradient-rocket-40"
        />
        <linearGradient
          id="linear-gradient-rocket-48"
          x1="738.4843"
          y1="516.52178"
          x2="738.4843"
          y2="506.92997"
          xlink:href="#linear-gradient-rocket-8"
        />
        <linearGradient
          id="linear-gradient-rocket-49"
          x1="154.5567"
          y1="740.25671"
          x2="168.3326"
          y2="740.25671"
          xlink:href="#linear-gradient-rocket-12"
        />
        <linearGradient
          id="linear-gradient-rocket-50"
          x1="218.36722"
          y1="578.45954"
          x2="204.88684"
          y2="506.56417"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00758" stop-color="#4e4ef2" />
          <stop offset="0.68756" stop-color="#4e77f2" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-51"
          x1="173.22003"
          y1="589.42363"
          x2="173.22003"
          y2="493.40359"
          xlink:href="#linear-gradient-rocket-50"
        />
        <linearGradient
          id="linear-gradient-rocket-52"
          x1="534.43554"
          y1="245.57841"
          x2="561.74979"
          y2="245.57841"
          gradientTransform="translate(425.46987 -59.13704) rotate(90)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#4e69f2" />
          <stop offset="0.72822" stop-color="#3c88f2" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-53"
          x1="182.84871"
          y1="492.55498"
          x2="182.84871"
          y2="411.909"
          xlink:href="#linear-gradient-rocket-8"
        />
        <linearGradient
          id="linear-gradient-rocket-54"
          x1="173.72199"
          y1="486.13884"
          x2="173.72199"
          y2="466.49631"
          xlink:href="#linear-gradient-rocket-8"
        />
        <linearGradient
          id="linear-gradient-rocket-55"
          x1="163.2168"
          y1="777.80333"
          x2="163.2168"
          y2="677.89529"
          xlink:href="#linear-gradient-rocket-37"
        />
        <linearGradient
          id="linear-gradient-rocket-56"
          x1="168.17265"
          y1="776.26815"
          x2="180.37771"
          y2="776.26815"
          xlink:href="#linear-gradient-rocket-13"
        />
        <linearGradient
          id="linear-gradient-rocket-57"
          x1="153.42998"
          y1="607.39747"
          x2="153.42998"
          y2="516.70117"
          xlink:href="#linear-gradient-rocket-50"
        />
        <linearGradient
          id="linear-gradient-rocket-58"
          x1="306.56129"
          y1="878.14023"
          x2="319.45815"
          y2="878.14023"
          gradientTransform="translate(476.03101 -39.75378) rotate(29.68934)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#355ea0" />
          <stop offset="0.89016" stop-color="#3f72bc" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-59"
          x1="377.00667"
          y1="837.75639"
          x2="389.90353"
          y2="837.75639"
          gradientTransform="translate(465.27682 -79.94661) rotate(29.68934)"
          xlink:href="#linear-gradient-rocket-22"
        />
        <linearGradient
          id="linear-gradient-rocket-60"
          x1="208.80782"
          y1="821.66446"
          x2="221.70468"
          y2="821.66446"
          gradientTransform="translate(435.22602 1.2493) rotate(29.68934)"
          xlink:href="#linear-gradient-rocket-58"
        />
        <linearGradient
          id="linear-gradient-rocket-61"
          x1="195.78132"
          y1="835.53861"
          x2="410.38164"
          y2="835.53861"
          xlink:href="#linear-gradient-rocket-3"
        />
        <linearGradient
          id="linear-gradient-rocket-62"
          x1="195.78134"
          y1="804.28901"
          x2="410.38164"
          y2="804.28901"
          xlink:href="#linear-gradient-rocket-4"
        />
        <linearGradient
          id="linear-gradient-rocket-63"
          x1="195.78134"
          y1="784.96452"
          x2="289.9183"
          y2="784.96452"
          xlink:href="#linear-gradient-rocket-3"
        />
        <linearGradient
          id="linear-gradient-rocket-64"
          x1="289.9183"
          y1="789.19821"
          x2="351.02583"
          y2="789.19821"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#3550a0" />
          <stop offset="0.89016" stop-color="#3f69bc" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-65"
          x1="228.44611"
          y1="789.12697"
          x2="289.9183"
          y2="789.12697"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#2e3e8c" />
          <stop offset="1" stop-color="#3550a0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-66"
          x1="289.9183"
          y1="739.25302"
          x2="351.02583"
          y2="739.25302"
          xlink:href="#linear-gradient-rocket-64"
        />
        <linearGradient
          id="linear-gradient-rocket-67"
          x1="228.44611"
          y1="739.18177"
          x2="289.9183"
          y2="739.18177"
          xlink:href="#linear-gradient-rocket-65"
        />
        <linearGradient
          id="linear-gradient-rocket-68"
          x1="228.44598"
          y1="698.45871"
          x2="351.02302"
          y2="698.45871"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#3550a0" />
          <stop offset="0.98601" stop-color="#4b86db" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-rocket-69"
          x1="270.74431"
          y1="338.36309"
          x2="292.1923"
          y2="315.87249"
          xlink:href="#linear-gradient-rocket-32"
        />
        <linearGradient
          id="linear-gradient-rocket-70"
          x1="270.7443"
          y1="384.02257"
          x2="292.19227"
          y2="361.532"
          xlink:href="#linear-gradient-rocket-32"
        />
        <linearGradient
          id="linear-gradient-rocket-71"
          x1="270.7443"
          y1="429.68207"
          x2="292.19227"
          y2="407.19149"
          xlink:href="#linear-gradient-rocket-32"
        />
        <linearGradient
          id="linear-gradient-rocket-72"
          x1="272.60026"
          y1="273.97403"
          x2="297.17587"
          y2="273.97403"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01796" stop-color="#69f" />
          <stop offset="0.9983" stop-color="#4ba2f9" />
        </linearGradient>
      </defs>
      <g>
        <path
          class="cls-1"
          d="M110.00105,723.11167,481.20367,937.42186a67.70592,67.70592,0,0,0,67.71228-.00419L920.00136,723.114a22.56864,22.56864,0,0,0-.00858-39.09236L548.81313,469.88088a67.70593,67.70593,0,0,0-67.67043.00139L110.00642,684.01837A22.56864,22.56864,0,0,0,110.00105,723.11167Z"
        />
        <g>
          <g>
            <path
            data-aos="fade-left" data-aos-duration="1500" data-aos-delay="500"
              class="cls-2"
              d="M757.72842,635.18316l80.013-46.16705a7.43044,7.43044,0,0,0,3.42543-8.49676l-17.24865-59.75126-93.21458,54.831.1979,44.16684Z"
            />
            <polygon
            data-aos="fade-left" data-aos-duration="1500" data-aos-delay="500"
              class="cls-3"
              points="524.016 460.106 524.206 500.429 730.902 619.766 730.704 575.599 524.016 460.106"
            />
            <path
            data-aos="fade-left" data-aos-duration="1500" data-aos-delay="500"
              class="cls-4"
              d="M524.01624,460.10628l90.511-52.23442a17.28254,17.28254,0,0,1,17.27988.00164L821.22088,517.233a5.76085,5.76085,0,0,1-.00033,9.97824l-81.875,47.26386a17.28253,17.28253,0,0,1-17.28306-.00136Z"
            />
            <g data-aos="fade-left" data-aos-duration="1500" data-aos-delay="500">
              <g>
                <g>
                  <path
                    class="cls-5"
                    d="M613.10893,165.92565s-7.6807,3.50015-13.27882,13.19147c-5.613,9.717-21.30617,42.60163-21.30617,42.60163l16.38791,14.89081,29.0617-38.75674Z"
                  />
                  <polygon
                    class="cls-6"
                    points="603.026 195.759 588.362 214.658 594.912 236.61 611.021 216.165 603.026 195.759"
                  />
                  <path
                    class="cls-7"
                    d="M663.44923,461.50567s1.53658,7.54836.1057,11.29993c-.67366,1.76625-2.05939,3.44247-7.61252,5.97162-6.19731,2.82254-6.212,8.63775-16.73735,9.84055-8.26255.94422-14.83552-5.50877-10.26109-9.75645,6.11434-5.6776,17.19938-18.755,17.19938-18.755Z"
                  />
                  <path
                    class="cls-7"
                    d="M654.31562,443.186s3.03219,9.48992.28209,11.9822c-2.5412,2.303-6.69121,1.7405-11.07385,2.274-7.85589.95628-11.80737,4.00975-19.80727,2.71247-7.00194-1.13545-9.14729-7.63166-3.26628-10.06886,4.1284-1.71087,16.47112-9.29685,16.47112-9.29685Z"
                  />
                  <path
                    class="cls-8"
                    d="M608.97636,278.1254s.94663,74.147,3.63806,91.76112,23.28321,72.29023,23.28321,72.29023c2.1172,5.59313,18.28085,2.08949,18.28085-2.151l-4.68782-136.2166Z"
                  />
                  <g>
                    <path
                      class="cls-9"
                      d="M672.90693,307.21009l-28.6896,12.55015L608.9764,278.12543c0-10.00875.02378-15.84661,0-27.07464l22.3114-10.725,41.85509,23.7966A424.66863,424.66863,0,0,1,672.90693,307.21009Z"
                    />
                    <path
                      class="cls-10"
                      d="M674.85383,282.32043c0,7.73787-16.88657,14.61533-32.66733,14.46645-17.83075-.16821-35.50164-7.822-35.12316-26.24148l1.913-38.54595s-8.25326-44.82853-7.1873-51.35437c2.56644-15.7118,21.08833-17.84657,21.08833-17.84657,21.43654-1.92757,43.59384,16.02467,47.25615,29.256C672.10968,199.465,674.85383,240.3303,674.85383,282.32043Z"
                    />
                    <path
                      class="cls-11"
                      d="M625.75359,167.80972c-1.92432,3.31323-1.44986,10.05714-1.54887,12.46631l-4.85219-7.56238,6.32457-8.75222Z"
                    />
                    <path
                      class="cls-12"
                      d="M608.97638,231.99945l-1.86257,37.491c-.55511,14.21776,4.15489,19.30625,18.86,24.81516l46.27425-84.73067a149.30687,149.30687,0,0,0-2.11453-17.52047l-26.19222,6.31246c-4.06987,6.88741-14.87947,14.66815-19.13129,14.40711s-18.94524-9.11059-21.78386-17.0155C603.34045,202.12288,606.632,219.01631,608.97638,231.99945Z"
                    />
                    <path
                      class="cls-13"
                      d="M630.892,164.373l-6.36291,4.45279a70.06553,70.06553,0,0,0-.32436,11.45015l9.82032-7.76393,7.91856-5.94141Z"
                    />
                    <path
                      class="cls-11"
                      d="M624.43218,161.72562c-3.96879,2.7768-10.42707,13.455-10.42707,13.455l5.34742-2.467,10.84923-14.10822A21.37756,21.37756,0,0,0,624.43218,161.72562Z"
                    />
                    <polygon
                      class="cls-6"
                      points="635.979 182.049 631.051 178.652 624.205 180.276 630.992 174.91 635.979 182.049"
                    />
                    <polygon
                      class="cls-6"
                      points="624.205 180.276 619.353 172.714 614.005 175.181 618.64 175.73 624.205 180.276"
                    />
                  </g>
                  <path
                    class="cls-9"
                    d="M633.70659,303.99389l11.443,157.827c1.04109,7.32076,18.23316,4.38247,18.05638-.49967l9.70095-155.60843Z"
                  />
                </g>
                <path
                  class="cls-14"
                  d="M599.71719,220.20342l-30.83468-15.05878-19.21874-31.30316,35.36938,17.27339a7.94484,7.94484,0,0,1,3.28414,2.98213l14.16118,23.0655A2.13881,2.13881,0,0,1,599.71719,220.20342Z"
                />
                <path
                  class="cls-15"
                  d="M605.65491,207.76117l-4.69768-3.411a3.86687,3.86687,0,0,0-3.29879-.599l-7.91067,2.17874c-1.15814.46941-1.5511,2.04644-.55729,3.50908l3.94031,5.63565a3.49966,3.49966,0,0,0,3.29865,1.46772l7.66912-.95067C609.20808,214.61926,608.45111,209.30465,605.65491,207.76117Z"
                />
                <path
                  class="cls-16"
                  d="M594.11589,208.94851a.36157.36157,0,0,1-.30239.41763l-4.19089.69128-.43213-.61929c-.01442-.01441-.01442-.02883-.02884-.04316l4.53653-.73452A.34893.34893,0,0,1,594.11589,208.94851Z"
                />
                <path
                  class="cls-16"
                  d="M595.6426,211.46888a.36256.36256,0,0,1-.288.41763l-3.98942.64812-.461-.66253,4.33506-.70569A.36345.36345,0,0,1,595.6426,211.46888Z"
                />
                <path
                  class="cls-16"
                  d="M597.16931,213.98925a.36183.36183,0,0,1-.30239.41763l-3.77353.60487-.44655-.64812,4.10475-.67685A.36961.36961,0,0,1,597.16931,213.98925Z"
                />
                <path
                  class="cls-5"
                  d="M667.45609,208.90037l-18.00267,31.41884c-3.46943,7.4435-12.9315,9.14667-19.80392,3.4174l-11.517-10.44431c-4.07921-4.37358,5.90064-14.9635,8.17039-14.03832l5.60183,2.73723,15.88578-31.179C656.07507,173.47367,679.20458,185.60582,667.45609,208.90037Z"
                />
                <path
                  class="cls-11"
                  d="M625.83312,216.49316l9.97128,5.19063c4.99553,2.87425-4.5,22.36374-11.62081,18.59526l-9.15-8.06081C610.14983,227.31436,619.64,213.90836,625.83312,216.49316Z"
                />
                <ellipse
                  class="cls-17"
                  cx="621.16927"
                  cy="224.41519"
                  rx="7.27955"
                  ry="4.33327"
                  transform="translate(65.45636 581.34179) rotate(-52.57577)"
                />
                <path
                  class="cls-18"
                  d="M624.93914,218.92542l-19.28423-11.16425-3.19922,7.01517,15.33409,14.78577c1.2632,1.17758,4.64723-.40895,6.52287-3.2052C626.14917,223.619,626.83087,220.05832,624.93914,218.92542Z"
                />
              </g>
              <path
                class="cls-16"
                d="M624.52778,168.49457c-.16162,5.68017,8.58409,3.75485,14.88759-2.4172l-2.8859-17.774-14.88755,2.41719Z"
              />
              <path
                class="cls-19"
                d="M619.0474,149.58925s-2.18059-2.21182-3.7465-.31343c-1.06633,1.29273-1.12065,4.88412,1.04324,7.02413a3.49987,3.49987,0,0,0,3.28427.64878Z"
              />
              <path
                class="cls-20"
                d="M611.93745,139.186c-.6139,7.58062,3.62535,10.51229,5.45885,14.81273l.64291-10.65619Z"
              />
              <path
                class="cls-21"
                d="M628.47175,167.99933c-5.59882,1.78962-10.13738-4.23854-10.72821-10.25746l-1.26017-13.58414A10.88836,10.88836,0,0,1,626.86373,132.784h0a10.88837,10.88837,0,0,1,11.37371,10.38036l.61467,13.46124C639.12644,162.63284,634.39813,166.105,628.47175,167.99933Z"
              />
              <path
                class="cls-20"
                d="M615.082,132.24713c-3.672,4.14006-3.99149,8.3209-1.6684,12.29128,4.92074,6.99446,13.65315-2.083,17.95022,2.06966,1.28219,6.94745,6.08416,4.0265,6.08416,4.0265a61.49115,61.49115,0,0,0,1.24909,12.86916s2.20679-6.58617,4.20841-12.45106c1.18042-3.45872,5.40566-14.73477-3.65471-21.1884C635.3663,127.09738,621.66354,124.82678,615.082,132.24713Z"
              />
              <path
                class="cls-11"
                d="M630.99219,174.90982l4.9866,7.1394c5.67-4.84739,7.96255-10.855,7.66546-15.60466l-3.88379-6.10533A34.762,34.762,0,0,1,630.99219,174.90982Z"
              />
              <path
                class="cls-20"
                d="M637.68727,149.80193l-.35423,4.84179c-.04608.61806-1.42424-.04054-1.95107-1.16005a12.93206,12.93206,0,0,1-.688-4.91281Z"
              />
              <path
                class="cls-22"
                d="M637.68727,149.80193s2.18059-2.21182,3.7465-.31343c1.06633,1.29273,1.12065,4.88412-1.04324,7.02413a3.49987,3.49987,0,0,1-3.28427.64878Z"
              />
            </g>
            <path
       
              class="cls-23"
              d="M806.55909,686.71013V686.71l-.00071-14.16205L223.441,672.41956v14.28063a.13872.13872,0,0,0,0,.01706v.05678l.00317-.00158A15.65351,15.65351,0,0,0,231.3439,700.4L491.32921,850.5a47.421,47.421,0,0,0,47.425-.0029l259.90338-150.0955a15.65426,15.65426,0,0,0,7.898-13.62865l.00352.00105Z"
            />
            <path
        
              class="cls-24"
              d="M231.34385,686.17391l259.98543,150.1a47.42034,47.42034,0,0,0,47.42479-.00293L798.65741,686.17554a15.80678,15.80678,0,0,0-.006-27.37978L538.68205,508.8144a47.42035,47.42035,0,0,0-47.39548.001L231.3476,658.79348A15.80678,15.80678,0,0,0,231.34385,686.17391Z"
            />
            <g data-aos="fade-down" data-aos-duration="1500" data-aos-delay="100">
              <g>
                <ellipse
                  class="cls-25"
                  cx="514.99865"
                  cy="671.12484"
                  rx="197.80996"
                  ry="121.69882"
                />
                <ellipse
                  class="cls-26"
                  cx="514.99865"
                  cy="643.8435"
                  rx="163.92086"
                  ry="94.41748"
                />
                <path
                  class="cls-27"
                  d="M730.90155,619.766l-32.3798,5.86485c-13.62364-6.21076-26.72092-7.81251-39.41244-5.86486L617.0782,588.71059l35.03462-15.00266Z"
                />
                <ellipse
                  class="cls-28"
                  cx="514.99865"
                  cy="643.8435"
                  rx="150.41161"
                  ry="83.86197"
                />
                <path
                  class="cls-29"
                  d="M662.70672,659.75517c-13.338,38.70957-74.39719,67.951-147.70733,67.951S380.63,698.46474,367.29205,659.75517C380.63,621.05649,441.68924,591.815,514.99939,591.815S649.36869,621.05649,662.70672,659.75517Z"
                />
              </g>
              <g>
                <g>
                  <g>
                    <path
                      class="cls-30"
                      d="M440.00557,485.33027c-18.72,12.52819-42.855,36.545-57.41995,63.23529-9.85194,18.05375-9.39538,33.968.05007,49.55746l94.07633-29.24Z"
                    />
                    <path
                      class="cls-31"
                      d="M440.00557,497.28774c-18.72,12.52819-41.94818,37.06024-57.41995,63.23529a36.80442,36.80442,0,0,0,.05007,37.6l45.21085,75.73781a12.86852,12.86852,0,0,0,11.04955,6.2726h0A12.86852,12.86852,0,0,0,451.72263,666.226l-5.45346-67.32921L476.712,580.84046Z"
                    />
                  </g>
                  <g>
                    <path
                      class="cls-32"
                      d="M589.99173,485.33027c18.72,12.52819,42.85505,36.545,57.42,63.23529,9.85194,18.05375,9.39538,33.968-.05007,49.55746l-94.07632-29.24Z"
                    />
                    <path
                      class="cls-33"
                      d="M589.99173,497.28774c18.72,12.52819,41.94819,37.06024,57.42,63.23529a36.8044,36.8044,0,0,1-.05007,37.6l-45.21084,75.73781a12.86854,12.86854,0,0,1-11.04956,6.2726h0A12.86851,12.86851,0,0,1,578.27468,666.226l5.45345-67.32921-30.44284-18.05634Z"
                    />
                  </g>
                </g>
                <path
                  class="cls-34"
                  d="M553.17077,619.20723c-1.90118,5.04074-3.81678,9.6206-5.70319,13.50911-5.21373,9.79351-15.82791,16.07281-32.47684,16.07281-16.63452,0-27.23463-6.2793-32.448-16.07281-1.88676-3.88851-3.78794-8.454-5.68876-13.48037,8.02172,8.55487,20.59509,13.71085,38.13678,13.71085C532.56125,632.94682,545.14869,627.77642,553.17077,619.20723Z"
                />
                <path
                  class="cls-35"
                  d="M601.02853,450.20138c0,21.67115-22.09785,119.67315-42.14083,161.00791-7.04986,13.24609-21.38662,21.73432-43.89545,21.73432-22.493,0-36.82977-8.48823-43.87963-21.73432-20.043-41.33476-42.14083-139.33676-42.14083-161.02364,9.21528,21.73432,44.24316,37.90463,86.02046,37.90463C556.78537,488.09028,591.79733,471.92,601.02853,450.20138Z"
                />
                <path
                  class="cls-36"
                  d="M501.72636,540.77727l-16.209,96.41452c3.31548,4.16588,9.37517,8.897,19.15559,10.67428Z"
                />
                <path
                  class="cls-37"
                  d="M514.99866,716.69917h0c-4.75213,0-8.60449-3.18429-8.60449-7.11231l-4.68857-168.11838a5.96425,5.96425,0,0,1,5.96194-6.13053h14.66227a5.96426,5.96426,0,0,1,5.96194,6.13053l-4.68857,168.11838C523.60316,713.51488,519.75079,716.69917,514.99866,716.69917Z"
                />
              </g>
            </g>
            <ellipse
              class="cls-38"
              cx="515.00016"
              cy="448.919"
              rx="86.02837"
              ry="48.36874"
              data-aos="fade-down" data-aos-duration="1500" data-aos-delay="300"
            />
            <ellipse
              class="cls-39"
              cx="515.00016"
              cy="448.919"
              rx="77.63334"
              ry="40.37323"
              data-aos="fade-down" data-aos-duration="1500" data-aos-delay="500"
            />
            <g data-aos="fade-down" data-aos-duration="1500" data-aos-delay="700">
              <path
                class="cls-40"
                d="M515.47225,489.28965h-.4753c-26.42785,0-49.77371-6.86984-63.78695-17.35463V430.21213l-.08641-4.23414-.04324-2.08841.12965.05766,1.541.84968,3.58611,1.97308,58.53023,32.26078Z"
              />
              <path
                class="cls-41"
                d="M577.73258,429.43436v43.27843c-14.04207,9.98057-36.68216,16.49036-62.26033,16.57686l-.60487-30.25887,56.84512-33.384,3.62936-2.13166,2.24673-1.32506.05758,2.88042Z"
              />
              <path
                class="cls-42"
                d="M454.053,419.50719l54.25873-32.80381a11.01489,11.01489,0,0,1,11.01266-.22229l55.09893,30.34556a6.1194,6.1194,0,0,1,.2139,10.59694l-54.25874,32.8038a11.01489,11.01489,0,0,1-11.01266.22229l-55.09892-30.34556A6.11938,6.11938,0,0,1,454.053,419.50719Z"
              />
              <g class="cls-43">
                <path
                  class="cls-44"
                  d="M514.63982,487.18893a12.73329,12.73329,0,0,1-6.142-1.57453L451.21,454.06261V449.3971l58.04169,31.967a11.19865,11.19865,0,0,0,11.17149-.225l57.3094-34.64857v4.70691L521.23189,485.357A12.739,12.739,0,0,1,514.63982,487.18893Z"
                />
              </g>
            </g>
            <g data-aos="fade-down" data-aos-duration="1500" data-aos-delay="900">
              <ellipse
                class="cls-45"
                cx="515.00016"
                cy="388.72965"
                rx="77.63334"
                ry="40.37323"
              />
              <path
                class="cls-46"
                d="M602.8147,362.34738c-.50583,5.61139-1.10649,11.30179-1.78617,17.03965-9.2312,21.7185-44.24316,37.8889-86.03628,37.8889-41.7773,0-76.80518-16.1704-86.02046-37.90472q-1.01967-8.58306-1.78617-17.00811a465.799,465.799,0,0,1-2.00749-60.33444c1.83354-46.51934,14.99185-89.18181,30.51412-122.67638H574.30134c15.53809,33.49457,28.68721,76.157,30.52085,122.67638A468.32956,468.32956,0,0,1,602.8147,362.34738Z"
              />
              <g>
                <ellipse
                  class="cls-47"
                  cx="514.99865"
                  cy="304.43204"
                  rx="36.69312"
                  ry="48.47666"
                />
                <ellipse
                  class="cls-48"
                  cx="514.99865"
                  cy="304.43204"
                  rx="29.99813"
                  ry="39.63166"
                />
                <ellipse
                  class="cls-48"
                  cx="514.99865"
                  cy="304.43204"
                  rx="29.99813"
                  ry="39.63166"
                />
                <path
                  class="cls-49"
                  d="M544.99046,304.43454c0,1.49779-.05766,2.96683-.18741,4.42146-1.6561-19.80291-14.37329-35.2132-29.7979-35.2132-15.439,0-28.15621,15.41029-29.81231,35.2132-.12975-1.45463-.18741-2.92367-.18741-4.42146,0-21.89124,13.42287-39.63466,29.99972-39.63466C531.56759,264.79988,544.99046,282.5433,544.99046,304.43454Z"
                />
              </g>
            </g>
            <g data-aos="fade-down" data-aos-duration="1500" data-aos-delay="700">
              <ellipse
                class="cls-50"
                cx="469.23535"
                cy="447.58285"
                rx="1.40303"
                ry="2.43536"
                transform="translate(-160.92581 294.58241) rotate(-30)"
              />
              <ellipse
                class="cls-50"
                cx="463.68181"
                cy="444.12498"
                rx="1.40303"
                ry="2.43536"
                transform="translate(-159.94091 291.34237) rotate(-30)"
              />
              <ellipse
                class="cls-50"
                cx="458.23305"
                cy="440.92993"
                rx="1.40303"
                ry="2.43536"
                transform="translate(-159.07338 288.18993) rotate(-30)"
              />
            </g>
            <g data-aos="fade-down" data-aos-duration="1500" data-aos-delay="700">
              <ellipse
                class="cls-50"
                cx="469.23535"
                cy="472.64253"
                rx="1.40303"
                ry="2.43536"
                transform="translate(-173.45565 297.93977) rotate(-30)"
              />
              <ellipse
                class="cls-50"
                cx="463.68181"
                cy="469.18467"
                rx="1.40303"
                ry="2.43536"
                transform="translate(-172.47075 294.69973) rotate(-30)"
              />
              <ellipse
                class="cls-50"
                cx="458.23305"
                cy="465.98961"
                rx="1.40303"
                ry="2.43536"
                transform="matrix(0.86603, -0.5, 0.5, 0.86603, -171.60322, 291.54729)"
              />
            </g>
            <ellipse
              class="cls-51"
              cx="514.99679"
              cy="184.83725"
              rx="60.09335"
              ry="33.78699"
              data-aos="fade-down" data-aos-duration="1500" data-aos-delay="1500"
            />
            <ellipse
              class="cls-39"
              cx="514.99679"
              cy="184.83725"
              rx="55.13828"
              ry="29.06274"
              data-aos="fade-down" data-aos-duration="1500" data-aos-delay="1100"
            />
            <g data-aos="fade-down" data-aos-duration="1500" data-aos-delay="1300">
              <path
                class="cls-52"
                d="M556.33105,204.07c-10.11031,6.0344-24.87249,9.83676-41.3341,9.83676s-31.22378-3.80236-41.33409-9.83676a15.65537,15.65537,0,0,1,1.15224-4.19089l6.88416-16.20238,66.63863.11515,6.841,16.08723A15.65849,15.65849,0,0,1,556.33105,204.07Z"
              />
              <ellipse
                class="cls-53"
                cx="514.99677"
                cy="188.61544"
                rx="34.36061"
                ry="19.91023"
              />
            </g>
            <path
            data-aos="fade-down" data-aos-duration="1500" data-aos-delay="1500"
              class="cls-54"
              d="M574.96317,157.98156c-.91682,18.1778-27.409,32.7516-59.9551,32.7516-32.562,0-59.05419-14.5738-59.97092-32.7516.158-.332.30033-.648.45836-.96419,20.43816-43.67413,45.99769-71.50985,59.49674-71.50985,13.51477,0,39.07431,27.83572,59.51246,71.50985C574.66283,157.33353,574.82086,157.64959,574.96317,157.98156Z"
            />
            <path
            data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500"
              class="cls-55"
              d="M403.44573,206.83925V381.88463a11.67858,11.67858,0,0,1-5.84027,10.11451l-144.9079,83.64421a5.00511,5.00511,0,0,1-7.50726-4.33479V296.26318a11.6786,11.6786,0,0,1,5.84027-10.11452l144.9079-83.6442A5.00511,5.00511,0,0,1,403.44573,206.83925Z"
            />
            <path
            data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500"
              class="cls-56"
              d="M418.82719,182.82125v25.98552a11.67861,11.67861,0,0,1-5.84026,10.11452L268.079,302.5655a5.00511,5.00511,0,0,1-7.50725-4.3348V272.24518a11.67859,11.67859,0,0,1,5.84026-10.11452l144.9079-83.6442A5.00511,5.00511,0,0,1,418.82719,182.82125Z"
            />
            <path
            data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500"
              class="cls-57"
              d="M389.05859,260.45043v4.2913a5.086,5.086,0,0,1-2.54342,4.40485l-79.35494,45.80551a1.65848,1.65848,0,0,1-2.48758-1.43636v-4.2913a5.086,5.086,0,0,1,2.54342-4.40485L386.571,259.01407A1.65848,1.65848,0,0,1,389.05859,260.45043Z"
            />
            <path
            data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500"
              class="cls-58"
              d="M389.05859,279.24617v4.29129a5.086,5.086,0,0,1-2.54342,4.40485l-79.35494,45.80551a1.65848,1.65848,0,0,1-2.48758-1.43636v-4.2913a5.086,5.086,0,0,1,2.54342-4.40484L386.571,277.8098A1.65848,1.65848,0,0,1,389.05859,279.24617Z"
            />
            <path
            data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500"
              class="cls-59"
              d="M389.05859,298.0419v4.2913a5.086,5.086,0,0,1-2.54342,4.40484l-79.35494,45.80552a1.65848,1.65848,0,0,1-2.48758-1.43637V346.8159a5.086,5.086,0,0,1,2.54342-4.40485L386.571,296.60554A1.65848,1.65848,0,0,1,389.05859,298.0419Z"
            />
            <path
            data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500"
              class="cls-60"
              d="M344.00494,342.85552v4.2913a5.086,5.086,0,0,1-2.54342,4.40484l-34.30129,19.78763a1.65848,1.65848,0,0,1-2.48758-1.43636v-4.2913a5.086,5.086,0,0,1,2.54342-4.40485l34.30129-19.78763A1.65848,1.65848,0,0,1,344.00494,342.85552Z"
            />
            <path
            data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500"
              class="cls-61"
              d="M389.05859,373.22483v4.2913a5.086,5.086,0,0,1-2.54342,4.40485l-19.54461,11.28567a1.65847,1.65847,0,0,1-2.48758-1.43636V387.479a5.086,5.086,0,0,1,2.54342-4.40484L386.571,371.78847A1.65848,1.65848,0,0,1,389.05859,373.22483Z"
            />
            <g data-aos="fade-right"  data-aos-duration="1500" data-aos-delay="1300">
              <g>
                <path
                  class="cls-9"
                  d="M708.6722,575.12251,735.898,558.99126a4.20854,4.20854,0,0,0,1.56372-5.60947l-23.61669-44.04461a4.20853,4.20853,0,0,0-5.85425-1.632L680.765,523.83647a4.20853,4.20853,0,0,0-1.56373,5.60947L702.818,573.49055A4.20853,4.20853,0,0,0,708.6722,575.12251Z"
                />
                <path
                  class="cls-15"
                  d="M688.49446,559.409l-3.48957-10.35927a3.40668,3.40668,0,0,1-.03353-2.0699l1.30611-4.33691,2.217,4.13462,3.3278,4.28a.57371.57371,0,0,0,.96008-.37824l.22288-2.87823c.12485-1.46929,2.19835-1.87663,2.36816-.22724l.49353,4.41144-.15235,7.16643Z"
                />
                <path
                  class="cls-62"
                  d="M770.85548,785.47426s-5.89.48386-5.695,7.86356c.18138,6.863,4.39547,11.44838,6.84914,17.75781,2.19272,5.63843,6.213,9.05173,12.93205,5.37414,5.28549-2.893-1.25807-21.18767-1.25807-21.18767Z"
                />
                <path
                  class="cls-63"
                  d="M783.26672,780.61519c0,.58178,1.90027,24.065,1.90027,24.065.30245,4.14781-8.51165,5.61682-9.80886,1.49l-4.50265-25.55492Z"
                />
                <polygon
                  class="cls-64"
                  points="775.358 806.17 773.047 799.776 774.087 798.954 775.358 806.17"
                />
                <path
                  class="cls-62"
                  d="M775.99642,630.87174,785.998,785.995c.43633,6.47227-17.62047,10.96057-19.84421.4619-9.70121-43.42279-16.3072-80.39018-21.04078-124.68082Z"
                />
                <g>
                  <path
                    class="cls-65"
                    d="M710.533,662.20278l32.69043,2.12245c12.66153-7.64753,28.13268-23.482,32.773-33.45349a205.19244,205.19244,0,0,0-2.867-21.52272l-14.4937-11.93076L712.5592,621.42064C710.32649,630.2621,709.5022,649.03331,710.533,662.20278Z"
                  />
                  <g>
                    <path
                      class="cls-66"
                      d="M745.43681,560.63865l22.84077,33.43557s15.29765-1.95989,17.06657-12.29237-7.65609-42.19053-7.65609-42.19053Z"
                    />
                    <path
                      class="cls-67"
                      d="M745.1295,561.85793s15.66912,7.63015,23.662,14.41276c3.396,2.88177,17.58628-1.00695,17.2979-4.64958-1.09979-13.89194-9.11819-36.75885-9.11819-36.75885Z"
                    />
                    <path
                      class="cls-68"
                      d="M775.33791,552.51518l1.04474,24.41991c-1.3921.28159-5.33227.80757-6.66086-.81564Z"
                    />
                    <path
                      class="cls-69"
                      d="M712.5592,621.42064s6.14039,9.631,21.88884,11.85433c19.17831,2.70754,41.01925-7.68623,38.72849-26.51079l-3.01135-18.9034,7.26186-46.34822c2.08471-10.90831-9.86556-18.95532-18.78614-18.37356-11.43266,0-38.19649,13.2158-43.13949,23.09245l2.49779,50.74384Z"
                    />
                    <polygon
                      class="cls-68"
                      points="717.272 591.259 717.999 596.975 712.559 621.579 734.448 566.774 717.272 591.259"
                    />
                    <path
                      class="cls-70"
                      d="M758.8606,526.66882c1.18734,3.562-6.79781,6.983-14.5432,6.96261-8.3963-.02213-9.18337-1.69825-9.18337-1.69825,1.7261-1.74881,6.789-3.84144,12.08525-5.958C754.02658,524.77585,757.83063,523.57891,758.8606,526.66882Z"
                    />
                    <path
                      class="cls-71"
                      d="M739.71568,526.55553c-5.5513,1.28632-11.34438-5.37212-11.39137-12.00921l-.1053-14.87268a12.01782,12.01782,0,0,1,11.93243-12.1026h0a12.01782,12.01782,0,0,1,12.1026,11.93243l.1053,14.87267C752.40635,521.01323,746.09409,525.07756,739.71568,526.55553Z"
                    />
                    <polygon
                      class="cls-72"
                      points="741.874 532.201 756.047 526.644 751.476 511.697 739.367 515.61 741.874 532.201"
                    />
                  </g>
                </g>
                <path
                  class="cls-65"
                  d="M741,826.22406c2.04655.75363,4.12394,10.00759,1.48688,14.74512-2.58985,4.65271-10.68138,3.62307-16.82308.32633-5.27135-2.82956-14.33253-2.44887-19.54527-8.64142-5.74688-6.82708-.13589-12.89424,10.26659-11.03856a139.484,139.484,0,0,0,15.367,2.03081A24.25762,24.25762,0,0,1,741,826.22406Z"
                />
                <path
                  class="cls-73"
                  d="M742.06338,802.46951c0,.58178-.46116,27.05366-.46116,27.05366-.04556,7.08429-13.54216,3.12512-18.73565.58484a5.98189,5.98189,0,0,1,4.21054-5.34679l3.66607.46062-2.33747-20.34634Z"
                />
                <path
                  class="cls-65"
                  d="M710.53306,662.20279l13.59807,143.7261c1.03767,9.16779,21.65064,8.84386,22.20317,0l2.88039-147.18651Z"
                />
                <path
                  class="cls-74"
                  d="M708.09872,561.96937,691.32317,588.6549c-5.31423,9.96026,9.37858,20.4162,17.00834,10.30591L726.437,573.94715Z"
                />
                <polygon
                  class="cls-75"
                  points="690.199 593.354 688.494 559.409 695.715 559.152 700.702 579.979 690.199 593.354"
                />
                <path
                  class="cls-76"
                  d="M715.28484,546.65083l-19.199,32.17356c-4.75288,8.30252,14.81826,17.71429,20.43342,13.50763l20.73687-28.97989C746.24993,547.71688,721.74513,533.36927,715.28484,546.65083Z"
                />
              </g>
              <path
                class="cls-77"
                d="M753.35471,523.191c-2.89781,2.04322-11.70108,4.32564-14.51163-7.36178-3.81457-.08513-5.60505-1.8623-5.80207-6.56608-9.83306.24145-13.44263-10.84411-3.61368-19.55207,7.82713-6.38159,20.198-4.44747,25.43824,1.48328,3.094,3.50163,4.48288,8.49613,3.28461,15.87072A88.37289,88.37289,0,0,1,753.35471,523.191Z"
              />
              <ellipse
                class="cls-78"
                cx="754.91947"
                cy="510.21188"
                rx="8.07524"
                ry="8.58274"
                transform="translate(-104.47701 207.46238) rotate(-14.64635)"
              />
              <path
                class="cls-77"
                d="M750.10381,519.13025c-3.30844-5.56625-.29343-12.558,4.66254-13.645s10.14429.81872,13.72061,7.18672c3.59469,6.40075,5.05348,23.57767,10.78667,28.40343,14.88506,12.52912.71251,31.65453-14.03632,28.72967-7.929-1.57243-13.28009-6.64887-15.06-14.38568-1.85409-8.05922,1.85661-15.40587,2.20974-20.751C753.20432,522.29791,750.10381,519.13025,750.10381,519.13025Z"
              />
              <path
                class="cls-79"
                d="M736.3604,509.50459s1.818-2.13052,3.38277-.4823c1.06555,1.12237,1.3821,4.46109-.42582,6.61715a3.137,3.137,0,0,1-2.93737.853Z"
              />
            </g>
            <g data-aos="fade-right"  data-aos-duration="1500" data-aos-delay="1500">
              <g>
                <path
                  class="cls-80"
                  d="M198.20808,458.20108c.947,5.60093-.54243,13.878-8.43679,15.96933l-1.16318-8.89995Z"
                />
                <path
                  class="cls-62"
                  d="M155.24483,739.42457c-3.23378.389-7.25767,8.9638-6.19832,12.8945,1.62514,6.03,7.61143,7.37252,8.83091,10.76267a19.29347,19.29347,0,0,0,10.79372,11.0033c6.36889,2.65546,14.56227-1.564,11.81065-6.74746-4.47993-8.4393-12.07562-23.65081-12.07562-23.65081S157.18569,739.19109,155.24483,739.42457Z"
                />
                <path
                  class="cls-16"
                  d="M199.45983,598.06132s-1.83445,76.08241-3.76716,87.25347c-2.67879,15.48345-30.02841,57.43687-30.02841,57.43687-4.67644-2.49488-8.06378-5.41679-8.29846-9.38283l18.007-59.51287-15.38906-63.4401Z"
                />
                <path
                  class="cls-81"
                  d="M168.3326,738.226c-3.06474,3.0907-9.33235-.50546-10.96681-4.8572l-2.52227,7.20156c-1.40034,3.88057,2.42879,7.69424,9.2642,6.26881Z"
                />
                <path
                  class="cls-62"
                  d="M168.98907,745.37326l1.9977,8.00888c.484,2.73453-7.045,2.22928-8.364.07283l-4.40659-4.954C158.731,744.96081,168.46123,742.48935,168.98907,745.37326Z"
                />
                <path
                  class="cls-82"
                  d="M187.06287,483.92326c8.93225-.05509,19.345,5.30716,23.14714,15.69841,3.37209,9.2159,7.397,46.12294,7.397,46.12294L231.90579,569.559c4.53918,7.81267-8.77885,17.85815-15.7246,11.05315l-16.02955-16.02956L197.068,540.51186Z"
                />
                <path
                  class="cls-83"
                  d="M219.80967,581.15152l3.25483,3.73591c4.20472,4.55341,14.1216-2.73075,10.95532-7.6337l-3.48705-5.94409C227.19881,565.14426,213.17517,574.11338,219.80967,581.15152Z"
                />
                <path
                  class="cls-84"
                  d="M208.7281,516.77475l-2.18409,53.859-5.759-5.45158C201.82344,553.21394,205.69724,530.40578,208.7281,516.77475Z"
                />
                <path
                  class="cls-62"
                  d="M201.028,587.02771c.97854,29.88487,2.79253,59.75213-.24295,87.73108-.9258,13.12739-21.49625,9.86783-24.22326,5.74271v-78.298Z"
                />
                <path
                  class="cls-65"
                  d="M136.89772,616.55663,167.583,631.76586l33.56232-41.26177-.36023-18.37336-19.46271-17.053-42.82617,24.22711C135.79225,593.1534,135.72183,601.56627,136.89772,616.55663Z"
                />
                <path
                  class="cls-83"
                  d="M136.60244,592.03848c26.59891,22.3625,57.61871,9.75058,64.42556-5.01077V572.852l-63.49288,9.32228Z"
                />
                <path
                  class="cls-85"
                  d="M138.80537,575.62254l17.2853,11.8928c15.00056,11.6,47.9408-1.012,46.18354-22.10612-2.3933-17.69782,8.84888-40.34691,6.22337-61.05919-2.12339-16.75125-20.38978-20.19777-20.38978-20.19777-9.59181-2.94228-46.24139,14.82954-50.56554,33.9921Z"
                />
                <polygon
                  class="cls-84"
                  points="163.842 532.461 162.883 569.053 180.666 591.93 176.562 592.984 154.5 571.43 163.842 532.461"
                />
                <g>
                  <path
                    class="cls-83"
                    d="M192.30972,498.96536c-.57195,4.19323-7.094,6.76615-14.56744,5.74679s-13.06817-5.245-12.49621-9.43825l1.11356-8.164,27.06366,3.69146Z"
                  />
                  <ellipse
                    class="cls-86"
                    cx="179.89146"
                    cy="488.95563"
                    rx="7.66282"
                    ry="13.65713"
                    transform="translate(-328.89015 601.11541) rotate(-82.23284)"
                  />
                  <ellipse
                    class="cls-84"
                    cx="179.97049"
                    cy="488.37619"
                    rx="6.11562"
                    ry="10.89962"
                    transform="matrix(0.13515, -0.99083, 0.99083, 0.13515, -328.24768, 600.69259)"
                  />
                  <path
                    class="cls-16"
                    d="M188.85484,473.58735,187.717,484.79224l-.90752,8.97249a15.97237,15.97237,0,0,1-7.66167.67694,14.77339,14.77339,0,0,1-8.38213-3.68694l1.18107-7.25872,2.01615-12.32821Z"
                  />
                </g>
                <path
                  class="cls-87"
                  d="M184.39533,491.97732c5.42763.52933,10.30855-6.54248,10.18379-12.78363l-.076-14.06144a11.704,11.704,0,0,0-12.38666-10.97888h0A11.704,11.704,0,0,0,171.13752,466.54l.87113,14.45841C172.3974,487.45066,178.96771,491.448,184.39533,491.97732Z"
                />
                <path
                  class="cls-80"
                  d="M185.927,450.63477c11.38545-2.60329,16.21425,8.81533,11.757,14.28506-4.89919,6.01209-12.70909,2.11182-15.37951,4.07206-.40178,6.58045-8.30833,4.90247-8.30833,4.90247a29.10447,29.10447,0,0,1-2.74548,13.835c-3.57579-2.62588-5.36008-5.34929-8.23837-12.58873-1.85251-4.65943-2.71592-14.15267,1.87323-16.82473C169.28022,449.29122,178.43129,452.64514,185.927,450.63477Z"
                />
                <path
                  class="cls-88"
                  d="M176.16585,473.61343s-2.29632-2.58609-4.14781-.56606c-1.26079,1.37556-1.52972,5.36594.75221,7.87122a3.89708,3.89708,0,0,0,3.61434.91212Z"
                />
                <path
                  class="cls-80"
                  d="M179.75677,472.777a5.87807,5.87807,0,0,1-.875,3.50007c-1.01459,1.203-2.67425,1.21136-2.7159-.0473v-2.61632Z"
                />
                <path
                  class="cls-65"
                  d="M166.23093,774.38447c-1.80877,2.36813-2.91767,10.48957-.91871,14.34639,2.12963,4.10894,8.26825,5.56334,11.7725,7.77655,3.273,2.06718,4.401,6.43637,13.16609,6.905,7.34957.393,11.31867-3.65689,10.95489-6.775-.3627-3.10883-10.64466-11.86588-13.88446-16.41517C181.40163,773.40123,170.35314,768.98748,166.23093,774.38447Z"
                />
                <path
                  class="cls-89"
                  d="M146.12882,653.7035l8.85093,42.99959,14.42945,81.41029c2.34281,5.67665,11.596,4.37583,10.85328-2.61944l.0423-80.30116-5.44215-46.98261Z"
                />
                <path
                  class="cls-90"
                  d="M180.14271,770.41889c-1.289,4.15736-8.50913,3.86564-11.97006.76225l.53481,6.86411c.324,2.85505,2.78681,4.82831,6.43221,3.793l5.238-1.82868Z"
                />
                <path
                  class="cls-65"
                  d="M178.22673,605.23068l5.86461,87.31792c.34117,11.37717-33.59251,15.98358-35.98036,4.28053-4.97886-30.63406-9.76864-60.79214-11.21343-80.27251Z"
                />
                <path
                  class="cls-91"
                  d="M138.67451,514.68049c-4.54868,10.121-8.25215,31.8959-10.07213,49.24859-1.21026,5.79513.19449,12.62632,4.817,16.09977l30.42271,26.85275c6.41071,5.35759,18.42208-6.04889,13.792-12.06076L155.748,564.85258l8.09416-32.39152C167.49764,514.35667,144.08482,514.32053,138.67451,514.68049Z"
                />
                <path
                  class="cls-83"
                  d="M168.36484,608.66307l3.89637,3.06092c4.98245,3.68625,13.36125-5.3248,9.33351-9.54872l-4.53766-5.18665C173.336,592.19168,160.53061,602.9906,168.36484,608.66307Z"
                />
                <path
                  class="cls-65"
                  d="M182.95,774.82155l5.30363,6.69128c1.62422,2.36929-5.68433,5.11-7.84816,3.642l-6.73524-3.05437C172.29594,777.553,181.22064,772.33028,182.95,774.82155Z"
                />
              </g>
              <g>
                <g>
                  <path
                    class="cls-92"
                    d="M322.62888,860.19c5.89618,3.40416,10.676,11.71763,10.676,18.56873,0,3.38075-1.16725,5.76808-3.05478,6.91576l.00315.00568-.063.03629-.028.01614-9.74543,5.6129-14.79838-26.422,9.34671-5.3782c.08629-.05371.17462-.10352.264-.15193l.17816-.10251.0076.01356C317.31572,858.372,319.84362,858.58193,322.62888,860.19Z"
                  />
                  <ellipse
                    class="cls-93"
                    cx="313.00972"
                    cy="878.14023"
                    rx="8.74406"
                    ry="15.14574"
                    transform="translate(-393.84949 270.31179) rotate(-29.68934)"
                  />
                  <ellipse
                    class="cls-94"
                    cx="313.00972"
                    cy="878.14023"
                    rx="5.28153"
                    ry="9.14823"
                    transform="translate(-393.84949 270.31179) rotate(-29.68934)"
                  />
                </g>
                <g>
                  <path
                    class="cls-92"
                    d="M393.07426,819.80615c5.89618,3.40416,10.676,11.71763,10.676,18.56873,0,3.38075-1.16725,5.76808-3.05478,6.91576l.00315.00568-.063.03629-.028.01614-9.74543,5.6129-14.79838-26.422,9.34671-5.3782c.08629-.05371.17462-.10352.264-.15192l.17816-.10252.0076.01356C387.7611,817.98812,390.289,818.19808,393.07426,819.80615Z"
                  />
                  <ellipse
                    class="cls-93"
                    cx="383.4551"
                    cy="837.75639"
                    rx="8.74406"
                    ry="15.14574"
                    transform="translate(-364.59968 299.90174) rotate(-29.68934)"
                  />
                  <ellipse
                    class="cls-95"
                    cx="383.4551"
                    cy="837.75639"
                    rx="5.28153"
                    ry="9.14823"
                    transform="translate(-364.59968 299.90174) rotate(-29.68934)"
                  />
                </g>
                <g>
                  <path
                    class="cls-92"
                    d="M224.87541,803.71423c5.89618,3.40416,10.676,11.71763,10.676,18.56873,0,3.38075-1.16724,5.76808-3.05478,6.91576l.00315.00568-.063.03629-.028.01614-9.74543,5.6129-14.79838-26.422,9.34671-5.3782c.0863-.05371.17462-.10352.264-.15193l.17815-.10251.0076.01356C219.56225,801.8962,222.09015,802.10616,224.87541,803.71423Z"
                  />
                  <ellipse
                    class="cls-93"
                    cx="215.25625"
                    cy="821.66446"
                    rx="8.74406"
                    ry="15.14574"
                    transform="translate(-378.70992 214.48086) rotate(-29.68934)"
                  />
                  <ellipse
                    class="cls-96"
                    cx="215.25625"
                    cy="821.66446"
                    rx="5.28153"
                    ry="9.14823"
                    transform="translate(-378.70992 214.48086) rotate(-29.68934)"
                  />
                </g>
                <path
                  class="cls-97"
                  d="M199.30082,797.98263V658.967a21.23215,21.23215,0,0,0-8.25-16.8008L173.50338,628.607a5.308,5.308,0,0,1,.59153-8.7971l63.51974-36.67311c3.23468-1.79984,8.07687-2.30168,11.33656.42l17.612,13.609c5.20324,5.42385,8.25,11.62842,8.25,18.204V746.16962"
                />
                <g>
                  <path
                    class="cls-98"
                    d="M179.81758,601.91561l7.77473,4.71456a5.99886,5.99886,0,0,1,2.76507,3.91932l.646,3.13633a5.10112,5.10112,0,0,1-2.63005,5.54818l-5.32375,2.7875a4.53623,4.53623,0,0,1-6.1469-1.96109L171.773,609.98173C169.35483,604.55771,176.80838,599.97684,179.81758,601.91561Z"
                  />
                  <path
                    class="cls-99"
                    d="M182.56439,622.23134a4.19814,4.19814,0,0,1-1.03691.25932l-1.33947-5.76085a.54549.54549,0,0,1,.4033-.648.52155.52155,0,0,1,.6482.40313Z"
                  />
                  <path
                    class="cls-99"
                    d="M185.86254,620.54641l-.97943.51845-1.325-5.7176a.54155.54155,0,0,1,.4033-.6482.53456.53456,0,0,1,.648.4033Z"
                  />
                  <path
                    class="cls-99"
                    d="M189.14626,618.71731a4.58927,4.58927,0,0,1-.77777.51863l-.18741.10074-1.2528-5.42963a.54552.54552,0,0,1,.4033-.6482.52159.52159,0,0,1,.648.4033Z"
                  />
                  <path
                    class="cls-98"
                    d="M233.08646,577.2826l6.33487,4.83078a3.18033,3.18033,0,0,1,1.25182,2.518l.01,2.91406a3.57549,3.57549,0,0,1-1.83434,3.13518l-7.76751,4.33068c-1.56111.46131-3.25465-.83155-4.2592-2.64622,0,0,1.71745-3.4025-.43747-4.99033-.66375-.48909-2.398.7129-2.398.7129l-.73868-4.67445C221.87586,578.80562,229.5882,574.65781,233.08646,577.2826Z"
                  />
                  <path
                    class="cls-99"
                    d="M232.77016,594.07531l-1.00826.5617-1.02249-5.54478a.54232.54232,0,1,1,1.06574-.20165Z"
                  />
                  <path
                    class="cls-99"
                    d="M236.11137,592.20314l-1.05133.59036-.34563-5.29989a.53414.53414,0,1,1,1.06574-.07208Z"
                  />
                  <path
                    class="cls-99"
                    d="M239.06388,590.53244a1.91217,1.91217,0,0,1-.2159.144l-.86426.47538-.02884-5.24257a.54106.54106,0,0,1,.53305-.53269.52608.52608,0,0,1,.54711.53269Z"
                  />
                </g>
                <line
                  class="cls-97"
                  x1="274.81317"
                  y1="620.26171"
                  x2="199.30082"
                  y2="663.85879"
                />
                <line
                  class="cls-97"
                  x1="274.81317"
                  y1="698.876"
                  x2="199.30082"
                  y2="742.47307"
                />
                <path
                  class="cls-100"
                  d="M195.7814,802.42616v-9.828l214.60024,23.398v9.91864l-.00523-.00126a7.88081,7.88081,0,0,1-3.97636,6.81037l-75.982,43.85939a14.1549,14.1549,0,0,1-14.15373-.00063L199.76236,809.3202a7.878,7.878,0,0,1-3.97364-6.77512l-.00732.00805Z"
                />
                <path
                  class="cls-101"
                  d="M199.763,785.69167l75.982-43.85939a14.15477,14.15477,0,0,1,14.15369.00062l116.50193,67.26242a7.96205,7.96205,0,0,1-.00061,13.791l-75.982,43.85939a14.15477,14.15477,0,0,1-14.15369-.00062L199.76237,799.4827A7.96206,7.96206,0,0,1,199.763,785.69167Z"
                />
                <path
                  class="cls-102"
                  d="M199.763,785.69167l75.982-43.85939a14.15477,14.15477,0,0,1,14.15369.00062l.01961,88.15962H252.60693L199.76237,799.4827A7.96206,7.96206,0,0,1,199.763,785.69167Z"
                />
                <g>
                  <g>
                    <path
                      class="cls-103"
                      d="M351.02315,748.40391V792.8517a5.84292,5.84292,0,0,1-2.9421,5.27109L295.25038,828.583a10.66343,10.66343,0,0,1-5.33208,1.40951V769.94467Z"
                    />
                    <path
                      class="cls-104"
                      d="M289.9183,769.94467v60.04785a11.00879,11.00879,0,0,1-5.33207-1.40951L231.38784,797.8774a5.9704,5.9704,0,0,1-2.94173-5.0257V748.26141Z"
                    />
                    <path
                      class="cls-39"
                      d="M348.05866,743.391l-53.19875-30.71431a10.67164,10.67164,0,0,0-10.67168,0L231.41034,743.148a5.92871,5.92871,0,0,0,0,10.26883l53.19874,30.71431a10.67166,10.67166,0,0,0,10.67169,0l52.77789-30.47133A5.92871,5.92871,0,0,0,348.05866,743.391Z"
                    />
                  </g>
                  <g>
                    <ellipse
                      class="cls-50"
                      cx="297.43453"
                      cy="790.52953"
                      rx="2.91785"
                      ry="1.6849"
                      transform="translate(-535.90139 652.85063) rotate(-60)"
                    />
                    <ellipse
                      class="cls-50"
                      cx="303.36595"
                      cy="787.11757"
                      rx="2.91785"
                      ry="1.6849"
                      transform="translate(-529.98084 656.28141) rotate(-60)"
                    />
                    <ellipse
                      class="cls-50"
                      cx="309.29736"
                      cy="783.70562"
                      rx="2.91785"
                      ry="1.6849"
                      transform="translate(-524.06029 659.71218) rotate(-60)"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="cls-105"
                      d="M351.02315,698.45871V742.9065a5.84293,5.84293,0,0,1-2.9421,5.2711l-52.83067,30.46021a10.66333,10.66333,0,0,1-5.33208,1.40952V719.99948Z"
                    />
                    <path
                      class="cls-106"
                      d="M289.9183,719.99948v60.04785a11.00869,11.00869,0,0,1-5.33207-1.40952l-53.19839-30.7056a5.97041,5.97041,0,0,1-2.94173-5.02571V698.31621Z"
                    />
                    <path
                      class="cls-107"
                      d="M348.05866,693.44578l-53.19875-30.71431a10.67169,10.67169,0,0,0-10.67168,0L231.41034,693.2028a5.92872,5.92872,0,0,0,0,10.26884L284.60908,734.186a10.67171,10.67171,0,0,0,10.67169,0l52.77789-30.47133A5.92872,5.92872,0,0,0,348.05866,693.44578Z"
                    />
                  </g>
                  <g>
                    <ellipse
                      class="cls-50"
                      cx="297.43453"
                      cy="740.58434"
                      rx="2.91785"
                      ry="1.6849"
                      transform="translate(-492.64758 627.87803) rotate(-60)"
                    />
                    <ellipse
                      class="cls-50"
                      cx="303.36595"
                      cy="737.17238"
                      rx="2.91785"
                      ry="1.6849"
                      transform="translate(-486.72703 631.30881) rotate(-60)"
                    />
                    <ellipse
                      class="cls-50"
                      cx="309.29736"
                      cy="733.76042"
                      rx="2.91785"
                      ry="1.6849"
                      transform="translate(-480.80649 634.73959) rotate(-60)"
                    />
                  </g>
                </g>
              </g>
              <path
                class="cls-108"
                d="M163.84213,532.46106c21.61934-.90187,40.97579-11.47369,44.69446-22.1707.8396,6.80844-.551,14.9504-2.71128,24.0238-3.68618,6.9802-19.77495,16.64542-42.509,18.202Z"
              />
            </g>
            <path
              class="cls-15"
              d="M600.95723,204.35019l-1.86068-1.39346a4.29207,4.29207,0,0,0-3.47014-.76177l-2.06615.4417,1.20621,1.96466Z"
            />
            <path
            data-aos="fade-left" data-aos-duration="1500" data-aos-delay="800"
              class="cls-109"
              d="M293.54192,309.4492v21.402a6.64184,6.64184,0,0,1-3.32146,5.75232L272.7066,346.713a2.21394,2.21394,0,0,1-3.32074-1.91744V323.39357a6.64186,6.64186,0,0,1,3.32147-5.75232l17.51394-10.10945A2.21389,2.21389,0,0,1,293.54192,309.4492Z"
            />
            <polygon
            data-aos="fade-left" data-aos-duration="1500" data-aos-delay="800"
              class="cls-110"
              points="280.647 336.398 274.062 332.643 276.72 328.06 280.647 330.299 288.116 317.415 290.774 318.93 280.647 336.398"
            />
            <path
            data-aos="fade-left" data-aos-duration="1500" data-aos-delay="800"
              class="cls-111"
              d="M293.54192,355.10877v21.402a6.64184,6.64184,0,0,1-3.32146,5.75232l-17.51386,10.1094a2.214,2.214,0,0,1-3.32074-1.91744V369.05305a6.64185,6.64185,0,0,1,3.32147-5.75232l17.51386-10.1094A2.21394,2.21394,0,0,1,293.54192,355.10877Z"
            />
            <path
            data-aos="fade-left" data-aos-duration="1500" data-aos-delay="800"
              class="cls-112"
              d="M293.54192,400.76827v21.402a6.64185,6.64185,0,0,1-3.32146,5.75232l-17.51386,10.1094a2.214,2.214,0,0,1-3.32074-1.91744V414.71255a6.64185,6.64185,0,0,1,3.32147-5.75232l17.51386-10.1094A2.21394,2.21394,0,0,1,293.54192,400.76827Z"
            />
            <polygon
            data-aos="fade-left" data-aos-duration="1500" data-aos-delay="800"
              class="cls-110"
              points="281.025 382.057 274.44 378.303 277.098 373.719 281.025 375.958 288.494 363.075 291.152 364.59 281.025 382.057"
            />
            <g data-aos="fade-left" data-aos-duration="1500" data-aos-delay="800">
              <ellipse
                class="cls-113"
                cx="819.11651"
                cy="497.3939"
                rx="3.20265"
                ry="5.55912"
                transform="translate(-138.95614 476.1964) rotate(-30)"
              />
              <ellipse
                class="cls-113"
                cx="806.43961"
                cy="489.50073"
                rx="3.20265"
                ry="5.55912"
                transform="translate(-136.70794 468.80047) rotate(-30)"
              />
              <ellipse
                class="cls-113"
                cx="794.0019"
                cy="482.20748"
                rx="3.20265"
                ry="5.55912"
                transform="translate(-134.72766 461.6045) rotate(-30)"
              />
            </g>
            <g data-aos="fade-left" data-aos-duration="1500" data-aos-delay="800">
              <ellipse
                class="cls-114"
                cx="381.38525"
                cy="179.70996"
                rx="5.55912"
                ry="3.20265"
                transform="translate(35.05923 420.1443) rotate(-60)"
              />
              <ellipse
                class="cls-114"
                cx="394.06215"
                cy="171.8168"
                rx="5.55912"
                ry="3.20265"
                transform="translate(48.23336 427.17623) rotate(-60)"
              />
              <ellipse
                class="cls-114"
                cx="406.49986"
                cy="164.52355"
                rx="5.55912"
                ry="3.20265"
                transform="matrix(0.5, -0.86603, 0.86603, 0.5, 60.76836, 434.30098)"
              />
            </g>
          </g>
          <path
          data-aos="fade-left" data-aos-duration="1500" data-aos-delay="800"
            class="cls-115"
            d="M297.17587,264.7013v4.2913a5.086,5.086,0,0,1-2.54342,4.40485l-19.54461,11.28568a1.65848,1.65848,0,0,1-2.48758-1.43637v-4.2913a5.086,5.086,0,0,1,2.54342-4.40484l19.54461-11.28568A1.65848,1.65848,0,0,1,297.17587,264.7013Z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "rocketsvg",
  created() {},
};
</script>

<style scoped>
.cls-1,
.cls-108,
.cls-57,
.cls-58,
.cls-59,
.cls-60,
.cls-61 {
  opacity: 0.4;
}

.cls-1 {
  fill: url(#linear-gradient-rocket);
}

.cls-2 {
  fill: url(#linear-gradient-rocket-2);
}

.cls-3 {
  fill: url(#linear-gradient-rocket-3);
}

.cls-4 {
  fill: url(#linear-gradient-rocket-4);
}

.cls-5 {
  fill: #73acff;
}

.cls-6 {
  fill: #4d78bc;
}

.cls-7 {
  fill: #1e304c;
}

.cls-8 {
  fill: #20325b;
}

.cls-9 {
  fill: #253f75;
}

.cls-10 {
  fill: url(#linear-gradient-rocket-5);
}

.cls-11 {
  fill: #8dc1ff;
}

.cls-12 {
  fill: #3263ba;
}

.cls-12,
.cls-27,
.cls-43 {
  opacity: 0.5;
}

.cls-13 {
  fill: #b5d6f7;
}

.cls-14 {
  fill: #355ea0;
}

.cls-15 {
  fill: #f29191;
}

.cls-16 {
  fill: #c65c6d;
}

.cls-17 {
  fill: #274068;
}

.cls-18 {
  fill: url(#linear-gradient-rocket-6);
}

.cls-19 {
  fill: url(#linear-gradient-rocket-7);
}

.cls-20 {
  fill: #382531;
}

.cls-21 {
  fill: url(#linear-gradient-rocket-8);
}

.cls-22 {
  fill: url(#linear-gradient-rocket-9);
}

.cls-23 {
  fill: url(#linear-gradient-rocket-10);
}

.cls-24 {
  fill: url(#linear-gradient-rocket-11);
}

.cls-25 {
  fill: url(#linear-gradient-rocket-12);
}

.cls-26 {
  fill: url(#linear-gradient-rocket-13);
}

.cls-27 {
  fill: url(#linear-gradient-rocket-14);
}

.cls-28 {
  fill: url(#linear-gradient-rocket-15);
}

.cls-29 {
  fill: url(#radial-gradient);
}

.cls-30 {
  fill: #3251bf;
}

.cls-31 {
  fill: url(#linear-gradient-rocket-16);
}

.cls-32 {
  fill: url(#linear-gradient-rocket-17);
}

.cls-33 {
  fill: url(#linear-gradient-rocket-18);
}

.cls-34 {
  fill: #8fb7ef;
}

.cls-35 {
  fill: url(#linear-gradient-rocket-19);
}

.cls-36 {
  fill: #18397c;
  opacity: 0.3;
}

.cls-37 {
  fill: url(#linear-gradient-rocket-20);
}

.cls-38 {
  fill: #82b1e8;
}

.cls-39 {
  fill: #1c3059;
}

.cls-40 {
  fill: url(#linear-gradient-rocket-21);
}

.cls-41 {
  fill: url(#linear-gradient-rocket-22);
}

.cls-42 {
  fill: url(#linear-gradient-rocket-23);
}

.cls-44 {
  fill: #1c3666;
}

.cls-45 {
  fill: #7ab1ed;
}

.cls-46 {
  fill: url(#linear-gradient-rocket-24);
}

.cls-47 {
  fill: url(#linear-gradient-rocket-25);
}

.cls-48 {
  fill: url(#linear-gradient-rocket-26);
}

.cls-49 {
  fill: #15356d;
}

.cls-50 {
  fill: #69befb;
}

.cls-51 {
  fill: #759bd3;
}

.cls-52 {
  fill: url(#linear-gradient-rocket-28);
}

.cls-53 {
  fill: #3f72bc;
}

.cls-54 {
  fill: url(#linear-gradient-rocket-29);
}

.cls-102,
.cls-55 {
  opacity: 0.6;
}

.cls-55 {
  fill: url(#linear-gradient-rocket-30);
}

.cls-56 {
  fill: url(#linear-gradient-rocket-31);
}

.cls-57 {
  fill: url(#linear-gradient-rocket-32);
}

.cls-58 {
  fill: url(#linear-gradient-rocket-33);
}

.cls-59 {
  fill: url(#linear-gradient-rocket-34);
}

.cls-60 {
  fill: url(#linear-gradient-rocket-35);
}

.cls-61 {
  fill: url(#linear-gradient-rocket-36);
}

.cls-62 {
  fill: #202859;
}

.cls-63 {
  fill: url(#linear-gradient-rocket-37);
}

.cls-64 {
  fill: #e26d83;
}

.cls-65 {
  fill: #2a3572;
}

.cls-66 {
  fill: url(#linear-gradient-rocket-38);
}

.cls-67 {
  fill: url(#linear-gradient-rocket-39);
}

.cls-68 {
  fill: #86bcfc;
}

.cls-69 {
  fill: url(#linear-gradient-rocket-40);
}

.cls-70 {
  fill: #d86d79;
}

.cls-71 {
  fill: url(#linear-gradient-rocket-41);
}

.cls-72 {
  fill: url(#linear-gradient-rocket-42);
}

.cls-73 {
  fill: url(#linear-gradient-rocket-43);
}

.cls-74 {
  fill: url(#linear-gradient-rocket-44);
}

.cls-75 {
  fill: url(#linear-gradient-rocket-45);
}

.cls-76 {
  fill: url(#linear-gradient-rocket-46);
}

.cls-77 {
  fill: #203051;
}

.cls-78 {
  fill: url(#linear-gradient-rocket-47);
}

.cls-79 {
  fill: url(#linear-gradient-rocket-48);
}

.cls-80 {
  fill: #683b3a;
}

.cls-81 {
  fill: url(#linear-gradient-rocket-49);
}

.cls-82 {
  fill: url(#linear-gradient-rocket-50);
}

.cls-83 {
  fill: #3743af;
}

.cls-108,
.cls-84 {
  fill: #303a99;
}

.cls-85 {
  fill: url(#linear-gradient-rocket-51);
}

.cls-86 {
  fill: url(#linear-gradient-rocket-52);
}

.cls-87 {
  fill: url(#linear-gradient-rocket-53);
}

.cls-88 {
  fill: url(#linear-gradient-rocket-54);
}

.cls-89 {
  fill: url(#linear-gradient-rocket-55);
}

.cls-90 {
  fill: url(#linear-gradient-rocket-56);
}

.cls-91 {
  fill: url(#linear-gradient-rocket-57);
}

.cls-92 {
  fill: #202f59;
}

.cls-93 {
  fill: #2a3c75;
}

.cls-94 {
  fill: url(#linear-gradient-rocket-58);
}

.cls-95 {
  fill: url(#linear-gradient-rocket-59);
}

.cls-96 {
  fill: url(#linear-gradient-rocket-60);
}

.cls-97 {
  fill: none;
  stroke: #6fa7f7;
  stroke-miterlimit: 10;
  stroke-width: 5.08467px;
}

.cls-98 {
  fill: #f9a49d;
}

.cls-99 {
  fill: #d36e6e;
}

.cls-100 {
  fill: url(#linear-gradient-rocket-61);
}

.cls-101 {
  fill: url(#linear-gradient-rocket-62);
}

.cls-102 {
  fill: url(#linear-gradient-rocket-63);
}

.cls-103 {
  fill: url(#linear-gradient-rocket-64);
}

.cls-104 {
  fill: url(#linear-gradient-rocket-65);
}

.cls-105 {
  fill: url(#linear-gradient-rocket-66);
}

.cls-106 {
  fill: url(#linear-gradient-rocket-67);
}

.cls-107 {
  fill: url(#linear-gradient-rocket-68);
}

.cls-109 {
  fill: url(#linear-gradient-rocket-69);
}

.cls-110 {
  fill: #c5e3ff;
}

.cls-111 {
  fill: url(#linear-gradient-rocket-70);
}

.cls-112 {
  fill: url(#linear-gradient-rocket-71);
}

.cls-113 {
  fill: #5b8dff;
}

.cls-114 {
  fill: #4e72e0;
}

.cls-115 {
  fill: url(#linear-gradient-rocket-72);
}
</style>