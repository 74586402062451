<template>
  <div id="contact" class="fb fb-fd-r fb-ai-c fb-jc-sb margin-0" v-if="!loading">
        <div class="width-40">
          <h1 class="veryfat">Kontakt</h1>
            <div id="mc_embed_signup">
            <form
              action="mailto:hello@florianbachl.at "
              method="post"
              @submit="checkForm"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
            >
              <div>
                <div>
                  <label for="fullname" class="hide"
                    >Name <span class="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Name*"
                    name="fullname"
                    v-model="fullname"
                    id="fullname"
                  />
                </div>
                <div class="vertical-line" id="fullnameline"></div>
                <div>
                  <label for="email" class="hide"
                    >Email Addresse <span class="asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    name="EMAIL"
                    v-model="email"
                    placeholder="Email*"
                    id="email"
                  />
                </div>
                <div class="vertical-line" id="emailline"></div>
                <div>
                  <label for="phone" class="hide"
                    >Telefon <span class="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    name="PHONE"
                    placeholder="Telefonnummer"
                    v-model="phone"
                    id="phone"
                  />
                </div>
                <div class="vertical-line " id="phoneline"></div>
                <div>
                  <label for="subject" class="hide"
                    >Telefon <span class="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    name="subject"
                    placeholder="Betreff*"
                    v-model="subject"
                    id="subject"
                  />
                </div>
                <div class="vertical-line " id="subjectline"></div>
                <div>
                    <textarea
                  class="scrollbar"
                    placeholder="Deine Nachricht*"
                  v-model="message"
                  id="message"
                />
                </div>
                
                <div id="consent-group">

                      <input
                        type="radio"
                        value="Ich habe die Datenschutz-Bedingungen gelesen und bin mit der Datenverarbeitung einverstanden."
                        name="CONSENT"
                        v-model="consent"
                        class="checkbox-consent"
                        id="consent"
                      /><label for="mce-CONSENT-0" id="consentlabel"
                        >Ich habe die Datenschutz-Bedingungen gelesen und bin
                        mit der Datenverarbeitung einverstanden.</label
                      >
                </div>
                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div
                  style="position: absolute; left: -5000px"
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_c08c4aab5cb7c609cf7259d17_061d408004"
                    tabindex="-1"
                    v-model="fakeuserinput"
                    
                  />
                </div>
                <div v-for="er in errors" :key="er" class="red">
                    {{er}}
                </div>
                   <button
                    type="submit"
                    value="send"
                    name="send"
                    class="pb"           
                  >
                  Absenden</button>
                
              </div>
            </form>
          </div>
        </div>
        <Rocketsvg></Rocketsvg>
      
  </div>
</template>

<script>
import Rocketsvg from "../components/svgs/rocketsvg";
export default {
  name: "contact",
  data() {
    return {
        loading: true,
        fakeuserinput: null,
        submit: false,
        subject: null,
        phone: null,
        email: null,
        message: null,
        fullname: null,
        consent: false,
        finish: false,
        errors: []
    }
  },
components: {
    Rocketsvg
  },
  beforeCreate() {

    window.location.href = "https://us20.list-manage.com/contact-form?u=c08c4aab5cb7c609cf7259d17&form_id=7db63d5aa582a977c35141241f17e0f1"
    this.loading= false
   },
  computed: {},
  watch: {},
  methods: {
    async postForm(){
      setTimeout(() => {  this.lastslide = false;
      this.finish = true; }, 1000);
      
    },
    checkForm: function (e) { 
        this.errors = [];
      if (this.email && this.fullname && this.consent && this.subject && this.message && !this.fakeuserinput) {
        console.log(e)
        this.postForm()
        return true;
      }

       document.getElementById("emailline").classList.remove("redborder");
       document.getElementById("fullnameline").classList.remove("redborder");
       document.getElementById("subjectline").classList.remove("redborder");
       document.getElementById("emailline").classList.remove("redborder");
       document.getElementById("message").classList.remove("redborder");
       document.getElementById("consentlabel").classList.remove("red");


     
console.log("made it here!")
      if (!this.email) {
        this.errors.push('Email ist ein Pflichtfeld.');
         document.getElementById("emailline").classList.add("redborder");
      }
      console.log("made it here!")
      if (!this.fullname) {
        this.errors.push('Name ist ein Pflichtfeld.');
        document.getElementById("fullnameline").classList.add("redborder");
      }
      console.log("made it here!")
      if (!this.subject) {
        this.errors.push('Betreff ist ein Pflichtfeld.');
        document.getElementById("subjectline").classList.add("redborder");
      }
      console.log("made it here!")
      if (!this.message) {
        this.errors.push('Nachricht ist ein Pflichtfeld.');
        document.getElementById("message").classList.add("redborder");
      }
      console.log("made it here!")
      if (!this.consent) {
        this.errors.push('Datenschutz-Bestimmungen müssen akzeptiert werden.');
        document.getElementById("consentlabel").classList.add("red");
      }
      console.log("made it here!")
      e.preventDefault();
    },
  },
};
</script>
<style scoped>
#contact{
height: 100vh;
width: 100vw;
}

#rocketsvg {
  width: 50%;
}

input {
  color: #02060E;
caret-color: #02060E;
}

.vertical-line {
  border-bottom: 1px solid #02060E;
  width: 100%;
}

.checkbox-consent {
  width: 1em;
  margin-top: 0.5em;
  margin-right: 1em;
}

#consent-group {
  margin-top: 1em;
  margin-bottom: 3em;
}

textarea:focus {
  outline: none;
}

textarea {
  width: calc( 100% - 1em);
  border-radius: 10px;

  font-size: 1em;
  margin-top: 1em;
  border: 1px solid #161a21;
    padding: 0.1em 0.5em;
  height: 120px;
  resize: none;
}

.area {
  background-color: #161a21;
  border-radius: 10px;
  padding: 15px;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #1a2029;
  border-radius: 10px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #272f3d;
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #303744;
}

.red{
    color: red;
}
.redborder{
    border: 1px solid red;
}

@media screen and (max-width: 768px) {
    #rocketsvg{
        display: none;
    }
    .width-40{
        width: calc(100% - 20px);
    }
}
</style>
