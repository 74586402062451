<template>
  <div id="websitesvg" >
    <svg
      
      data-name="Website"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1000 1000"
    >
      <defs>
        <linearGradient
          id="linear-gradient-website"
          x1="505.37407"
          y1="327.33762"
          x2="467.36339"
          y2="192.49022"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#6f9ddd" />
          <stop offset="0.53856" stop-color="#7bb8f5" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-2"
          x1="478.7976"
          y1="195.56514"
          x2="502.50565"
          y2="195.56514"
          gradientTransform="translate(962.11155 433.70434) rotate(-174.93255)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#f29191" />
          <stop offset="1" stop-color="#f9a49d" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-3"
          x1="474.57173"
          y1="200.01633"
          x2="482.26944"
          y2="200.01633"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlink:href="#linear-gradient-website-2"
        />
        <linearGradient
          id="linear-gradient-website-4"
          x1="143.00332"
          y1="755.97712"
          x2="947.17712"
          y2="605.19453"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#c3e0fc" />
          <stop offset="0.99956" stop-color="#c3e4fc" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-5"
          x1="586.66642"
          y1="674.95779"
          x2="660.02011"
          y2="807.17556"
          xlink:href="#linear-gradient-website-4"
        />
        <linearGradient
          id="linear-gradient-website-6"
          x1="182.15348"
          y1="689.99584"
          x2="281.7696"
          y2="838.96722"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.32571" stop-color="#739be0" stop-opacity="0" />
          <stop offset="0.81728" stop-color="#5a92e0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-7"
          x1="184.92917"
          y1="687.462"
          x2="313.41512"
          y2="687.462"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12729" stop-color="#739be0" stop-opacity="0" />
          <stop offset="0.81728" stop-color="#5a92e0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-8"
          x1="186.26908"
          y1="629.10801"
          x2="256.45316"
          y2="673.02966"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00044" stop-color="#739be0" />
          <stop offset="1" stop-color="#5d80ce" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-9"
          x1="236.56451"
          y1="573.68368"
          x2="237.58331"
          y2="488.78357"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#4e69f2" />
          <stop offset="0.72822" stop-color="#3c88f2" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-10"
          x1="225.92882"
          y1="472.30546"
          x2="252.58859"
          y2="472.30546"
          xlink:href="#linear-gradient-website-9"
        />
        <linearGradient
          id="linear-gradient-website-11"
          x1="321.20105"
          y1="427.06903"
          x2="812.03685"
          y2="149.04949"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#274685" />
          <stop offset="1" stop-color="#245191" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-12"
          x1="332.83258"
          y1="665.59484"
          x2="812.34838"
          y2="196.94626"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#5269ff" />
          <stop offset="1" stop-color="#3cb5f2" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-13"
          x1="805.49837"
          y1="204.34431"
          x2="838.3097"
          y2="204.34431"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#294a8c" />
          <stop offset="1" stop-color="#265599" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-14"
          x1="775.89845"
          y1="265.93211"
          x2="836.34732"
          y2="193.25762"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#3965bf" />
          <stop offset="1" stop-color="#2c64b3" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-15"
          x1="315.67314"
          y1="444.67649"
          x2="815.56496"
          y2="147.18652"
          xlink:href="#linear-gradient-website-14"
        />
        <linearGradient
          id="linear-gradient-website-16"
          x1="355.05272"
          y1="792.52976"
          x2="842.26613"
          y2="435.72292"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#c3e0fc" />
          <stop offset="0.99956" stop-color="#cae7fc" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-17"
          x1="342.4049"
          y1="775.25938"
          x2="829.6183"
          y2="418.45254"
          xlink:href="#linear-gradient-website-4"
        />
        <linearGradient
          id="linear-gradient-website-18"
          x1="329.75707"
          y1="757.98901"
          x2="816.97048"
          y2="401.18216"
          xlink:href="#linear-gradient-website-4"
        />
        <linearGradient
          id="linear-gradient-website-19"
          x1="791.87165"
          y1="475.07093"
          x2="837.37811"
          y2="412.58446"
          xlink:href="#linear-gradient-website-9"
        />
        <linearGradient
          id="linear-gradient-website-20"
          x1="858.44758"
          y1="516.7996"
          x2="855.72719"
          y2="404.35677"
          xlink:href="#linear-gradient-website-9"
        />
        <linearGradient
          id="linear-gradient-website-21"
          x1="859.57196"
          y1="400.70059"
          x2="853.68555"
          y2="387.56938"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlink:href="#linear-gradient-website-2"
        />
        <linearGradient
          id="linear-gradient-website-22"
          x1="560.67732"
          y1="656.29587"
          x2="564.52613"
          y2="556.90615"
          xlink:href="#linear-gradient-website"
        />
        <linearGradient
          id="linear-gradient-website-23"
          x1="578.11964"
          y1="581.25542"
          x2="627.7013"
          y2="661.06152"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.22073" stop-color="#7bb8f5" />
          <stop offset="1" stop-color="#6f9ddd" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-24"
          x1="573.51037"
          y1="546.21661"
          x2="562.07716"
          y2="529.12339"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlink:href="#linear-gradient-website-2"
        />
        <linearGradient
          id="linear-gradient-website-25"
          x1="571.62793"
          y1="536.58693"
          x2="576.65902"
          y2="536.58693"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlink:href="#linear-gradient-website-2"
        />
        <linearGradient
          id="linear-gradient-website-26"
          x1="-10966.00815"
          y1="543.18787"
          x2="-10954.17352"
          y2="543.18787"
          gradientTransform="matrix(-0.1201, -1.14974, -0.99276, 0.13909, -222.01311, -12141.2693)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#a6daff" />
          <stop offset="1" stop-color="#bde6ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-27"
          x1="880.86525"
          y1="531.20418"
          x2="884.21592"
          y2="430.68405"
          xlink:href="#linear-gradient-website-9"
        />
        <linearGradient
          id="linear-gradient-website-28"
          x1="43.21175"
          y1="772.92061"
          x2="188.61856"
          y2="772.92061"
          xlink:href="#linear-gradient-website-7"
        />
        <linearGradient
          id="linear-gradient-website-29"
          x1="159.69061"
          y1="606.49424"
          x2="126.68883"
          y2="472.18464"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#365799" />
          <stop offset="0.81867" stop-color="#3660b3" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-website-30"
          x1="131.27348"
          y1="456.1583"
          x2="156.06058"
          y2="456.1583"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlink:href="#linear-gradient-website-2"
        />
        <linearGradient
          id="linear-gradient-website-31"
          x1="142.82956"
          y1="465.49456"
          x2="131.33975"
          y2="456.38195"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          xlink:href="#linear-gradient-website-2"
        />
      </defs>
      <g>
        <g data-aos="fade-left" data-aos-duration="2000" data-aos-delay="1800">
          <g>
            <g>
              <path
                class="cls-1"
                d="M507.36994,194.41169c-.88537-7.88716-3.11921-21.54377-16.7479-24.05462-15.35517-2.82893-23.4076,11.97707-24.49044,20.73762-1.04214,8.43123.10023,11.6162-6.34655,15.81225s-15.61048,11.58333-13.64459,23.69081c6.26987,38.61491,69.10479-2.27954,69.10479-2.27954C524.07074,207.27743,508.19843,201.79222,507.36994,194.41169Z"
              />
              <path
                class="cls-2"
                d="M556.31155,198.34853l-5.50915,40.4313c-.76409,6.28264-9.9333,15.1547-19.58364,8.9428L506.164,230.91246l-2.67915-29.13018v-.01888s7.10324-.22639,12.8481,3.66014c4.12714,2.79211,15.867,13.62169,15.867,13.62169l4.90529-24.45115C538.5768,186.89981,557.085,191.42748,556.31155,198.34853Z"
              />
              <path
                class="cls-3"
                d="M541.14266,249.364a12.69756,12.69756,0,0,1-9.9239-1.64139l-10.98031-7.24475-2.33067-16.47064Z"
              />
              <path
                class="cls-4"
                d="M459.40745,316.888a152.95582,152.95582,0,0,0-2.22156,15.19712l11.09362-4.41481Z"
              />
              <path
                class="cls-5"
                d="M459.40745,316.888c-.074-.13823,3.479-16.31505,5.21945-24.21106a17.794,17.794,0,0,0-.19126-8.44388l-3.75946-14.02133-.52832-1.94321-5.24477-30.31879s15.41417-23.9796,22.24367-27.99821c.33971-.20751,21.28727-7.93636,26.33806-8.1692.71694.01889,14.423,22.225,14.423,22.225,9.4622,18.16863,6.567,32.55879,4.80232,45.88375l6.79173,35.79017C532.25652,325.3591,478.95326,353.37615,459.40745,316.888Z"
              />
              <path
                class="cls-3"
                d="M460.67,270.21667l15.78458,14.813c7.55834,7.12923,20.60607,3.662,21.79467-6.6015l5.56338-54.08282-23.9301.058-3.12226,12.67842-21.92,11.6773Z"
              />
              <g>
                <path
                  class="cls-3"
                  d="M474.35471,219.717c4.81443,6.68,26.63869,17.09687,26.63869,17.09687s6.74248-19.58173.58542-28.4152c-3.12588-4.48466-7.09256-3.99292-14.94152-2.41316S469.72873,213.29844,474.35471,219.717Z"
                />
                <path
                  class="cls-6"
                  d="M477.67825,216.63113c3.51615,4.87866,20.2472,12.77132,20.2472,12.77132s4.1323-14.58611-.36443-21.03751c-2.28294-3.27532-5.18-2.91619-10.91234-1.76243S474.29972,211.94343,477.67825,216.63113Z"
                />
                <path
                  class="cls-7"
                  d="M477.8464,213.612c.81083,5.25036,10.711,8.17842,17.14292,6.16028,2.99119-.93854,3.60232-6.71949,1.30738-10.38986a9.53654,9.53654,0,0,0-11.6988-3.48721C480.10025,207.79717,477.18572,209.334,477.8464,213.612Z"
                />
                <path
                  class="cls-7"
                  d="M495.68718,213.26343c1.24191,6.47828-9.1371,5.89925-17.50787,0l.00007-20.90231h17.50783Z"
                />
                <path
                  class="cls-8"
                  d="M490.65162,177.37149h0a11.239,11.239,0,0,1,11.239,11.239v13.90925a11.239,11.239,0,0,1-11.239,11.239h0a11.239,11.239,0,0,1-11.239-11.239V188.61052a11.239,11.239,0,0,1,11.239-11.239Z"
                  transform="translate(996.65944 347.02744) rotate(174.93255)"
                />
                <path
                  class="cls-1"
                  d="M500.68794,177.85438l2.11761,13.26822a10.0095,10.0095,0,0,1-7.60325-4.90176c-4.2543,10.08423-8.05267,10.51163-11.188,10.84477a5.441,5.441,0,0,1-.616,4.51157c-1.5771,2.1094-6.37072-1.58956-9.178-.97655C472.73809,173.93825,484.79329,163.88337,500.68794,177.85438Z"
                />
              </g>
              <path
                class="cls-2"
                d="M461.55487,260.29788l14.95995,17.96053c5.26381,6.28261,17.31962,4.32991,18.7783-4.04206l7.07045-50.18172c.5026-7.62631-19.172-10.32626-20.29642-3.378l-4.314,19.68231-6.7569-8.78362C457.09073,218.71379,445.7977,243.89747,461.55487,260.29788Z"
              />
              <path
                class="cls-1"
                d="M480.61833,204.85875s.31649,13.96379-3.85806,19.48664c-6.86766,9.08576-20.74882,6.41141-22.85862,16.24567-1.30036-10.64081,21.61149-37.32774,21.61149-37.32774Z"
              />
              <path
                class="cls-9"
                d="M480.919,195.45984s-3.05661-2.70918-5.34453.09723c-1.558,1.91106-1.53579,6.79294,1.76305,9.37922,1.98485,1.55612,4.932.3979,4.932.3979Z"
              />
            </g>
            <ellipse
              class="cls-4"
              cx="492.03489"
              cy="222.24418"
              rx="4.08893"
              ry="8.07738"
              transform="translate(200.88318 676.91052) rotate(-81.67391)"
            />
            <ellipse
              class="cls-4"
              cx="546.87837"
              cy="196.40895"
              rx="3.96272"
              ry="7.82806"
              transform="translate(273.34787 709.08185) rotate(-81.67391)"
            />
          </g>
          <g>
            <path
              class="cls-10"
              d="M487.80465,197.08131l-6.00181,5.767-23.09777-26.306a2.61111,2.61111,0,0,1,.153-3.60562h0a2.61112,2.61112,0,0,1,3.6108-.00717Z"
            />
            <rect
              class="cls-11"
              x="480.72286"
              y="198.31851"
              width="10.98041"
              height="6.22601"
              rx="2.40198"
              transform="translate(-3.94532 393.06281) rotate(-43.85697)"
            />
            <rect
              class="cls-11"
              x="491.62806"
              y="201.09497"
              width="6.30739"
              height="18.50822"
              transform="translate(-7.73391 401.48708) rotate(-43.85697)"
            />
          </g>
          <path
            class="cls-12"
            d="M486.98411,214.89463l-.56055-6.30663a4.73336,4.73336,0,0,1,2.13932-4.39042l4.72048-3.06124a2.63487,2.63487,0,0,1,3.54411.63322l5.46735,7.31456a3.73153,3.73153,0,0,1-.04222,4.52351l-5.61645,7.22868-.44677,4.41c-.40048,2.24187-11.52415.24671-10.76005-2.82384Z"
          />
          <path
            class="cls-10"
            d="M541.74192,173.24269l-6.00182,5.767-23.09776-26.306a2.61112,2.61112,0,0,1,.153-3.60562h0a2.61111,2.61111,0,0,1,3.61079-.00717Z"
          />
          <rect
            class="cls-11"
            x="534.66013"
            y="174.47989"
            width="10.98041"
            height="6.22601"
            rx="2.40198"
            transform="translate(27.61615 423.78455) rotate(-43.85697)"
          />
          <path
            class="cls-12"
            d="M541.2072,190.22085l-1.99879-7.30112a1.66065,1.66065,0,0,1,.52441-1.70227l7.0154-5.98018a2.68554,2.68554,0,0,1,3.89322.43593l6.519,8.72156a3.24721,3.24721,0,0,1,.02946,3.84816l-5.59075,6.48014-.25906,3.89228c-.15214,2.99822-11.58792,1.25285-11.01857-1.56228Z"
          />
          <path
            class="cls-11"
            d="M556.39655,194.403h0a3.15369,3.15369,0,0,1-4.45911-.089l-2.64505-2.75275,4.79966-2.49592a1.556,1.556,0,0,1,1.83988.3024l.55359.57613A3.15369,3.15369,0,0,1,556.39655,194.403Z"
          />
          <path
            class="cls-7"
            d="M554.83229,188.583a.37574.37574,0,0,0-.50667-.16766l-4.29406,2.16223-1.772-1.19748,4.40369-3.55052a.37752.37752,0,1,0-.47167-.58958l-4.58724,3.69733-2.43226-1.64392,5.59667-4.47911a.37752.37752,0,0,0-.47167-.58959l-5.78022,4.626-2.37584-1.60581,6.57248-5.26066a.37752.37752,0,1,0-.47167-.58958l-6.7558,5.40748-2.16235-1.4613,1.77635,6.488,5.38756,2.28567a2.94534,2.94534,0,0,0,2.56676-.129l1.58036-.86675,4.0299-2.02912A.37731.37731,0,0,0,554.83229,188.583Z"
          />
        </g>
        <path
          class="cls-13"
          d="M810.08625,470.6033l-491.98237,90.951-203.89995,117.812a15.09335,15.09335,0,0,0,.00137,26.13823L383.89753,861.293a45.28006,45.28006,0,0,0,45.28917.005l506.055-292.17455a15.09335,15.09335,0,0,0,.00926-26.137Z"
        />
        <line
          class="cls-14"
          x1="629.02675"
          y1="683.68127"
          x2="370.01288"
          y2="833.30136"
        />
        <path
          class="cls-15"
          d="M853.98072,656.545H743.3706l-29.02112-16.74511a21.35459,21.35459,0,0,0-21.32222-.0129L383.98243,839.95482l23.76575,27.39442,146.08243-.2723,26.99811-17.52585-27.90321-8.016-3.46381-6.16668L762.87777,710.93089H841.175l16.29739-27.19295,18.36716-17.88317Z"
        />
        <polygon
          class="cls-16"
          points="340.194 807.286 94.154 807.286 334.534 668.984 340.194 807.286"
        />
        <polygon
          class="cls-17"
          points="313.415 713.094 184.929 713.109 184.929 661.815 304.355 662.486 306.28 678.546 311.394 687.462 287.856 693.645 313.415 713.094"
        />
        <line
          class="cls-14"
          x1="170.27171"
          y1="682.04368"
          x2="429.28558"
          y2="831.66377"
        />
        <g data-aos="fade-right" data-aos-duration="3000" data-aos-delay="1500">
          <g>
            <path
              class="cls-18"
              d="M170.41982,585.22512l-.14827-.07413V652.4414a7.06064,7.06064,0,0,0,3.55526,6.36963l63.84086,36.80828a12.88562,12.88562,0,0,0,6.4433,1.70327V627.81041Z"
            />
            <path
              class="cls-19"
              d="M244.111,627.81041v69.51217a13.30306,13.30306,0,0,0,6.44331-1.70327l67.5496-39.051.29041-4.12688V586.15528Z"
            />
            <path
              class="cls-20"
              d="M173.85386,580.09763l64.28565-37.11533a12.89571,12.89571,0,0,1,12.89572,0L314.81231,579.804a7.1643,7.1643,0,0,1,0,12.40892l-64.28565,37.11533a12.89571,12.89571,0,0,1-12.89572,0l-63.77708-36.82171A7.1643,7.1643,0,0,1,173.85386,580.09763Z"
            />
          </g>
          <path
            class="cls-21"
            d="M283.84087,659.11009l-1.74729,7.4814s15.57786,20.12628,22.4045,22.15965c7.52831,2.24237,11.56316-6.40746,8.62617-9.59551-7.49718-8.138-13.18051-23.23122-13.18051-23.23122Z"
          />
          <path
            class="cls-22"
            d="M263.96772,554.32152l42.64042,16.54482a15.73684,15.73684,0,0,1,9.72583,17.82119l-14.2673,69.8341c-1.09773,5.7206-8.54714,2.83005-14.74611,0l-.12721-62.86578-39.82616-13.6381Z"
          />
          <path
            class="cls-21"
            d="M288.04779,685.3488s-1.70107,8.55528.63135,11.95572c1.98353,2.89179,5.62022,4.185,8.63137,6.06032,4.42248,2.75435,4.14364,7.544,14.46212,9.5496,7.39586,1.43754,14.67512-5.42335,10.59268-9.74406-6.64665-7.03458-18.15905-19.84317-18.15905-19.84317Z"
          />
          <path
            class="cls-23"
            d="M259.25545,469.95479c5.492,1.75452,11.9827,6.079,14.33416,13.417,2.43275,7.59168,9.86237,43.0699,11.13587,49.40911a114.0839,114.0839,0,0,1,1.68712,16.26318c1.2564,10.34436-17.99406,10.49321-17.99413,2.07819l-3.61523-8.79688S253.89206,468.24137,259.25545,469.95479Z"
          />
          <path
            class="cls-24"
            d="M269.08236,498.41116c1.44314,16.69266-2.67145,28.33683-1.30345,51.15485l-6.35364-12.86475Z"
          />
          <path
            class="cls-25"
            d="M219.8618,600.28451l5.80405-5.78951,38.30187-22.02177-.71509-23.4-17.78834-16.51143-38.93671,22.37394v17.64816C206.52758,579.26414,207.18964,593.8887,219.8618,600.28451Z"
          />
          <path
            class="cls-24"
            d="M206.52758,560.1153l.0001,11.55026c20.69213,14.48972,54.31257.69487,58.4796-14.52277Z"
          />
          <path
            class="cls-25"
            d="M219.8618,600.28451l22.5939-34.3623,38.49472,28.11028a20.0102,20.0102,0,0,1,7.80213,12.14331l15.77557,76.988c1.29465,4.61106-12.8359,11.1794-16.5203,2.39882l-29.83825-67.89588Z"
          />
          <path
            class="cls-26"
            d="M206.52758,559.47342s1.44173,5.72928,14.5593,9.53371c14.90064,4.32157,45.61544-1.70725,45.20224-17.44049-6.146-30.89845,7.15726-46.25808,1.55556-70.03237-2.55556-10.8461-8.58923-11.57948-8.58923-11.57948-6.14341-1.97579-16.756-2.111-26.18817,1.21792-12.66053,4.46826-24.366,11.25119-27.26617,21.38624Z"
          />
          <path
            class="cls-24"
            d="M209.31507,563.20277l31.50612-8.68861a7.75136,7.75136,0,0,0,4.47155-3.2995l1.05319-1.64865-39.72267,7.55725C205.7269,559.50992,206.70271,561.52084,209.31507,563.20277Z"
          />
          <g>
            <path
              class="cls-27"
              d="M252.58859,480.34762c0,4.13066-5.968,7.47922-13.32988,7.47922s-13.32989-3.34856-13.32989-7.47922v-8.04216h26.65977Z"
            />
            <ellipse
              class="cls-28"
              cx="239.25871"
              cy="472.30546"
              rx="13.32989"
              ry="7.47921"
            />
            <ellipse
              class="cls-24"
              cx="239.25871"
              cy="471.73468"
              rx="10.63845"
              ry="5.96909"
            />
            <path
              class="cls-29"
              d="M246.82229,471.86031a9.97368,9.97368,0,0,0-.94333,4.54682,16.12023,16.12023,0,0,1-6.62223,1.3018,14.35554,14.35554,0,0,1-8.73528-2.56584l.5472-5.18834.2264-2.1696.18862-1.8679.90556,1.26415.79248,1.11307Z"
            />
          </g>
          <path
            class="cls-30"
            d="M251.71193,438.92269c3.05607,6.478-.4962,9.79085-.32814,15.68774l-3.13561-9.73489Z"
          />
          <path
            class="cls-31"
            d="M243.52244,473.32322c6.6524,0,9.27673-6.69624,8.4316-12.888l-1.24248-13.70687a11.31509,11.31509,0,0,0-12.74137-9.68091h0a11.3151,11.3151,0,0,0-9.68091,12.74137l2.67891,13.8721C231.81329,469.85266,236.672,473.32322,243.52244,473.32322Z"
          />
          <g>
            <path
              class="cls-30"
              d="M247.93515,434.17109c4.6626,3.52858,5.23788,7.30246,4.18249,11.7109-.787,3.9688-12.51056,1.33665-16.10221,6.5298.11125,7.464-6.25926,5.58454-6.25926,5.58454,2.06408,6.70383,2.16141,9.35274,1.16584,13.34882,0,0-3.91564-7.68035-6.73375-11.78465-2.1858-3.18338-8.97019-12.29468-.23137-22.63441C227.20975,433.07733,239.57827,427.84672,247.93515,434.17109Z"
            />
            <path
              class="cls-31"
              d="M230.00931,457.04848s-2.71768-1.83979-3.94673.45165c-.83694,1.56039-.149,5.29271,2.53654,7.06157a3.69809,3.69809,0,0,0,3.5373-.0084Z"
            />
            <path
              class="cls-30"
              d="M230.19978,457.73188l1.22266,4.25965c.17582.63083,1.94251.11934,2.00826-1.21594a29.45662,29.45662,0,0,0-.62509-5.61294Z"
            />
          </g>
          <path
            class="cls-32"
            d="M308.08111,567.2806l-77.20342,10.98947.09779,2.027a1.84888,1.84888,0,0,0,.99082,1.505L257.8985,595.374a1.98951,1.98951,0,0,0,1.86961-.01308l47.59522-25.76417a1.45055,1.45055,0,0,0,.75873-1.33626Z"
          />
          <path
            class="cls-33"
            d="M258.75774,593.98009l49.32337-26.69949-30.01055-15.70644-46.20642,25.01223a1.88667,1.88667,0,0,0,.0233,3.33076Z"
          />
          <polygon
            class="cls-34"
            points="259.65 591.236 302.95 567.797 286.224 559.06 242.924 582.498 259.65 591.236"
          />
          <path
            class="cls-23"
            d="M286.4126,549.04405c1.2564,10.34436-17.99406,10.49321-17.99413,2.07819Z"
          />
          <ellipse
            class="cls-24"
            cx="277.51799"
            cy="551.38074"
            rx="7.293"
            ry="6.60065"
          />
          <path
            class="cls-31"
            d="M276.00867,547.0216c4.03718-1.03311,6.44631,2.7308,6.44631,2.7308l1.6762,6.04445-.79769,8.41442-10.785,3.06933v-6.30484s-2.3845-5.0893-2.35017-6.963C270.21337,553.19163,271.9715,548.0547,276.00867,547.0216Z"
          />
          <path
            class="cls-35"
            d="M256.89151,593.02938l1.86623.95071,47.85271-28.3374,16.80136-30.55436L275.98531,560.761a7.33645,7.33645,0,0,0-2.93618,2.91685Z"
          />
          <path
            class="cls-32"
            d="M258.75774,593.98009l49.32337-26.69949,16.80137-30.55436L277.456,562.39892a7.3366,7.3366,0,0,0-2.93618,2.91686Z"
          />
          <path
            class="cls-23"
            d="M201.91958,497.68883l-10.00711,47.17473c-1.79055,7.5754,5.693,16.3445,15.63643,14.00293l33.04508-6.56465c5.65477-1.38944,5.02068-16.98214-2.77789-16.23608l-20.51177.77828,10.97007-35.41277c2.04185-7.36576-16.47883-18.37352-16.47883-18.37352S203.61758,490.727,201.91958,497.68883Z"
          />
          <path
            class="cls-24"
            d="M242.00193,550.26656l4.344-.70055c5.40608-1.033,4.56857-11.92778-.61369-11.84169l-6.11771-.16069C234.24289,537.10451,233.58232,551.96528,242.00193,550.26656Z"
          />
          <path
            class="cls-31"
            d="M245.11244,539.1361l5.771-3.07034,14.16711,5.40242a1.30065,1.30065,0,0,1-.65865,2.50937l-5.469-1.15626.53062,1.599-4.29543,4.40493a4.00464,4.00464,0,0,1-3.95033,1.06206c-2.10892-.59147-5.22493-1.52486-5.71658-1.66051C242.49548,547.40017,241.428,541.23655,245.11244,539.1361Z"
          />
          <path
            class="cls-31"
            d="M264.38706,543.98632l-5.47115-1.16973.11331.30182.43389,1.3018-.47166.49055-1.18884,1.2075-.5281.52821-1.33969,1.37723-.52809.54721-.24528.24527a4.01379,4.01379,0,0,1-3.96219,1.07542c-2.09394-.60375-5.20722-1.5282-5.71643-1.66028-2.98085-.83014-4.05638-6.99958-.37747-9.09376l5.77332-3.07528,14.1689,5.41473A1.301,1.301,0,0,1,264.38706,543.98632Z"
          />
          <polygon
            class="cls-24"
            points="225.528 510.135 221.42 536.688 217.348 536.701 225.528 510.135"
          />
        </g>
        <polygon
          class="cls-36"
          points="330.336 447.936 330.336 689.895 318.104 682.893 318.104 440.755 330.336 447.936"
        />
        <path
          class="cls-37"
          d="M335.33328,805.91543l-12.14-6.996.005-.00028a9.93474,9.93474,0,0,1-5.09435-8.7267V682.89288l12.2321,7.00173Z"
        />
        <path
          class="cls-38"
          d="M330.824,400.96314,792.71972,133.99157a9.93213,9.93213,0,0,1,10.18547.10024L802.902,134.08l13.15328,7.66937L330.336,447.89479l-12.2321-7.13959V422.99942A25.44784,25.44784,0,0,1,330.824,400.96314Z"
        />
        <path
          class="cls-20"
          d="M820.00058,516.952V164.92024L330.336,447.93559v349.3035a10.03153,10.03153,0,0,0,15.0484,8.68692L807.27944,538.98894A25.44791,25.44791,0,0,0,820.00058,516.952Z"
        />
        <path
          class="cls-39"
          d="M684.28588,495.15639,826.7747,412.96a3.99723,3.99723,0,0,1,5.99619,3.46152v87.37493a9.993,9.993,0,0,1-4.9957,8.65378L685.28641,594.64661a3.99722,3.99722,0,0,1-5.99619-3.46152V503.81016A9.99307,9.99307,0,0,1,684.28588,495.15639Z"
        />
        <path
          class="cls-39"
          d="M516.23009,591.69779l142.48883-82.19642a3.99723,3.99723,0,0,1,5.99619,3.46152v87.37493a9.99307,9.99307,0,0,1-4.9957,8.65378L517.23062,691.188a3.99722,3.99722,0,0,1-5.99618-3.46152V600.35156A9.99308,9.99308,0,0,1,516.23009,591.69779Z"
        />
        <path
          class="cls-39"
          d="M348.17431,688.23919l142.48883-82.19642a3.99722,3.99722,0,0,1,5.99618,3.46152v87.37493a9.993,9.993,0,0,1-4.99569,8.65377L349.17484,787.72941a3.99723,3.99723,0,0,1-5.99619-3.46152V696.893A9.99309,9.99309,0,0,1,348.17431,688.23919Z"
        />
        <polygon
          class="cls-40"
          points="820.001 409.608 820.001 164.92 330.336 447.895 330.336 689.895 820.001 409.608"
        />
        <g data-aos="fade-up" data-aos-duration="3000" data-aos-delay="1500">
          <line
            class="cls-41"
            x1="332.70572"
            y1="600.53881"
            x2="335.15641"
            y2="599.12304"
          />
          <line
            class="cls-42"
            x1="343.35611"
            y1="594.38524"
            x2="811.58609"
            y2="323.86303"
          />
          <line
            class="cls-41"
            x1="815.68619"
            y1="321.49413"
            x2="818.13639"
            y2="320.07836"
          />
        </g>
        <g data-aos="fade-right" data-aos-duration="3000" data-aos-delay="1500">
          <line
            class="cls-41"
            x1="621.27311"
            y1="280.66405"
            x2="621.27506"
            y2="283.49389"
          />
          <line
            class="cls-43"
            x1="621.28189"
            y1="293.08422"
            x2="621.4401"
            y2="518.45727"
          />
          <line
            class="cls-41"
            x1="621.44352"
            y1="523.25243"
            x2="621.44547"
            y2="526.08251"
          />
        </g>
        <path
          class="cls-44"
          d="M808.15983,193.29861l21.22277-12.25756a3.40167,3.40167,0,0,1,3.40046-.01675l5.52664,3.18555L812.654,228.11147l-5.48119-3.167a3.40038,3.40038,0,0,1-1.67444-2.9446V197.90876A5.32369,5.32369,0,0,1,808.15983,193.29861Z"
        />
        <path
          class="cls-45"
          d="M840.09166,211.31936V187.22161a3.43966,3.43966,0,0,0-5.15971-2.97871l-21.22274,12.25751a5.32364,5.32364,0,0,0-2.66148,4.61021v24.09111a3.43967,3.43967,0,0,0,5.15969,2.97873l21.22271-12.25086A5.32364,5.32364,0,0,0,840.09166,211.31936Z"
        />
        <path
          class="cls-46"
          d="M820.00058,164.92024V149.72491a10.03148,10.03148,0,0,0-15.04877-8.68663L343.056,408.00984a25.4479,25.4479,0,0,0-12.72,22.03626v17.84869Z"
        />
        <path
          class="cls-47"
          d="M351.4411,457.20613,794.14281,201.43272a3.40167,3.40167,0,0,1,3.40046-.01675l5.52664,3.18554L355.93527,492.019l-5.48119-3.167a3.40041,3.40041,0,0,1-1.67444-2.9446V461.81628A5.3237,5.3237,0,0,1,351.4411,457.20613Z"
        />
        <path
          class="cls-48"
          d="M804.85187,231.711V207.61328a3.43966,3.43966,0,0,0-5.15971-2.97871L356.99049,460.40793a5.32364,5.32364,0,0,0-2.66148,4.61021v24.09111a3.43967,3.43967,0,0,0,5.15968,2.97873L802.19034,236.32127A5.32365,5.32365,0,0,0,804.85187,231.711Z"
        />
        <g>
          <path
            class="cls-47"
            d="M428.51462,385.75678l-5.22863-3.02721-.00374-.00218-.01469-.0085.00034.00136a3.31776,3.31776,0,0,0-3.336.01088l-67.808,39.24948a6.70121,6.70121,0,0,0-3.34421,5.79979V439.935a3.31712,3.31712,0,0,0,1.7572,2.94256l-.00415.00224,5.01281,2.90061Z"
          />
          <path
            class="cls-48"
            d="M358.92225,445.79267l67.808-39.24946a6.70133,6.70133,0,0,0,3.34421-5.79978V388.58879a3.35065,3.35065,0,0,0-5.0292-2.89989l-67.808,39.24946a6.70132,6.70132,0,0,0-3.34421,5.79978v12.15464A3.35066,3.35066,0,0,0,358.92225,445.79267Z"
          />
        </g>
        <g class="cls-49">
          <ellipse
            class="cls-48"
            cx="577.39911"
            cy="527.60918"
            rx="6.0768"
            ry="3.50089"
            transform="translate(-168.2234 763.84689) rotate(-60)"
          />
          <ellipse
            class="cls-48"
            cx="595.15326"
            cy="517.3638"
            rx="6.0768"
            ry="3.50089"
            transform="translate(-150.47357 774.09974) rotate(-60)"
          />
          <ellipse
            class="cls-48"
            cx="612.90741"
            cy="507.11842"
            rx="6.0768"
            ry="3.50089"
            transform="translate(-132.72373 784.3526) rotate(-60)"
          />
          <ellipse
            class="cls-48"
            cx="630.66156"
            cy="496.87304"
            rx="6.0768"
            ry="3.50089"
            transform="translate(-114.97389 794.60546) rotate(-60)"
          />
          <ellipse
            class="cls-48"
            cx="648.41572"
            cy="486.62766"
            rx="6.0768"
            ry="3.50089"
            transform="translate(-97.22406 804.85831) rotate(-60)"
          />
        </g>
        <g>
          <path
            class="cls-47"
            d="M689.91023,479.13771,832.399,396.94133a3.95037,3.95037,0,0,1,3.87672-.06038l-.00244-.01155,5.552,3.20547L692.4077,581.79213l-5.3225-3.07832a3.95773,3.95773,0,0,1-2.1707-3.54736V487.79152A9.993,9.993,0,0,1,689.91023,479.13771Z"
          />
          <path
            class="cls-50"
            d="M695.23647,482.21038,837.7253,400.014a3.99723,3.99723,0,0,1,5.99619,3.46152v87.37493a9.993,9.993,0,0,1-4.9957,8.65377L696.237,581.7006a3.99722,3.99722,0,0,1-5.99618-3.46152V490.86415A9.99308,9.99308,0,0,1,695.23647,482.21038Z"
          />
        </g>
        <g>
          <path
            class="cls-47"
            d="M521.85445,575.67911,664.3432,493.48273a3.95035,3.95035,0,0,1,3.87672-.06038l-.00244-.01155,5.552,3.20547L524.35191,678.33353l-5.32249-3.07832a3.95774,3.95774,0,0,1-2.17071-3.54736V584.33292A9.99306,9.99306,0,0,1,521.85445,575.67911Z"
          />
          <path
            class="cls-51"
            d="M527.18069,578.75178l142.48883-82.19642a3.99722,3.99722,0,0,1,5.99618,3.46152v87.37493a9.993,9.993,0,0,1-4.99569,8.65377L528.18122,678.242a3.99723,3.99723,0,0,1-5.99619-3.46152V587.40555A9.99309,9.99309,0,0,1,527.18069,578.75178Z"
          />
        </g>
        <g>
          <path
            class="cls-47"
            d="M353.79867,672.22051l142.48874-82.19638a3.95035,3.95035,0,0,1,3.87672-.06038l-.00244-.01155,5.552,3.20547L356.29613,774.87493l-5.3225-3.07832a3.95775,3.95775,0,0,1-2.1707-3.54736V680.87432A9.993,9.993,0,0,1,353.79867,672.22051Z"
          />
          <path
            class="cls-52"
            d="M359.12491,675.29317l142.48882-82.19641a3.99723,3.99723,0,0,1,5.99619,3.46152v87.37493a9.993,9.993,0,0,1-4.9957,8.65377L360.12544,774.7834a3.99723,3.99723,0,0,1-5.99619-3.46152V683.947A9.99309,9.99309,0,0,1,359.12491,675.29317Z"
          />
        </g>
        <g data-aos="fade-left" data-aos-duration="3000" data-aos-delay="1500">
          <path
            class="cls-21"
            d="M869.88306,684.72992s2.88885,8.03916.219,12.84433c-1.809,3.25588-5.66365,4.40828-9.93355,6.7012-6.37358,3.4226-8.67215,5.834-17.96573,6.60144-9.1971.75948-11.37525-6.67028-8.55-10.49076,4.62514-6.25446,13.97408-9.70406,19.91966-17.92244Z"
          />
          <path
            class="cls-21"
            d="M875.38422,651.1914s3.41142,8.77326,1.31651,13.43076c-1.52347,3.38705-7.05433,3.42916-11.28038,6.676-6.174,4.74348-14.26966,5.82268-19.39589,5.82268-4.4423,0-10.77384-4.60054-7.60508-8.781,3.44889-4.55,20.08932-17.45483,20.08932-17.45483Z"
          />
          <path
            class="cls-53"
            d="M891.63755,533.50039l-21.44685,7.39379L827.26859,516.216V483.27863l20.73545-11.7885,43.6335,25.07285A92.25031,92.25031,0,0,1,891.63755,533.50039Z"
          />
          <path
            class="cls-54"
            d="M838.64078,396.85536c-7.44214.9604-15.01056,4.53438-20.25145,15.887l-17.51163,39.791-18.63545,22.85221,13.18912,11.33588,22.83789-21.64433,16.76615-22.11365Z"
          />
          <path
            class="cls-24"
            d="M822.96734,447.26385l-11.62572,24.22628,4.929-4.35176a17.63706,17.63706,0,0,0,2.38111-2.56564l6.08425-8.02479Z"
          />
          <path
            class="cls-55"
            d="M892.10084,502.706c-2.77341,5.1506-4.24416,8.21561-14.17766,12.31519-17.98614,7.34126-46.30889-.59786-50.61386-22.863,0,0,.45327-15.943,0-21.3913-.67289-8.0881-8.86834-37.48729-6.41214-52.97894,2.24806-14.17885,15.55731-20.51633,15.55731-20.51633,22.62021-5.16214,51.99564,14.503,55.78785,36.94831Z"
          />
          <polygon
            class="cls-24"
            points="868.361 443.083 870.734 487.537 863.969 512.104 869.251 515.337 876.408 488.532 868.361 443.083"
          />
          <path
            class="cls-24"
            d="M867.43166,409.86725c-4.16167,6.2084-23.63924,16.3311-23.63924,16.3311s-6.72025-17.55643-1.40692-25.75979c2.69752-4.16477,6.31119-3.84094,13.48034-2.64973S871.43044,403.90185,867.43166,409.86725Z"
          />
          <path
            class="cls-6"
            d="M864.21719,407.23019c-3.19164,4.4284-18.37856,11.59263-18.37856,11.59263s-3.75093-13.23993.33079-19.09593c2.07225-2.973,4.7019-2.647,9.90523-1.59976S867.2839,402.97512,864.21719,407.23019Z"
          />
          <path
            class="cls-7"
            d="M863.80986,404.20243c-.60775,4.78385-9.72246,7.42363-15.56078,5.59174-2.71513-.85192-3.26985-6.09934-1.18672-9.431a8.65639,8.65639,0,0,1,10.6191-3.16537C861.764,398.92422,864.1687,401.37777,863.80986,404.20243Z"
          />
          <path
            class="cls-7"
            d="M847.61563,403.886c-1.12729,5.88038,13.741,5.345,15.892,0l-.75123-16.65443-12.59625-1.56032Z"
          />
          <path
            class="cls-1"
            d="M838.54345,370.38309c-1.03512,5.35636.20883,13.337,7.74147,15.52238l1.31689-8.519Z"
          />
          <path
            class="cls-56"
            d="M851.293,403.03272c8.60807-2.34524,11.1502-6.33391,11.75676-10.38263L864.636,378.9282a11.16148,11.16148,0,0,0-9.80592-12.36936h0a11.16147,11.16147,0,0,0-12.36936,9.80592l-1.58623,13.72188C840.16657,396.21018,842.88349,405.32386,851.293,403.03272Z"
          />
          <path
            class="cls-1"
            d="M850.66843,361.96c-10.87306-2.75546-15.9294,9.53393-11.77281,14.88582,4.56874,5.88255,11.48054,2.03522,14.00044,3.97735.23783,6.32715,6.46164,5.17192,6.46164,5.17192-.67129,7.18835,3.97005,13.48293,3.97005,13.48293,3.49228-2.44082,5.70135-5.15333,8.62764-12.03947,1.88341-4.432,2.92581-13.52743-1.42033-16.19614C866.01079,362.45575,857.82011,364.0587,850.66843,361.96Z"
          />
          <path
            class="cls-12"
            d="M856.49188,386.05568s2.146-2.30574,3.78874-.427c1.11865,1.27936,1.27845,4.91841-.8527,7.15087a3.54934,3.54934,0,0,1-3.31038.75346Z"
          />
          <path
            class="cls-1"
            d="M852.89606,380.82314s.13074,6.99886.592,8.43814c.37805,1.17952,2.69,1.55242,2.83324.20065l.38724-3.61364Z"
          />
          <path
            class="cls-57"
            d="M827.26859,516.216s.65252,61.99712,4.333,80.37987,30.04513,57.74564,30.04513,57.74564c5.4858,8.78067,16.10261,2.36854,13.89516-4.359L863.10663,588.7694,856.04538,530.595Z"
          />
          <path
            class="cls-53"
            d="M891.63757,533.50038l-20.58932,81.47838-.70964,69.7975c-.4513,7.46805-18.70251,9.35907-19.06155,2.25006,0,0-5.686-67.79233-5.686-79.59078s7.04667-76.63351,7.04667-76.63351Z"
          />
          <path
            class="cls-58"
            d="M876.40852,521.1317,539.95014,615.7938l.01352,14.21141a10.57108,10.57108,0,0,0,5.32751,9.47057L615.188,679.80613a21.35436,21.35436,0,0,0,21.32215.01288L871.05625,544.76138a10.5747,10.5747,0,0,0,5.34869-9.30407l.00358.00285Z"
          />
          <path
            class="cls-59"
            d="M636.51011,669.18052,871.05617,534.12284a10.67717,10.67717,0,0,0,.01121-18.50268l-69.89679-40.3303a21.35433,21.35433,0,0,0-21.32215-.01292L545.30238,610.33463a10.67717,10.67717,0,0,0-.0112,18.50267L615.188,669.16761A21.35434,21.35434,0,0,0,636.51011,669.18052Z"
          />
          <path
            class="cls-21"
            d="M541.37028,825.94464c-2.28767.61951-4.34426,13.37562-1.05835,15.74914,4.49061,3.24373,8.16965-.25548,16.78585-.57952,6.60518-.24841,16.04005-2.02734,17.13049-9.673.72568-5.0881-6.43688-5.52107-9.5071-5.46915S541.37028,825.94464,541.37028,825.94464Z"
          />
          <path
            class="cls-12"
            d="M541.21407,827.75944c-.27034,4.22465,6.29992,4.81476,10.499,3.8046l-1.03591-3.34949,5.59256-23.38637-12.84982-2.69524Z"
          />
          <polygon
            class="cls-12"
            points="558.399 832.796 565.883 831.237 561.366 827.847 558.399 832.796"
          />
          <path
            class="cls-22"
            d="M527.83064,668.20127c.13249,19.64441,16.57105,83.47022,16.57105,83.47022L540.615,806.71212c-.32587,4.75908,14.351,7.531,15.39082,2.23976l7.50833-42.19232-4.60622-80.311Z"
          />
          <path
            class="cls-25"
            d="M597.31426,685.9786l-38.857,9.27926c-13.428-9.27927-25.96427-19.9398-30.62659-27.05661-.25886-14.75584,1.62889-22.76975,5.05685-33.18944l18.95121-11.64634,42.42937,28.71327C595.97415,663.37429,598.14207,672.44965,597.31426,685.9786Z"
          />
          <path
            class="cls-60"
            d="M594.04873,650.029l4.06055,16.79678c.541,2.49355-5.351,15.2547-37.43682,13.986-19.35671-.7654-34.46278-13.00328-33.39225-21.08391a210.97654,210.97654,0,0,1,4.42326-22.81114Z"
          />
          <path
            class="cls-3"
            d="M593.08187,645.26751l2.60491,11.53737c.85455,3.862-15.88386,13.67991-29.46085,12.98457-24.44869-1.25216-39.09709-14.09342-37.1268-22.834l3.61322-13.82256Z"
          />
          <path
            class="cls-3"
            d="M531.8556,566.65421s-7.29116,32.83867-6.38172,40.87516,10.21631,15.22542,10.21631,15.22542Z"
          />
          <path
            class="cls-61"
            d="M593.40228,642.51791a7.47852,7.47852,0,0,1-3.25589,8.24683,43.61994,43.61994,0,0,1-23.15386,6.83959c-16.82589,0-38.07005-12.03971-35.2914-25.68486l2.376-18.16409s-4.44242-38.99744-1.9244-48.67606c3.23589-12.43794,13.03177-14.291,18.87333-14.64166,5.29628-.318,41.12148,16.98793,44.252,27.22559l-6.1328,48.86665Z"
          />
          <path
            class="cls-3"
            d="M532.89254,601.401c11.54237-.60706,25.12638-10.79508,29.43563-17.17007L531.38,588.46763Z"
          />
          <path
            class="cls-3"
            d="M577.12565,600.84568l5.86887,53.586c7.0582-2.24825,11.44655-5.78238,10.9796-10.62168s-8.60476-33.111-8.60476-33.111Z"
          />
          <path
            class="cls-21"
            d="M548.66609,847.62416c-2.71991.19835-6.73781,13.4141-.98812,17.64479,4.79318,3.52692,9.26848.90757,17.54753-.47145,6.45194-1.07469,16.54551-1.041,19.9697-6.29369,3.4863-5.348-.91376-11.086-8.72429-11.12214-2.00619-.00928-10.9661.11329-17.0356-1.55378Z"
          />
          <path
            class="cls-12"
            d="M547.3861,851.90083c-.52084,4.26647,8.37111,4.91935,9.53332,1.177l7.07421-23.1397-12.64186-3.54482Z"
          />
          <path
            class="cls-12"
            d="M552.92551,855.53876,565.63,854.10714c2.34954-.27366,1.22026-5.65634-1.68843-6.12363l-5.33616-1.23664Z"
          />
          <path
            class="cls-25"
            d="M597.31427,685.97859s-2.9581,46.80674-8.01984,73.688c-3.03465,16.11606-22.03679,71.22552-22.03679,71.22552-2.08364,5.64238-19.18568.50526-18.23942-4.57584l11.765-69.63615-2.42541-64.04691Z"
          />
          <path
            class="cls-12"
            d="M630.26873,670.30517c1.66065,3.8486,3.49293,6.82375,8.5069,9.24287a3.14118,3.14118,0,0,0,3.04314-.16275l5.93073-3.76814a2.511,2.511,0,0,0,1.08946-2.72842l-.66049-2.642a4.66968,4.66968,0,0,0-1.53382-2.449l-7.95413-6.65474C631.82951,662.18475,629.05,665.25267,630.26873,670.30517Z"
          />
          <path
            class="cls-62"
            d="M647.66843,675.679l-.73923.45671-2.63219-4.19815a.44691.44691,0,0,1,.13037-.609.43774.43774,0,0,1,.60912.15228Z"
          />
          <path
            class="cls-62"
            d="M645.1236,677.28866l-.7395.45684-2.76257-4.02423a.45119.45119,0,0,1,.13038-.609.41974.41974,0,0,1,.58735.10874Z"
          />
          <path
            class="cls-62"
            d="M642.53522,678.92007l-.718.45684c-.02178,0-.02178.02165-.04355.02165l-2.80611-3.87182a.42892.42892,0,1,1,.69622-.50026Z"
          />
          <path
            class="cls-12"
            d="M638.69044,661.14308l8.33638,2.0013a1.25875,1.25875,0,0,1,0,2.49611l-2.93647.26916Z"
          />
          <polygon
            class="cls-12"
            points="626.667 663.976 630.269 670.305 638.69 661.143 632.679 658.103 626.667 663.976"
          />
          <path
            class="cls-63"
            d="M572.62579,590.97956s17.67377,42.26173,21.595,47.348,31.72692,27.18887,31.72692,27.18887c3.18533,3.61509,12.51379-2.67972,9.74225-7.75353l-22.10037-30.45167-17.94235-48.90316C590.53182,566.74569,566.9838,577.39968,572.62579,590.97956Z"
          />
          <path
            class="cls-64"
            d="M563.27373,550.32592c5.27,3.51475,11.65293-5.2172,11.65292-11.65293V524.25151a11.65294,11.65294,0,0,0-11.65294-11.65293h0a11.65292,11.65292,0,0,0-11.65292,11.65294V538.673C551.62078,545.10873,558.0037,546.81117,563.27373,550.32592Z"
          />
          <path
            class="cls-65"
            d="M552.05491,544.96612c.08775,3.60345,10.87306,9.347,12.77725,2.27,1.27893-2.27222,6.943-7.3325,7.01838-11.36561,6.74617-2.66828,10.07154-15.76107-2.76806-22.27859-7.59758-4.19355-17.841-.34212-21.70455,5.23059-2.87981,4.1538-2.9333,8.23005-1.76049,14.52324S551.95894,541.02514,552.05491,544.96612Z"
          />
          <path
            class="cls-3"
            d="M574.18616,560.21581c-.30335-.30335-3.52638-5.29636-3.69507-5.56792-1.88795-3.03939-17.28317-9.15219-19.43455-6.43122l-2.70767,2.49669C550.19057,558.10805,558.0946,560.20759,574.18616,560.21581Z"
          />
          <path
            class="cls-66"
            d="M572.18055,533.02916c.59811-1.44422,2.31472-2.298,3.78948-.19845,1.00425,1.42972,1.10546,4.40748-1.02646,7.39357a3.1251,3.1251,0,0,1-3.18444,1.31954S571.27,535.22774,572.18055,533.02916Z"
          />
          <g>
            <ellipse
              class="cls-67"
              cx="554.99568"
              cy="535.55742"
              rx="7.53805"
              ry="6.82059"
              transform="translate(-35.43061 1031.78191) rotate(-84.02427)"
            />
            <path
              class="cls-65"
              d="M561.53511,542.72278c2.46348-6.08037-1.6242-12.6227-6.754-12.95436s-10.03527,2.36943-12.6446,9.29373c-2.62274,6.9599-8.73759,8.79741-10.545,17.43319-1.39265,6.65416.56267,10.91443-2.64326,15.529a13.28457,13.28457,0,0,0,1.69825,17.1407c4.99221,4.80073,16.01777,5.32319,21.72156,2.66445,4.90388-2.28587,10.83625-5.98182,12.99225-14.01711,2.2782-8.49071-1.75958-14.71712-3.27815-20.3031C558.78712,545.38864,561.53511,542.72278,561.53511,542.72278Z"
            />
          </g>
          <g>
            <path
              class="cls-12"
              d="M875.82875,536.33849a79.70033,79.70033,0,0,1-2.53157,9.13244,4.32025,4.32025,0,0,1-.95569,1.677l-2.827,3.19733-.90624.4319-1.37317.6752-.0206.00671-2.82867,1.37695-.02047.00667-1.33881.64122-.02047.00667-2.966,1.44462-.68666.33755a1.86443,1.86443,0,0,1-.58945.14654,1.58644,1.58644,0,0,1-1.55677-.51687,2.02427,2.02427,0,0,1-.16608-1.76663l3.64172-9.35768,4.55013-10.1316C868.91288,525.733,876.1564,532.3853,875.82875,536.33849Z"
            />
            <g>
              <path
                class="cls-62"
                d="M864.94292,546.30449l-4.90287,8.6307-.68666.33755a1.86443,1.86443,0,0,1-.58945.14654l5.42227-9.55095a.437.437,0,0,1,.75671.43616Z"
              />
              <path
                class="cls-62"
                d="M868.37466,545.80064l-3.9889,7.03537-.02047.00667-1.33881.64122,4.59828-8.099a.42871.42871,0,0,1,.7499.41573Z"
              />
              <path
                class="cls-62"
                d="M871.48886,545.71881l-2.88066,5.05834-1.37317.6752,3.49713-6.1697a.437.437,0,1,1,.7567.43616Z"
              />
            </g>
            <path
              class="cls-12"
              d="M867.38378,530.77205l-6.70572,4.85625a4.58339,4.58339,0,0,0-1.78587,2.71772l-1.10932,4.99076c-.14743,1.49662,1.28791,2.28682,2.64164.92787Z"
            />
          </g>
          <path
            class="cls-68"
            d="M892.24234,434.22s8.43341,45.90266,6.33627,56.83453c-1.608,8.382-19.26681,43.18917-19.26681,43.18917-3.4723,6.10137-16.5864-.351-14.50193-6.28571l11.59854-39.42626-8.672-48.97555C864.52072,420.64491,890.06323,420.48029,892.24234,434.22Z"
          />
        </g>
        <g data-aos="fade-right" data-aos-duration="2000" data-aos-delay="1800">
          <polygon
            class="cls-69"
            points="145.471 798.957 43.212 799.026 43.212 746.815 162.638 747.486 164.562 763.546 188.619 768.371 146.139 778.645 145.471 798.957"
          />
          <path
            class="cls-21"
            d="M154.38423,749.43542s-1.15076,10.9539,1.69328,13.53132c2.628,2.38164,9.50028,2.05516,12.92243,2.39737,8.14362.81436,10.33527,3.28124,18.71121,2.9821,8.18615-.29236,10.5595-9.24575,4.45407-11.70871a140.65488,140.65488,0,0,1-17.36743-8.85482Z"
          />
          <path
            class="cls-57"
            d="M179.438,579.59488c1.285,59.548-.92936,115.34745-4.26527,168.618-.21718,5.32113-20.83243,5.111-20.83243.93616L144.10913,601.70183Z"
          />
          <path
            class="cls-21"
            d="M119.12133,770.45375s-1.21652,6.65418-.00765,10.49818c.61582,1.95822,1.39411,3.50044,7.18759,6.55518,5.03313,2.65384,6.70729,8.57191,15.67433,11.103,8.48473,2.39495,17.72723-6.09021,11.87065-11.78935-5.5741-5.42426-14.39746-15.248-15.46534-19.553Z"
          />
          <polygon
            class="cls-53"
            points="116.694 604.556 138.193 617.331 176.581 579.52 175.874 562.596 115.673 582.024 116.694 604.556"
          />
          <path
            class="cls-53"
            d="M157.33984,599.17062,140.056,771.86208c-.33033,5.37983-21.08815,4.80649-20.91411,0l-2.44787-167.30655Z"
          />
          <path
            class="cls-70"
            d="M149.12208,466.68085s17.67374-3.58362,25.28851,11.01556c5.10918,9.79541,23.52919,52.81082,23.52919,52.81082l-16.46228,24.715-25.47277-49.18545Z"
          />
          <polygon
            class="cls-71"
            points="184.633 537.769 174.156 544.285 174.156 498.024 184.633 537.769"
          />
          <path
            class="cls-72"
            d="M112.031,587.21859c-.56287,15.24551,14.18462,20.02883,31.50294,20.93366,11.89877.62167,38.47862-3.76835,38.00805-23.78218,0,0-2.96033-28.5615-4.96762-54.72266-1.791-23.34194-3.18172-47.76067-4.80261-51.69973-5.31638-12.91985-20.15991-11.66992-20.15991-11.66992-18.79186,2.34546-43.09962,24.321-45.59331,38.62628l.42309,4.45276,8.85325,42.86931Z"
          />
          <path
            class="cls-12"
            d="M189.694,524.83662l-1.91406-1.09454a4.15742,4.15742,0,0,0-4.79783.477l-4.79169,4.18287a3.58181,3.58181,0,0,0-.77383,4.441l2.27212,4.0802Z"
          />
          <path
            class="cls-48"
            d="M180.78325,508.77912h.35348a1.35337,1.35337,0,0,1,1.35337,1.35337v18.43508a1.35336,1.35336,0,0,1-1.35336,1.35336h-.35348a1.35337,1.35337,0,0,1-1.35337-1.35337V510.13248a1.35337,1.35337,0,0,1,1.35337-1.35337Z"
            transform="translate(-58.80264 24.39058) rotate(-6.6358)"
          />
          <polygon
            class="cls-71"
            points="132.286 517.583 135.607 543.973 172.1 545.499 178.935 557.446 179.444 562.931 113.004 576.787 115.734 547.185 132.286 517.583"
          />
          <polygon
            class="cls-57"
            points="159.656 606.622 161.774 575.791 168.702 603.996 159.656 606.622"
          />
          <polygon
            class="cls-6"
            points="143.641 472.64 152.286 478.894 153.604 492.418 139.985 483.794 128.936 476.348 143.641 472.64"
          />
          <path
            class="cls-73"
            d="M150.7012,470.03868c4.64933,2.9135,11.2531,12.40194,11.2531,12.40194l-6.60377-2.8389-11.35561-12.76159A24.21589,24.21589,0,0,1,150.7012,470.03868Z"
          />
          <path
            class="cls-7"
            d="M134.29894,479.06062c4.09146,4.78131,15.538,6.73855,15.39326,2.36192l-.47946-15.53956-15.42444,2.82945Z"
          />
          <path
            class="cls-65"
            d="M157.68935,440.85562c2.20663,6.71216-1.22538,11.00838-2.633,15.16762l-1.18571-9.46421Z"
          />
          <path
            class="cls-73"
            d="M143.66971,485.54913l-4.86412,8.1446c-6.69282-5.15824-9.52632-10.49886-10.40694-17.09653l5.38965-7.88477C134.638,474.14546,138.10424,480.63523,143.66971,485.54913Z"
          />
          <path
            class="cls-73"
            d="M149.55566,476.99749c2.36723,3.63774,3.30713,9.74987,3.8415,13.30184l1.95337-10.69761-5.92932-6.96218Z"
          />
          <path
            class="cls-74"
            d="M145.01878,474.64371c6.9676,1.20126,11.55221-5.363,10.99626-11.839l-1.24579-14.51186a11.76909,11.76909,0,0,0-12.7326-10.71933h0a11.76909,11.76909,0,0,0-10.71933,12.73259l2.09278,14.5619C133.966,471.344,138.05118,473.44246,145.01878,474.64371Z"
          />
          <g>
            <path
              class="cls-65"
              d="M157.68935,440.85562c1.25088,4.876-3.98357,9.71363-11.80236,15.1929-4.82494,3.38124-8.16536.89046-8.16536.89046-.66111,4.37391-1.80942,11.10029-3.65826,13.147,0,0-9.91886-6.00824-11.47644-15.692-.86033-5.34883.307-11.40036,7.52082-17.71156S155.31823,431.613,157.68935,440.85562Z"
            />
            <path
              class="cls-75"
              d="M138.81137,457.49814s-2.47157-2.10671-4.26818-.02166c-1.22343,1.41985-1.30773,5.13014,1.14526,7.16409,1.47591,1.2238,3.73925.40493,3.73925.40493Z"
            />
            <path
              class="cls-65"
              d="M138.79906,457.45811l1.91919,5.40366c.383,1.09218,2.07036.81862,1.9159-.30714l-.2572-5.27957Z"
            />
          </g>
          <polygon
            class="cls-71"
            points="138.806 493.694 144.186 489.567 151.753 491.246 143.67 485.549 138.806 493.694"
          />
          <polygon
            class="cls-71"
            points="153.397 490.299 155.351 479.602 161.954 482.441 157.676 482.889 153.397 490.299"
          />
          <path
            class="cls-21"
            d="M186.19994,553.18612l-24.718,12.3417a1.66306,1.66306,0,0,1-2.13516-2.39754l24.11864-36.91509a10.046,10.046,0,0,1,3.92241-3.49315l24.718-12.3417a1.66307,1.66307,0,0,1,2.13516,2.39754L190.12234,549.693A10.046,10.046,0,0,1,186.19994,553.18612Z"
          />
          <path
            class="cls-12"
            d="M157.58588,553.98549l7.26331-8.65171a4.61821,4.61821,0,0,1,2.49841-1.53051l5.44814-1.25748-1.38651,2.12214,5.33724.947a2.39325,2.39325,0,0,1,1.72254,3.42662l-4.99528,7.06326a6.84068,6.84068,0,0,1-3.96207,2.69545l-9.73668,2.378Z"
          />
          <path
            class="cls-70"
            d="M105.31209,510.74178l.41131,46.15389c-.4249,6.98411,5.96561,14.82979,14.5812,12.78033l37.73174-4.62133c3.85919-.614,4.10064-15.26133-.81669-15.41652L131.48,547.88234l.84083-34.85882C133.07725,500.14088,106.44166,481.86621,105.31209,510.74178Z"
          />
        </g>
        <g >
          <g data-aos="fade-down" data-aos-duration="2000" data-aos-delay="2500">
            <path
              class="cls-76"
              d="M369.65019,334.23546l19.94064-11.517a3.19615,3.19615,0,0,1,3.195-.01573l5.19276,2.99309-24.10576,41.24939-5.15005-2.97565a3.195,3.195,0,0,1-1.57328-2.76671V338.56709A5.00206,5.00206,0,0,1,369.65019,334.23546Z"
            />
            <path
              class="cls-77"
              d="M399.65292,351.16751V328.52559a3.23186,3.23186,0,0,0-4.848-2.79876l-19.9406,11.517a5.002,5.002,0,0,0-2.50069,4.3317V364.2112a3.23187,3.23187,0,0,0,4.848,2.79878l19.94058-11.51075A5.002,5.002,0,0,0,399.65292,351.16751Z"
            />
          </g>
          <g data-aos="fade-down" data-aos-duration="3000" data-aos-delay="1500">
            <path
              class="cls-76"
              d="M333.77634,354.77014l19.94064-11.517a3.19615,3.19615,0,0,1,3.195-.01574l5.19276,2.9931L337.999,387.47985l-5.15006-2.97566a3.195,3.195,0,0,1-1.57328-2.7667V359.10177A5.00207,5.00207,0,0,1,333.77634,354.77014Z"
            />
            <path
              class="cls-77"
              d="M363.77907,371.7022V349.06027a3.23186,3.23186,0,0,0-4.848-2.79875l-19.9406,11.517a5.002,5.002,0,0,0-2.50069,4.3317v22.63568a3.23186,3.23186,0,0,0,4.848,2.79877l19.94058-11.51074A5.002,5.002,0,0,0,363.77907,371.7022Z"
            />
          </g>
        </g>
        <path
          class="cls-78"
          d="M629.16877,422.33767l20.14313-11.65949a1.9907,1.9907,0,0,0,.99343-1.72289v-5.595a.99534.99534,0,0,0-1.494-.86144l-20.14312,11.65949a1.99069,1.99069,0,0,0-.99344,1.72289v5.595A.99535.99535,0,0,0,629.16877,422.33767Z"
        />
        <g class="cls-79">
          <ellipse
            class="cls-80"
            cx="768.26767"
            cy="177.56791"
            rx="6.80114"
            ry="3.91819"
            transform="translate(230.35552 754.12327) rotate(-60)"
          />
          <ellipse
            class="cls-80"
            cx="783.77684"
            cy="167.91125"
            rx="6.80114"
            ry="3.91819"
            transform="matrix(0.5, -0.86603, 0.86603, 0.5, 246.47301, 762.72628)"
          />
          <ellipse
            class="cls-80"
            cx="798.99338"
            cy="158.98854"
            rx="6.80114"
            ry="3.91819"
            transform="translate(261.80857 771.44284) rotate(-60)"
          />
        </g>
        <polygon
          class="cls-81"
          points="386.131 801.738 401.287 792.987 416.438 801.726 401.285 810.512 386.131 801.738"
        />
        <g>
          <ellipse
            class="cls-59"
            cx="277.59958"
            cy="779.65753"
            rx="3.91819"
            ry="6.80114"
            transform="translate(-352.63748 243.25409) rotate(-30)"
          />
          <ellipse
            class="cls-59"
            cx="262.09041"
            cy="770.00088"
            rx="3.91819"
            ry="6.80114"
            transform="matrix(0.86603, -0.5, 0.5, 0.86603, -349.88698, 234.20576)"
          />
          <ellipse
            class="cls-59"
            cx="246.87386"
            cy="761.07817"
            rx="3.91819"
            ry="6.80114"
            transform="translate(-347.46426 225.40207) rotate(-30)"
          />
        </g>
        <path
          class="cls-31"
          d="M250.88345,536.06576l.523-.24133a5.071,5.071,0,0,1,2.65822-.43833l6.42191.67964c1.34582,0,1.218,1.76226-.14043,2.09786l-3.08587.86548Z"
        />
        <path
          class="cls-82"
          d="M259.46311,544.42021l-.47166.49055-1.39612-3.18847a.37192.37192,0,1,1,.67894-.30193l.755,1.69805Z"
        />
        <path
          class="cls-82"
          d="M257.80261,546.11826l-.5281.52821-2.16971-2.69793a.37572.37572,0,1,1,.585-.47166Z"
        />
        <path
          class="cls-82"
          d="M255.93482,548.0237l-.52809.54721-2.37722-2.45265a.38048.38048,0,0,1,.547-.52832Z"
        />
        <path
          class="cls-31"
          d="M270.19833,554.01278l-1.49072,7.7837c-.28914,1.30058,2.12256,1.86778,2.8344.49287l1.00649-2.78706Z"
        />
        <path
          class="cls-7"
          d="M178.10973,549.5519l-.547.7736-3.75445.07542h-.01889a.40553.40553,0,0,1-.37747-.37736.37852.37852,0,0,1,.37747-.37724Z"
        />
        <path
          class="cls-7"
          d="M175.90247,552.665l-.54721.7736-2.28279.03765h-.01889a.38759.38759,0,0,1-.37724-.35847.40825.40825,0,0,1,.37724-.39613Z"
        />
        <path
          class="cls-7"
          d="M174.03469,555.28744l-.52833.77349-1.16972.01888h-.01889a.38779.38779,0,0,1-.37747-.35847.40846.40846,0,0,1,.37747-.39613Z"
        />
        <path
          class="cls-7"
          d="M497.674,202.89535l-3.73557,2.43388a.38161.38161,0,0,1-.20773.05654.39384.39384,0,0,1-.32059-.16974.36862.36862,0,0,1,.11308-.50943l3.698-2.415Z"
        />
        <path
          class="cls-7"
          d="M499.73037,205.64993l-3.81111,2.49042a.55889.55889,0,0,1-.20751.07542.38912.38912,0,0,1-.32081-.18862.35352.35352,0,0,1,.11308-.50944l3.77333-2.47153Z"
        />
        <path
          class="cls-7"
          d="M501.749,208.34785l-3.67891,2.415a.49366.49366,0,0,1-.20774.05654.37837.37837,0,0,1-.20727-.698l3.64114-2.37722Z"
        />
        <path
          class="cls-7"
          d="M499.73037,216.85387c-.38974.50161-1.7518-.152-1.43873-1.188l.79762-2.28808-1.90176,1.83784-.55135,5.62072Z"
        />
        <rect
          class="cls-11"
          x="497.15145"
          y="212.94368"
          width="6.30739"
          height="6.30739"
          rx="3.15369"
          transform="translate(-10.17617 406.91702) rotate(-43.85693)"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "websitesvg",
  created() {},
};
</script>

<style scoped>
#websitesvg #websitesvg .cls-1 {
  fill: #5b312d;
}

#websitesvg #websitesvg .cls-2 {
  fill: #7bb8f5;
}

#websitesvg #websitesvg .cls-3 {
  fill: #5681bf;
}

#websitesvg #websitesvg .cls-4 {
  fill: #304b72;
}

#websitesvg #websitesvg .cls-5 {
  fill: url(#linear-gradient-website);
}

#websitesvg #websitesvg .cls-6 {
  fill: #b4dff9;
}

#websitesvg #websitesvg .cls-7 {
  fill: #d36e6e;
}

#websitesvg .cls-8 {
  fill: url(#linear-gradient-website-2);
}

#websitesvg .cls-9 {
  fill: url(#linear-gradient-website-3);
}

#websitesvg .cls-10 {
  fill: #ff3b57;
}

#websitesvg .cls-11 {
  fill: #395675;
}

#websitesvg .cls-12 {
  fill: #f9a49d;
}

#websitesvg .cls-13 {
  fill: url(#linear-gradient-website-4);
}

#websitesvg .cls-14,
#websitesvg .cls-41,
#websitesvg .cls-42,
#websitesvg .cls-43 {
  fill: none;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

#websitesvg .cls-14 {
  stroke: #4881d3;
  stroke-width: 1.415px;
  opacity: 0.4;
}

#websitesvg .cls-15,
#websitesvg .cls-78 {
  opacity: 0.6;
}

#websitesvg .cls-15 {
  fill: url(#linear-gradient-website-5);
}

#websitesvg .cls-16,
#websitesvg .cls-17,
#websitesvg .cls-34,
#websitesvg .cls-49,
#websitesvg .cls-69 {
  opacity: 0.5;
}

#websitesvg .cls-16 {
  fill: url(#linear-gradient-website-6);
}

#websitesvg .cls-17 {
  fill: url(#linear-gradient-website-7);
}

#websitesvg .cls-18 {
  fill: url(#linear-gradient-website-8);
}

#websitesvg .cls-19 {
  fill: #5d80ce;
}

#websitesvg .cls-20 {
  fill: #8cbdf9;
}

#websitesvg .cls-21 {
  fill: #253854;
}

#websitesvg .cls-22 {
  fill: #20314c;
}

#websitesvg .cls-23 {
  fill: #3c88f2;
}

#websitesvg .cls-24 {
  fill: #3753b7;
}

#websitesvg .cls-25 {
  fill: #2b466d;
}

#websitesvg .cls-26 {
  fill: url(#linear-gradient-website-9);
}

#websitesvg .cls-27 {
  fill: #3e62c6;
}

#websitesvg .cls-28 {
  fill: url(#linear-gradient-website-10);
}

#websitesvg .cls-29 {
  fill: #af5a5a;
}

#websitesvg .cls-30 {
  fill: #12303f;
}

#websitesvg .cls-31 {
  fill: #c96969;
}

#websitesvg .cls-32,
#websitesvg .cls-34 {
  fill: #769acc;
}

#websitesvg .cls-33 {
  fill: #8fbbef;
}

#websitesvg .cls-35 {
  fill: #9dc7f9;
}

#websitesvg .cls-36 {
  fill: #4670ce;
}

#websitesvg .cls-37 {
  fill: #739be0;
}

#websitesvg .cls-38 {
  fill: url(#linear-gradient-website-11);
}

#websitesvg .cls-39 {
  fill: #71a8e2;
}

#websitesvg .cls-40,
#websitesvg .cls-79 {
  opacity: 0.7;
}

#websitesvg .cls-40 {
  fill: url(#linear-gradient-website-12);
}

#websitesvg .cls-41,
#websitesvg .cls-42,
#websitesvg .cls-43 {
  stroke: #a0cdf9;
  stroke-width: 1.88667px;
}

#websitesvg .cls-42 {
  stroke-dasharray: 5.68224 9.4704;
}

#websitesvg .cls-43 {
  stroke-dasharray: 5.7542 9.59034;
}

#websitesvg .cls-44 {
  fill: url(#linear-gradient-website-13);
}

#websitesvg .cls-45 {
  fill: url(#linear-gradient-website-14);
}

#websitesvg .cls-46 {
  fill: url(#linear-gradient-website-15);
}

#websitesvg .cls-47 {
  fill: #a0cdf9;
}

#websitesvg .cls-48,
#websitesvg .cls-78 {
  fill: #cbe5f4;
}

#websitesvg .cls-50 {
  fill: url(#linear-gradient-website-16);
}

#websitesvg .cls-51 {
  fill: url(#linear-gradient-website-17);
}

#websitesvg .cls-52 {
  fill: url(#linear-gradient-website-18);
}

#websitesvg .cls-53 {
  fill: #476ba0;
}

#websitesvg .cls-54 {
  fill: url(#linear-gradient-website-19);
}

#websitesvg .cls-55 {
  fill: url(#linear-gradient-website-20);
}

#websitesvg .cls-56 {
  fill: url(#linear-gradient-website-21);
}

#websitesvg .cls-57 {
  fill: #355077;
}

#websitesvg .cls-58 {
  fill: #9fcaf4;
}

#websitesvg .cls-59 {
  fill: #dceff9;
}

#websitesvg .cls-60 {
  fill: #a5cdef;
}

#websitesvg .cls-61 {
  fill: url(#linear-gradient-website-22);
}

#websitesvg .cls-62 {
  fill: #d36c6c;
}

#websitesvg .cls-63 {
  fill: url(#linear-gradient-website-23);
}

#websitesvg .cls-64 {
  fill: url(#linear-gradient-website-24);
}

#websitesvg .cls-65 {
  fill: #1a2c47;
}

#websitesvg .cls-66 {
  fill: url(#linear-gradient-website-25);
}

#websitesvg .cls-67 {
  fill: url(#linear-gradient-website-26);
}

#websitesvg .cls-68 {
  fill: url(#linear-gradient-website-27);
}

#websitesvg .cls-69 {
  fill: url(#linear-gradient-website-28);
}

#websitesvg .cls-70 {
  fill: #3660b3;
}

#websitesvg .cls-71 {
  fill: #28417f;
}

#websitesvg .cls-72 {
  fill: url(#linear-gradient-website-29);
}

#websitesvg .cls-73 {
  fill: #3c71bc;
}

#websitesvg .cls-74 {
  fill: url(#linear-gradient-website-30);
}

#websitesvg .cls-75 {
  fill: url(#linear-gradient-website-31);
}

#websitesvg .cls-76 {
  fill: #5980ea;
}

#websitesvg .cls-77 {
  fill: #61a1ff;
}

#websitesvg .cls-80 {
  fill: #53b2f9;
}

#websitesvg .cls-81 {
  fill: #4881d3;
  opacity: 0.2;
}

#websitesvg .cls-82 {
  fill: #9b4e4e;
}
</style>