<template>
<div id="smallwebsitesvg">
<svg data-name="smallwebsite" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500">
  <defs>
    <linearGradient id="linear-gradient-smallwebsite" x1="78.16228" y1="352.13838" x2="393.10299" y2="352.13838" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#c3e0fc"/>
      <stop offset="0.99956" stop-color="#c3e4fc"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallwebsite-2" x1="470.22875" y1="404.94353" x2="284.08376" y2="221.05484" gradientUnits="userSpaceOnUse">
      <stop offset="0.04212" stop-color="#5076e6"/>
      <stop offset="1" stop-color="#69f"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallwebsite-3" x1="115.90388" y1="161.11269" x2="401.83772" y2="161.11269" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#518de6"/>
      <stop offset="0.95788" stop-color="#4769cc"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallwebsite-4" x1="113.0765" y1="255.29978" x2="383.56297" y2="255.29978" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#80c0ff"/>
      <stop offset="0.96003" stop-color="#7297ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallwebsite-5" x1="113.0765" y1="343.47085" x2="383.56297" y2="343.47085" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#66a5ff"/>
      <stop offset="0.95788" stop-color="#5076e6"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallwebsite-6" x1="113.0765" y1="166.36175" x2="383.56297" y2="166.36175" xlink:href="#linear-gradient-smallwebsite-5"/>
  </defs>
  <g>
    <g>
      <g>
        <path class="cls-1" d="M392.76013,399.06556,140.17533,253.1805,81.26865,286.66494a6.15009,6.15009,0,0,0-.02582,10.6734l264.31583,152.239a11.34262,11.34262,0,0,0,11.30513.02005L393.103,428.868Z"/>
        <path class="cls-2" d="M392.02605,208.671,141.15983,63.78406a6.45957,6.45957,0,0,0-6.28788-.1244l.0016-.00958-.11256.06479c-.08954.049-.17762.09939-.2649.15248L115.90388,74.56711,380.48062,436.1992l18.26265-10.49554-.00851-.00572a6.46392,6.46392,0,0,0,3.103-5.55278V225.66267A19.61874,19.61874,0,0,0,392.02605,208.671Z"/>
        <path class="cls-3" d="M392.02605,208.671,141.15983,63.78406a6.45957,6.45957,0,0,0-6.28788-.1244l.0016-.00958-.11256.06479c-.08954.049-.17762.09939-.2649.15248L115.90388,74.56711,383.563,259.32164l18.27475-10.53773V225.66267A19.61874,19.61874,0,0,0,392.02605,208.671Z"/>
        <path class="cls-4" d="M113.0765,274.3645V79.95121a6.53961,6.53961,0,0,1,9.80866-5.6639L373.75129,219.17419a19.61882,19.61882,0,0,1,9.81168,16.9917V430.64834a6.53961,6.53961,0,0,1-9.81034,5.66293l-250.8695-144.958A19.61879,19.61879,0,0,1,113.0765,274.3645Z"/>
        <path class="cls-5" d="M113.0765,274.3645V249.744L383.563,405.96254v24.6858a6.53961,6.53961,0,0,1-9.81034,5.66293l-250.8695-144.958A19.61879,19.61879,0,0,1,113.0765,274.3645Z"/>
        <g class="cls-6">
          <ellipse class="cls-7" cx="225.55791" cy="328.50993" rx="3.36032" ry="5.8328" transform="matrix(0.86603, -0.5, 0.5, 0.86603, -134.03593, 156.79094)"/>
          <ellipse class="cls-7" cx="238.85892" cy="336.79169" rx="3.36032" ry="5.8328" transform="translate(-136.39482 164.55099) rotate(-30)"/>
          <ellipse class="cls-7" cx="251.90896" cy="344.444" rx="3.36032" ry="5.8328" transform="matrix(0.86603, -0.5, 0.5, 0.86603, -138.4726, 172.10123)"/>
        </g>
        <path class="cls-8" d="M113.0765,103.1734V79.95121a6.53961,6.53961,0,0,1,9.80866-5.6639L373.75129,219.17419a19.61882,19.61882,0,0,1,9.81168,16.9917v23.15575Z"/>
      </g>
      <path class="cls-9" d="M161.06151,120.01756,127.21475,100.426a3.345,3.345,0,0,1-1.66928-2.895V91.46391a1.6725,1.6725,0,0,1,2.51036-1.44749l33.84676,19.5916a3.345,3.345,0,0,1,1.66928,2.895v6.06706A1.6725,1.6725,0,0,1,161.06151,120.01756Z"/>
    </g>
    <g>
      <path class="cls-10" d="M328.98915,243.38905l-24.95693-14.44587a2.46644,2.46644,0,0,1-1.23085-2.13463V222.335a1.23321,1.23321,0,0,1,1.851-1.06731l24.95694,14.44588a2.46642,2.46642,0,0,1,1.23084,2.13462v4.47355A1.23321,1.23321,0,0,1,328.98915,243.38905Z"/>
      <path class="cls-10" d="M293.84034,223.12059l-24.95693-14.44587a2.46644,2.46644,0,0,1-1.23085-2.13463v-4.47355a1.23321,1.23321,0,0,1,1.851-1.06731l24.95694,14.44588a2.46642,2.46642,0,0,1,1.23084,2.13462v4.47355A1.23321,1.23321,0,0,1,293.84034,223.12059Z"/>
      <path class="cls-10" d="M364.138,263.65751,339.181,249.21164a2.46644,2.46644,0,0,1-1.23085-2.13463v-4.47355a1.23321,1.23321,0,0,1,1.851-1.06731L364.75813,255.982a2.46642,2.46642,0,0,1,1.23084,2.13462v4.47355A1.23321,1.23321,0,0,1,364.138,263.65751Z"/>
    </g>
    <g>
      <path class="cls-11" d="M186.05748,306.63131l-77.15809-44.6616a7.62536,7.62536,0,0,1-3.80535-6.59952V123.43894a3.81269,3.81269,0,0,1,5.72269-3.29976l77.15809,44.6616a7.62536,7.62536,0,0,1,3.80535,6.59952V303.33155A3.81269,3.81269,0,0,1,186.05748,306.63131Z"/>
      <path class="cls-12" d="M145.92675,164.06555,112.08,144.474a3.345,3.345,0,0,1-1.66928-2.895v-6.067a1.6725,1.6725,0,0,1,2.51036-1.4475l33.84676,19.5916a3.345,3.345,0,0,1,1.66928,2.895v6.06706A1.67249,1.67249,0,0,1,145.92675,164.06555Z"/>
    </g>
    <path class="cls-13" d="M369.97556,385.49577l-38.53089-22.30292a3.80792,3.80792,0,0,1-1.9003-3.29564v-13.5464a1.904,1.904,0,0,1,2.85777-1.64782L370.933,367.00591a3.80792,3.80792,0,0,1,1.9003,3.29564V383.848A1.904,1.904,0,0,1,369.97556,385.49577Z"/>
  </g>
</svg>


</div>

</template>

<script>
export default {
   name: 'smallwebsitesvg',
   created(){

   }
}
</script>

<style scoped>
   #smallwebsitesvg .cls-1 {
        fill: url(#linear-gradient-smallwebsite);
      }

      #smallwebsitesvg .cls-2 {
        fill: url(#linear-gradient-smallwebsite-2);
      }

      #smallwebsitesvg .cls-3 {
        fill: url(#linear-gradient-smallwebsite-3);
      }

      #smallwebsitesvg .cls-4 {
        fill: url(#linear-gradient-smallwebsite-4);
      }

      #smallwebsitesvg .cls-10, #smallwebsitesvg .cls-12, #smallwebsitesvg .cls-5, #smallwebsitesvg .cls-6 {
        opacity: 0.5;
      }

      #smallwebsitesvg .cls-5 {
        fill: url(#linear-gradient-smallwebsite-5);
      }

      #smallwebsitesvg .cls-10, #smallwebsitesvg .cls-7 {
        fill: #cae8ff;
      }

      #smallwebsitesvg .cls-8 {
        fill: url(#linear-gradient-smallwebsite-6);
      }

      #smallwebsitesvg .cls-9 {
        fill: #a1dbff;
        opacity: 0.6;
      }

      #smallwebsitesvg .cls-11 {
        fill: #abd5ff;
      }

      #smallwebsitesvg .cls-12 {
        fill: #5e9be5;
      }

      #smallwebsitesvg .cls-13 {
        fill: #284199;
        opacity: 0.3;
      }
</style>