<template>
<div id="smallrocketsvg">
<svg data-name="small rocket" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500">
  <defs>

    <linearGradient id="linear-gradient-smallrocket" x1="138.9929" y1="139.63902" x2="373.09893" y2="378.86213" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#264c99"/>
      <stop offset="1" stop-color="#1f4082"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallrocket-2" x1="316.54897" y1="422.71634" x2="412.92522" y2="297.09773" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#9dc5ed"/>
      <stop offset="0.99185" stop-color="#cfe0f4"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallrocket-3" x1="347.07267" y1="404.01622" x2="347.07267" y2="287.6101" gradientUnits="userSpaceOnUse">
      <stop offset="0.06788" stop-color="#d96038"/>
      <stop offset="0.99497" stop-color="#ffb875"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallrocket-4" x1="348.2988" y1="409.15087" x2="414.82073" y2="322.44493" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#b8d4f4"/>
      <stop offset="0.99185" stop-color="#cfe0f4"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallrocket-5" x1="211.93802" y1="267.4565" x2="232.83273" y2="349.32965" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#57a8f9"/>
      <stop offset="1" stop-color="#4d89ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallrocket-6" x1="313.04642" y1="163.16377" x2="417.0597" y2="163.16377" gradientTransform="translate(-114.03257 143.38777) rotate(2.27532)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#3c88f2"/>
      <stop offset="1" stop-color="#4e69f2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallrocket-7" x1="383.6269" y1="55.74226" x2="474.95437" y2="55.74226" gradientTransform="translate(-114.03257 143.38777) rotate(2.27532)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#57c3f9"/>
      <stop offset="1" stop-color="#4d89ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallrocket-8" x1="390.22524" y1="78.24683" x2="494.2275" y2="78.24683" xlink:href="#linear-gradient-smallrocket-6"/>
    <linearGradient id="linear-gradient-smallrocket-9" x1="103.25881" y1="100.45378" x2="307.59747" y2="302.01861" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#bde6ff"/>
      <stop offset="1" stop-color="#8cc6ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-smallrocket-10" x1="90.16335" y1="57.84654" x2="116.60786" y2="137.18009" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlink:href="#linear-gradient-smallrocket-6"/>
    <linearGradient id="linear-gradient-smallrocket-11" x1="368.3054" y1="137.26351" x2="475.22226" y2="137.26351" xlink:href="#linear-gradient-smallrocket-6"/>
    <linearGradient id="linear-gradient-smallrocket-12" x1="176.39721" y1="126.03507" x2="196.29694" y2="186.3028" gradientTransform="translate(161.94809 -86.35831) rotate(45.14847)" xlink:href="#linear-gradient-smallrocket-6"/>
    <linearGradient id="linear-gradient-smallrocket-13" x1="174.15307" y1="132.9749" x2="204.00265" y2="184.99846" gradientTransform="translate(161.94809 -86.35831) rotate(45.14847)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#1f4082"/>
      <stop offset="1" stop-color="#264c99"/>
    </linearGradient>
  </defs>
  <g>
    <g>
      <circle class="cls-1" cx="261.00014" cy="264.31309" r="178.16205"/>
      <path class="cls-2" d="M351.58736,387.69659a27.35963,27.35963,0,1,0-37.04246-3.58808l-.00463.00333a27.37585,27.37585,0,0,0,7.58073,47.59342c1.37393-.4974,2.76-1.01851,4.12206-1.55148a176.75431,176.75431,0,0,0,17.732-8.14956q6.76958-3.55353,13.18372-7.68744a3.96481,3.96481,0,0,0,.18942-.60411,27.1808,27.1808,0,0,0,1.14894-7.84129v-.22528a27.25387,27.25387,0,0,0-6.90577-17.94517Z"/>
      <path class="cls-3" d="M413.39921,304.03917a25.1438,25.1438,0,0,0-11.73981,2.90589,34.44744,34.44744,0,0,0-48.45665,47.56219A34.43472,34.43472,0,0,0,320.202,380.7833a33.42507,33.42507,0,0,0-.97109,7.50989v.01185c-.01214.21313-.01214.42641-.01214.63968a34.26,34.26,0,0,0,5.354,18.431,20.25352,20.25352,0,0,0,1.67034,22.77805,176.75431,176.75431,0,0,0,17.732-8.14956q6.76958-3.55353,13.18372-7.68744c.20127-.11857.39069-.2487.58011-.37913q1.86568-1.19028,3.6958-2.452a178.82518,178.82518,0,0,0,21.20257-16.9975q2.78963-2.6118,5.48442-5.33029v-.21313a34.22813,34.22813,0,0,0-6.15259-19.6038,34.41072,34.41072,0,0,0,28.01218-15.01643,25.3717,25.3717,0,0,0,3.4179.25766c.4144,0,.841-.01185,1.25536-.03557a177.367,177.367,0,0,0,9.54719-18.66784v-.01186a177.2268,177.2268,0,0,0,8.29156-23.0979A25.16652,25.16652,0,0,0,413.39921,304.03917Z"/>
      <path class="cls-4" d="M412.21479,358.57389a179.12029,179.12029,0,0,1-43.02139,47.29737c-21.724-31.14068-50.00987-59.3083-87.26286-94.26312l15.671-16.70152L313.486,277.98C350.39513,312.62687,380.09081,338.91109,412.21479,358.57389Z"/>
      <path class="cls-5" d="M401.42371,322.707A26.04593,26.04593,0,0,0,388.323,326.225h-.01186a26.26013,26.26013,0,0,0-11.075,33.04779,20.72135,20.72135,0,0,0-5.36585,6.7042c-.3317-.01185-.66339-.02371-.99509-.02371a25.76142,25.76142,0,0,0-25.77489,25.41963c-.01186.11842-.01186.24884-.01186.36712a25.41136,25.41136,0,0,0,.68711,5.88711,25.72309,25.72309,0,0,0,11.57266,16.08556c.13042.07114.2487.15413.39069.225q1.86568-1.19028,3.6958-2.452a178.82518,178.82518,0,0,0,21.20257-16.9975q2.78963-2.6118,5.48442-5.33029c2.27416-2.32159,4.50118-4.71431,6.64521-7.16632,1.94246-2.19131,3.81379-4.43,5.638-6.72793q4.08663-5.11707,7.78229-10.54214,3.41168-4.97479,6.46737-10.17488a177.367,177.367,0,0,0,9.54719-18.66784v-.01186A26.26847,26.26847,0,0,0,401.42371,322.707Z"/>
      <g>
        <g>
          <g>
            <path class="cls-6" d="M188.98609,264.39781c-3.238,16.56631-3.23455,42.08119,3.24711,63.92487,4.38429,14.77542,13.08012,22.9437,26.354,26.16489l34.18617-65.432Z"/>
            <path class="cls-7" d="M195.33854,270.71742c-3.238,16.56631-2.48154,41.87174,3.24711,63.92487a27.58,27.58,0,0,0,20.00159,19.84528l64.13025,16.00957a9.64323,9.64323,0,0,0,9.17212-2.555l0,0a9.64325,9.64325,0,0,0-.60946-14.16431L252.62908,321.091l6.49678-25.71578Z"/>
          </g>
          <g>
            <path class="cls-8" d="M268.2549,184.71712c16.5493-3.32383,42.06386-3.4526,63.94084,2.91576,14.798,4.30767,23.01118,12.961,26.30112,26.21809L293.243,248.37579Z"/>
            <path class="cls-9" d="M274.60735,191.03673c16.5493-3.32383,41.85831-2.69851,63.94084,2.91576A27.58,27.58,0,0,1,358.49686,213.851l16.34171,64.04642a9.64323,9.64323,0,0,1-2.50742,9.18524l0,0a9.64324,9.64324,0,0,1-14.16728-.53605l-32.88669-38.48116-25.68177,6.63Z"/>
          </g>
        </g>
        <path class="cls-10" d="M313.40458,309.14128c-10.57757,10.63686-21.3327,13.40856-30.90361,10.51836a236.39776,236.39776,0,0,1-32.08841-14.17852,229.96781,229.96781,0,0,0,21.88967,8.91931c10.76728,3.25739,22.84919.13035,34.74169-11.82136,11.8925-11.9635,14.94834-24.05734,11.6438-34.81269-1.80047-5.14072-3.93264-10.49472-6.3728-15.99085a220.72684,220.72684,0,0,1,11.44253,26.41457C326.70657,287.749,323.98244,298.50436,313.40458,309.14128Z"/>
        <path class="cls-10" d="M307.04392,302.57907c-11.8925,11.95171-23.97441,15.07875-34.74169,11.82136a229.96781,229.96781,0,0,1-21.88967-8.91931c-36.90915-19.343-77.49038-49.64267-102.12819-76.1638-49.01478-52.78165-61.22712-122.869-49.98615-134.16918s81.39931.5449,134.41787,49.28734c28.01377,25.75118,60.2916,69.09233,79.59883,107.34005,2.44016,5.49613,4.57233,10.85013,6.3728,15.99085C321.99226,278.52173,318.93642,290.61557,307.04392,302.57907Z"/>
        <path class="cls-11" d="M307.04392,302.57907c-11.8925,11.95171-23.97441,15.07875-34.74169,11.82136-48.17382-16.57125-114.7905-62.696-150.90612-101.58329a244.24893,244.24893,0,0,1-49.382-80.68864c10.13916,9.12065,31.88684,2.73621,49.08591-14.54578,17.18722-17.282,23.44145-39.02954,14.30865-49.1452a244.35844,244.35844,0,0,1,80.92563,48.95571c39.06532,35.9142,85.53352,102.28218,102.35346,150.37315C321.99226,278.52173,318.93642,290.61557,307.04392,302.57907Z"/>
        <path class="cls-12" d="M121.1,117.58272c-17.19907,17.282-38.94675,23.66643-49.08591,14.54578q-1.20809-3.25151-2.27445-6.43194c-.21313-.62775-.41441-1.24372-.616-1.87147-.15385-.462-.30769-.924-.45-1.38592-.20128-.63961-.40284-1.26736-.59226-1.907-.10671-.34348-.21313-.67518-.308-1.01866-.23685-.74625-.45027-1.48063-.6634-2.215-1.48063-5.10523-2.68885-10.00911-3.63651-14.65235-.13014-.62783-.2487-1.24372-.367-1.85969a130.96084,130.96084,0,0,1-1.95461-13.84689c-.03528-.48562-.07114-.97131-.10642-1.44514-.01186-.26056-.03557-.52111-.04743-.76989-.03557-.43826-.05928-.88838-.07114-1.31478a53.75,53.75,0,0,1,1.0188-14.759,20.00856,20.00856,0,0,1,.97138-3.04419,10.79259,10.79259,0,0,1,2.26231-3.63645h.01185c3.22183-3.23368,10.74328-4.78538,21.15514-4.38269.40284.01186.80568.03557,1.22008.05928.50926.02364,1.0188.05921,1.52806.09471.23684.01186.474.02372.72268.04743.39069.02364.79352.05921,1.19607.09471,1.46907.11849,2.985.28434,4.54861.48569.56854.07106,1.13709.14213,1.70591.225,4.3352.59226,8.99023,1.45693,13.89425,2.59408q.8346.19542,1.67.39091,2.78964.67506,5.6857,1.46877c.48554.13028.98294.27242,1.4922.41455,2.32188.65147,4.70275,1.36221,7.11919,2.144.616.20134,1.23193.39091,1.85947.60411.616.20134,1.24379.41455,1.85975.62775,1.31494.43833,2.64143.90024,3.96793,1.38593.18971.05921.37912.11842.56883.18948l.02342.02372C144.54143,78.55318,138.2872,100.30072,121.1,117.58272Z"/>
        <path class="cls-13" d="M351.53606,346.83764l0,0c-2.51154,2.52459-6.2392,2.88825-8.326.81226l-91.79144-86.361a4.46941,4.46941,0,0,1-.10593-6.40733l7.74912-7.78939a4.46941,4.46941,0,0,1,6.40779.07273l86.83555,91.34264C354.39193,340.58355,354.0476,344.31306,351.53606,346.83764Z"/>
        <g>
          <ellipse class="cls-14" cx="184.83692" cy="151.59532" rx="27.4966" ry="36.3268" transform="translate(-52.99489 175.71705) rotate(-45.14847)"/>
          <ellipse class="cls-15" cx="184.83692" cy="151.59532" rx="22.4796" ry="29.69865" transform="translate(-52.99489 175.71705) rotate(-45.14847)"/>
        </g>
      </g>
    </g>
    <path class="cls-16" d="M146.47541,347.22482l-.24155-.94972a12.97767,12.97767,0,0,0-8.66977-9.17651l-.93448-.29505.94972-.24155a12.97767,12.97767,0,0,0,9.17651-8.66977l.29505-.93448.24156.94972a12.97764,12.97764,0,0,0,8.66976,9.17651l.93448.29505-.94972.24155a12.97765,12.97765,0,0,0-9.1765,8.66977Z"/>
    <path class="cls-16" d="M172.06077,380.918l-.14927-.58687a8.0194,8.0194,0,0,0-5.35739-5.67053l-.57746-.18233.58687-.14926a8.01945,8.01945,0,0,0,5.67054-5.3574l.18232-.57745.14927.58687a8.0194,8.0194,0,0,0,5.35739,5.67053l.57746.18233-.58687.14926a8.01941,8.01941,0,0,0-5.67053,5.3574Z"/>
    <path class="cls-16" d="M392.27751,238.99612l-.14926-.58687a8.01943,8.01943,0,0,0-5.3574-5.67053l-.57745-.18233.58687-.14927a8.0194,8.0194,0,0,0,5.67053-5.35739l.18233-.57746.14926.58687a8.01941,8.01941,0,0,0,5.3574,5.67054l.57745.18232-.58687.14927a8.01939,8.01939,0,0,0-5.67053,5.3574Z"/>
  </g>
</svg>


</div>

</template>

<script>
export default {
   name: 'smallrocketsvg',
   created(){

   }
}
</script>

<style scoped>
   
      #smallrocketsvg .cls-1 {
        fill: url(#linear-gradient-smallrocket);
      }

      #smallrocketsvg .cls-2 {
        fill: #81b4ea;
      }

      #smallrocketsvg .cls-3 {
        fill: url(#linear-gradient-smallrocket-2);
      }

      #smallrocketsvg .cls-4 {
        fill: url(#linear-gradient-smallrocket-3);
      }

      #smallrocketsvg .cls-5 {
        fill: url(#linear-gradient-smallrocket-4);
      }

      #smallrocketsvg .cls-6 {
        fill: url(#linear-gradient-smallrocket-5);
      }

      #smallrocketsvg .cls-7 {
        fill: url(#linear-gradient-smallrocket-6);
      }

      #smallrocketsvg .cls-8 {
        fill: url(#linear-gradient-smallrocket-7);
      }

      #smallrocketsvg .cls-9 {
        fill: url(#linear-gradient-smallrocket-8);
      }

      #smallrocketsvg .cls-10 {
        fill: #74a3ed;
      }

      #smallrocketsvg .cls-11 {
        fill: url(#linear-gradient-smallrocket-9);
      }

      #smallrocketsvg .cls-12 {
        fill: url(#linear-gradient-smallrocket-10);
      }

      #smallrocketsvg .cls-13 {
        fill: url(#linear-gradient-smallrocket-11);
      }

      #smallrocketsvg .cls-14 {
        fill: url(#linear-gradient-smallrocket-12);
      }

      #smallrocketsvg .cls-15 {
        fill: url(#linear-gradient-smallrocket-13);
      }

      #smallrocketsvg .cls-16 {
        fill: #68a1fc;
        opacity: 0.5;
      }
    
</style>