<template>
<div id="landingsvg">

<svg  data-aos="fade-up" data-aos-duration="2000" data-name="landing" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1000 1000">
  <defs>
    <linearGradient id="linear-gradient-landing" x1="88.86898" y1="732.56509" x2="896.21014" y2="732.56509" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#c3e0fc"/>
      <stop offset="0.99956" stop-color="#c3e4fc"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-2" x1="903.67058" y1="826.15814" x2="903.67058" y2="402.48023" gradientUnits="userSpaceOnUse">
      <stop offset="0.00503" stop-color="#67a6e6"/>
      <stop offset="0.99248" stop-color="#4670ce"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-3" x1="338.68122" y1="81.6923" x2="903.5751" y2="349.00815" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#2953a6"/>
      <stop offset="1" stop-color="#3157a6"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-4" x1="88.86898" y1="297.08451" x2="289.69271" y2="297.08451" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#4e85f2"/>
      <stop offset="1" stop-color="#3cb5f2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-5" x1="106.12719" y1="300.24017" x2="130.70281" y2="300.24017" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#a6d3ff"/>
      <stop offset="0.98791" stop-color="#bddeff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-6" x1="88.86898" y1="413.253" x2="289.69271" y2="413.253" xlink:href="#linear-gradient-landing-4"/>
    <linearGradient id="linear-gradient-landing-7" x1="116.37378" y1="315.20205" x2="277.59675" y2="315.20205" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#8fc8ff"/>
      <stop offset="1" stop-color="#a8e2ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-8" x1="88.86898" y1="222.784" x2="289.69271" y2="222.784" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#4d88ff"/>
      <stop offset="1" stop-color="#5fb2ed"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-9" x1="341.48323" y1="439.54307" x2="705.50388" y2="439.54307" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#70b5f9"/>
      <stop offset="0.99457" stop-color="#5c89e6"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-10" x1="775.28278" y1="352.67837" x2="616.40638" y2="656.56102" gradientUnits="userSpaceOnUse">
      <stop offset="0.00752" stop-color="#80a9ff"/>
      <stop offset="1" stop-color="#89caf9"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-11" x1="-5940.34265" y1="773.7766" x2="-5661.75946" y2="773.7766" gradientTransform="matrix(-1, 0, 0, 1, -5207.26785, 0)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#a6daff"/>
      <stop offset="1" stop-color="#bde6ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-12" x1="-207.98434" y1="784.6323" x2="325.8856" y2="784.6323" gradientTransform="translate(342.24276)" xlink:href="#linear-gradient-landing-11"/>
    <linearGradient id="linear-gradient-landing-13" x1="342.68207" y1="68.63882" x2="890.40135" y2="372.07912" gradientUnits="userSpaceOnUse">
      <stop offset="0.00967" stop-color="#3d6ccc"/>
      <stop offset="1" stop-color="#3966bf"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-14" x1="826.965" y1="296.37202" x2="855.58182" y2="296.37202" xlink:href="#linear-gradient-landing-5"/>
    <linearGradient id="linear-gradient-landing-15" x1="825.41084" y1="298.00066" x2="850.74187" y2="298.00066" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#bfe4ff"/>
      <stop offset="1" stop-color="#d1e8ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-16" x1="860.26457" y1="315.43316" x2="888.88139" y2="315.43316" xlink:href="#linear-gradient-landing-5"/>
    <linearGradient id="linear-gradient-landing-17" x1="858.71041" y1="317.0618" x2="884.04144" y2="317.0618" xlink:href="#linear-gradient-landing-15"/>
    <linearGradient id="linear-gradient-landing-18" x1="310.6986" y1="241.76309" x2="764.81618" y2="241.76309" xlink:href="#linear-gradient-landing-15"/>
    <linearGradient id="linear-gradient-landing-19" x1="308.91732" y1="243.62974" x2="759.26892" y2="243.62974" xlink:href="#linear-gradient-landing-5"/>
    <linearGradient id="linear-gradient-landing-20" x1="771.2848" y1="359.1882" x2="806.38052" y2="403.951" xlink:href="#linear-gradient-landing-3"/>
    <linearGradient id="linear-gradient-landing-21" x1="763.95974" y1="373.38342" x2="836.04255" y2="428.86407" xlink:href="#linear-gradient-landing-13"/>
    <linearGradient id="linear-gradient-landing-22" x1="310.51192" y1="250.31651" x2="470.54182" y2="250.31651" xlink:href="#linear-gradient-landing-15"/>
    <linearGradient id="linear-gradient-landing-23" x1="313.11244" y1="252.11154" x2="473.03119" y2="252.11154" xlink:href="#linear-gradient-landing-5"/>
    <linearGradient id="linear-gradient-landing-24" x1="310.51192" y1="372.62612" x2="470.54182" y2="372.62612" xlink:href="#linear-gradient-landing-15"/>
    <linearGradient id="linear-gradient-landing-25" x1="308.57799" y1="374.42116" x2="465.41229" y2="374.42116" xlink:href="#linear-gradient-landing-5"/>
    <linearGradient id="linear-gradient-landing-26" x1="496.466" y1="266.03432" x2="702.4169" y2="377.83624" xlink:href="#linear-gradient-landing-5"/>
    <linearGradient id="linear-gradient-landing-27" x1="495.28782" y1="268.20466" x2="701.23871" y2="380.00657" xlink:href="#linear-gradient-landing-5"/>
    <linearGradient id="linear-gradient-landing-28" x1="494.10964" y1="270.37499" x2="700.06053" y2="382.1769" xlink:href="#linear-gradient-landing-5"/>
    <linearGradient id="linear-gradient-landing-29" x1="301.70572" y1="378.99939" x2="275.74125" y2="331.95264" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#a6d1ff"/>
      <stop offset="0.7395" stop-color="#d4e9ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-30" x1="174.19555" y1="576.06548" x2="242.70575" y2="632.80706" gradientUnits="userSpaceOnUse">
      <stop offset="0.12729" stop-color="#739be0" stop-opacity="0"/>
      <stop offset="0.65022" stop-color="#5a92e0"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-31" x1="247.75637" y1="414.67961" x2="247.75637" y2="312.04736" xlink:href="#linear-gradient-landing-29"/>
    <linearGradient id="linear-gradient-landing-32" x1="236.73355" y1="313.22398" x2="261.25555" y2="313.22398" xlink:href="#linear-gradient-landing-29"/>
    <linearGradient id="linear-gradient-landing-33" x1="219.19116" y1="436.01236" x2="219.19116" y2="341.57452" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#a6d1ff"/>
      <stop offset="1" stop-color="#d4e9ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-34" x1="181.3459" y1="660.18212" x2="339.38169" y2="660.18212" xlink:href="#linear-gradient-landing-30"/>
    <linearGradient id="linear-gradient-landing-35" x1="276.00822" y1="475.38349" x2="336.38662" y2="475.38349" xlink:href="#linear-gradient-landing-29"/>
    <linearGradient id="linear-gradient-landing-36" x1="309.00377" y1="480.32515" x2="301.43823" y2="397.94479" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#365799"/>
      <stop offset="0.58753" stop-color="#3660b3"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-37" x1="-5158.80415" y1="221.54782" x2="-5147.773" y2="221.54782" gradientTransform="matrix(-0.1201, -1.14974, -0.99276, 0.13909, -104.93552, -5588.41062)" xlink:href="#linear-gradient-landing-11"/>
    <linearGradient id="linear-gradient-landing-38" x1="477.45285" y1="697.22731" x2="601.43399" y2="697.22731" xlink:href="#linear-gradient-landing-30"/>
    <linearGradient id="linear-gradient-landing-39" x1="538.06533" y1="509.00362" x2="560.86709" y2="450.4991" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#4e69f2"/>
      <stop offset="0.99497" stop-color="#3c88f2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-40" x1="585.20729" y1="522.32254" x2="585.20729" y2="428.466" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#4e69f2"/>
      <stop offset="0.72822" stop-color="#3c88f2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-41" x1="807.31267" y1="346.23531" x2="791.73787" y2="271.97689" gradientTransform="translate(-163.92261 134.70454) rotate(3.2628)" xlink:href="#linear-gradient-landing-39"/>
    <linearGradient id="linear-gradient-landing-42" x1="578.60681" y1="433.66131" x2="578.60681" y2="409.83871" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f29191"/>
      <stop offset="1" stop-color="#f9a49d"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-43" x1="320.67" y1="763.929" x2="475.85614" y2="763.929" xlink:href="#linear-gradient-landing-30"/>
    <linearGradient id="linear-gradient-landing-44" x1="429.89278" y1="565.01824" x2="421.48662" y2="524.03821" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f9a49d"/>
      <stop offset="1" stop-color="#f29191"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-45" x1="410.59197" y1="512.51412" x2="428.61821" y2="502.38509" xlink:href="#linear-gradient-landing-29"/>
    <linearGradient id="linear-gradient-landing-46" x1="456.30792" y1="581.49803" x2="456.30792" y2="483.84939" xlink:href="#linear-gradient-landing-29"/>
    <linearGradient id="linear-gradient-landing-47" x1="460.13183" y1="473.86156" x2="456.55921" y2="449.79893" gradientTransform="translate(885.7492 944.52119) rotate(-176.37623)" xlink:href="#linear-gradient-landing-42"/>
    <linearGradient id="linear-gradient-landing-48" x1="464.00142" y1="463.11658" x2="468.43109" y2="463.11658" xlink:href="#linear-gradient-landing-42"/>
    <linearGradient id="linear-gradient-landing-49" x1="505.65953" y1="552.75959" x2="480.59867" y2="512.72525" xlink:href="#linear-gradient-landing-44"/>
    <linearGradient id="linear-gradient-landing-50" x1="496.09445" y1="538.1383" x2="476.62109" y2="506.18202" xlink:href="#linear-gradient-landing-29"/>
    <linearGradient id="linear-gradient-landing-51" x1="620.0508" y1="743.0015" x2="745.30486" y2="743.0015" xlink:href="#linear-gradient-landing-30"/>
    <linearGradient id="linear-gradient-landing-52" x1="738.23561" y1="575.20001" x2="738.23561" y2="438.39058" xlink:href="#linear-gradient-landing-36"/>
    <linearGradient id="linear-gradient-landing-53" x1="652.5584" y1="545.97547" x2="734.51845" y2="598.93427" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#73ade6"/>
      <stop offset="1" stop-color="#5596f2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-54" x1="756.45845" y1="579.1355" x2="714.84797" y2="559.38102" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#4174d9"/>
      <stop offset="1" stop-color="#5596f2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-55" x1="664.8384" y1="531.60054" x2="753.58387" y2="531.60054" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#9cceff"/>
      <stop offset="1" stop-color="#6eabed"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-56" x1="727.89036" y1="454.14549" x2="749.48556" y2="454.14549" xlink:href="#linear-gradient-landing-42"/>
    <linearGradient id="linear-gradient-landing-57" x1="784.71412" y1="568.34551" x2="802.78736" y2="540.60518" xlink:href="#linear-gradient-landing-39"/>
    <linearGradient id="linear-gradient-landing-58" x1="722.55368" y1="793.46832" x2="830.93183" y2="793.46832" xlink:href="#linear-gradient-landing-30"/>
    <linearGradient id="linear-gradient-landing-59" x1="808.24016" y1="613.66544" x2="840.21494" y2="527.67501" xlink:href="#linear-gradient-landing-40"/>
    <linearGradient id="linear-gradient-landing-60" x1="857.65186" y1="580.34749" x2="848.40508" y2="550.29547" xlink:href="#linear-gradient-landing-39"/>
    <linearGradient id="linear-gradient-landing-61" x1="828.33377" y1="520.37188" x2="823.71039" y2="504.40018" xlink:href="#linear-gradient-landing-42"/>
    <linearGradient id="linear-gradient-landing-62" x1="828.86647" y1="506.19031" x2="834.05647" y2="506.19031" xlink:href="#linear-gradient-landing-42"/>
    <linearGradient id="linear-gradient-landing-63" x1="86.10692" y1="238.07104" x2="140.9571" y2="206.54795" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#4e85f2"/>
      <stop offset="0.99258" stop-color="#3ca6f2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-landing-64" x1="499.11841" y1="835.29182" x2="544.01912" y2="835.29182" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlink:href="#linear-gradient-landing-11"/>
    <linearGradient id="linear-gradient-landing-65" x1="687.33257" y1="915.624" x2="696.88214" y2="915.624" gradientTransform="translate(1139.00732 -141.57055) rotate(60)" xlink:href="#linear-gradient-landing-11"/>
    <linearGradient id="linear-gradient-landing-66" x1="702.74755" y1="906.02599" x2="712.29712" y2="906.02599" gradientTransform="translate(1138.40269 -159.71932) rotate(60)" xlink:href="#linear-gradient-landing-11"/>
    <linearGradient id="linear-gradient-landing-67" x1="717.87168" y1="897.15747" x2="727.42125" y2="897.15747" gradientTransform="translate(1138.28439 -177.25146) rotate(60)" xlink:href="#linear-gradient-landing-11"/>
  </defs>
  <g>
    <path  class="cls-1" d="M331.2448,498.57333,97.62537,633.45412a17.51283,17.51283,0,0,0,.00383,30.33529l512.525,295.73519a52.53849,52.53849,0,0,0,52.51152.00241L896.21014,824.79658Z"/>
    <polygon class="cls-2" points="896.21 405.904 896.21 824.797 911.131 816.184 911.131 397.361 896.21 405.904"/>
    <path class="cls-3" d="M894.34771,339.08907,368.447,35.45481a14.7139,14.7139,0,0,0-14.87671.01486l-15.79629,9.136,558.4361,361.298,14.92088-8.54293V368.15887A33.56708,33.56708,0,0,0,894.34771,339.08907Z"/>
    <path data-aos="fade-right" data-aos-duration="2000" data-aos-delay="300" class="cls-4" d="M289.69271,173.56288V307.12852a14.82,14.82,0,0,1-7.4112,12.83516L98.39557,426.10692a6.35141,6.35141,0,0,1-9.52659-5.50079V287.0405a14.82,14.82,0,0,1,7.4112-12.83517L280.16612,168.0621A6.3514,6.3514,0,0,1,289.69271,173.56288Z"/>
    <path data-aos="fade-right" data-aos-duration="2000" data-aos-delay="300" class="cls-5" d="M130.70281,290.96744v4.2913a5.086,5.086,0,0,1-2.54342,4.40484l-19.54461,11.28568a1.65848,1.65848,0,0,1-2.48759-1.43636v-4.2913a5.086,5.086,0,0,1,2.54342-4.40485l19.54461-11.28568A1.65849,1.65849,0,0,1,130.70281,290.96744Z"/>
    <path data-aos="fade-right" data-aos-duration="2000" data-aos-delay="300" class="cls-6" d="M289.69271,346.10982v20.80874a14.82,14.82,0,0,1-7.4112,12.83517L98.39557,485.897a6.3514,6.3514,0,0,1-9.52659-5.50078V459.58744a14.82,14.82,0,0,1,7.4112-12.83517L280.16612,340.609A6.35141,6.35141,0,0,1,289.69271,346.10982Z"/>
    <path data-aos="fade-right" data-aos-duration="2000" data-aos-delay="300" class="cls-7" d="M111.80234,400.37113l157.80823-91.09058V230.03531s-22.74753-.63584-32.43938,19.06912c-15.79578,32.11512-27.29684,32.71316-38.80281,34.612-21.0154,3.46823-31.5231,14.71078-36.882,26.32178-6.03036,13.0658-11.23513,33.88432-17.91157,40.03283s-17.1054,3.87056-24.43892,14.62408S111.80234,400.37113,111.80234,400.37113Z"/>
    <path class="cls-8" d="M269.61057,230.03531s-22.74753-.63584-32.43938,19.06912c-15.79578,32.11512-27.29684,32.71316-38.80281,34.612-21.0154,3.46823-31.5231,14.71078-36.882,26.32178-6.03036,13.0658-11.23513,33.88432-17.91157,40.03283s-17.1054,3.87056-24.43892,14.62408-7.33352,35.676-7.33352,35.676"/>
    <path class="cls-9" d="M289.69271,155.65866V180.3501L88.869,296.27023v-27.134a14.82,14.82,0,0,1,7.4112-12.83517L280.16612,150.15787A6.35141,6.35141,0,0,1,289.69271,155.65866Z"/>
    <path class="cls-10" d="M331.2448,498.57333V69.16588a14.85414,14.85414,0,0,1,22.28131-12.864L879.42675,359.93608a33.56723,33.56723,0,0,1,16.78339,29.07V824.79658Z"/>
    <polygon class="cls-11" points="331.245 498.573 694.015 289.152 896.21 405.904 896.21 824.797 331.245 498.573"/>
    <line class="cls-12" x1="732.19917" y1="693.90836" x2="455.36726" y2="853.64485"/>
    <line class="cls-13" x1="135.13406" y1="631.11176" x2="667.25272" y2="938.15284"/>
    <path class="cls-14" d="M331.2448,79.68042V56.90689a14.85414,14.85414,0,0,1,22.28131-12.864L879.42675,347.6771a33.56723,33.56723,0,0,1,16.78339,29.07v29.15662Z"/>
    <g data-aos="fade-down" data-aos-duration="2000" data-aos-delay="1000">
      <path class="cls-15" d="M853.2606,286.73838l-18.50971-10.6906a2.96683,2.96683,0,0,0-2.96576-.0146l-4.82013,2.77831L849.341,317.10086l4.78049-2.76212a2.96571,2.96571,0,0,0,1.46038-2.56818V290.75917A4.64311,4.64311,0,0,0,853.2606,286.73838Z"/>
      <path class="cls-16" d="M825.41084,302.4554V281.43824a3,3,0,0,1,4.50011-2.59792l18.50968,10.69054a4.64309,4.64309,0,0,1,2.32124,4.02086v21.01137a2.99994,2.99994,0,0,1-4.50008,2.59793l-18.50966-10.68474A4.64308,4.64308,0,0,1,825.41084,302.4554Z"/>
    </g>
    <g data-aos="fade-down" data-aos-duration="2000" data-aos-delay="500">
      <path class="cls-17" d="M886.56017,305.79951l-18.50972-10.69059a2.96679,2.96679,0,0,0-2.96575-.01461l-4.82013,2.77832L882.64052,336.162l4.78049-2.76212a2.96571,2.96571,0,0,0,1.46038-2.56817V309.82031A4.64311,4.64311,0,0,0,886.56017,305.79951Z"/>
      <path class="cls-18" d="M858.71041,321.51653V300.49937a3,3,0,0,1,4.50011-2.59792L881.7202,308.592a4.64306,4.64306,0,0,1,2.32124,4.02085v21.01137a3,3,0,0,1-4.50008,2.59794L861.0317,325.53741A4.64306,4.64306,0,0,1,858.71041,321.51653Z"/>
    </g>
    <g data-aos="fade-right" data-aos-duration="2000" data-aos-delay="500">
      <path class="cls-19" d="M762.15573,352.43323,319.62231,96.757a3.40038,3.40038,0,0,0-3.39917-.01674l-5.52454,3.18434L757.66327,387.23285l5.4791-3.16577a3.3991,3.3991,0,0,0,1.67381-2.94348v-24.082A5.32168,5.32168,0,0,0,762.15573,352.43323Z"/>
      <path class="cls-20" d="M308.91732,127.02384V102.93526a3.43835,3.43835,0,0,1,5.15775-2.97758L756.60845,355.63381a5.32163,5.32163,0,0,1,2.66047,4.60846v24.082a3.43835,3.43835,0,0,1-5.15772,2.97759L311.57784,131.63233A5.3216,5.3216,0,0,1,308.91732,127.02384Z"/>
    </g>
    <g data-aos="fade-right" data-aos-duration="2000" data-aos-delay="800">
      <path class="cls-21" d="M797.938,373.2294l-21.2147-12.25291a3.40036,3.40036,0,0,0-3.39916-.01674l-5.52454,3.18434L793.44553,408.029l5.47911-3.16577a3.39914,3.39914,0,0,0,1.6738-2.94349v-24.082A5.32166,5.32166,0,0,0,797.938,373.2294Z"/>
      <path class="cls-22" d="M766.0183,391.24329V367.15471a3.43836,3.43836,0,0,1,5.15775-2.97758L792.39072,376.43a5.32162,5.32162,0,0,1,2.66047,4.60846v24.08194a3.43836,3.43836,0,0,1-5.15772,2.9776l-21.21465-12.2462A5.32163,5.32163,0,0,1,766.0183,391.24329Z"/>
    </g>
    <g data-aos="fade-right" data-aos-duration="2000" data-aos-delay="1100">
      <path class="cls-23" d="M465.44677,240.159,320.12557,156.3287a4.02885,4.02885,0,0,0-3.95378-.06158l.00249-.01178-5.66236,3.26919L462.89966,344.854l5.4283-3.13952a4.03641,4.03641,0,0,0,2.21386-3.61787v-89.1118A10.1917,10.1917,0,0,0,465.44677,240.159Z"/>
      <path class="cls-24" d="M460.01466,243.29275,314.69337,159.4624a4.07669,4.07669,0,0,0-6.11538,3.53033v89.11181a10.1917,10.1917,0,0,0,5.095,8.8258l145.32124,83.83035a4.07669,4.07669,0,0,0,6.11538-3.53033V252.11855A10.19176,10.19176,0,0,0,460.01466,243.29275Z"/>
    </g>
    <g data-aos="fade-right" data-aos-duration="2000" data-aos-delay="1400">
      <path class="cls-25" d="M465.44677,362.46862l-145.3212-83.83031a4.02887,4.02887,0,0,0-3.95378-.06158l.00249-.01178-5.66236,3.26919L462.89966,467.16365l5.4283-3.13951a4.03642,4.03642,0,0,0,2.21386-3.61788v-89.1118A10.1917,10.1917,0,0,0,465.44677,362.46862Z"/>
      <path class="cls-26" d="M460.01466,365.60237,314.69337,281.772a4.07668,4.07668,0,0,0-6.11538,3.53032v89.11181a10.1917,10.1917,0,0,0,5.095,8.8258L458.99424,467.0703a4.07669,4.07669,0,0,0,6.11538-3.53033V374.42816A10.19173,10.19173,0,0,0,460.01466,365.60237Z"/>
      <path class="cls-27" d="M450.39211,447.18031,410.10524,423.861a3.98147,3.98147,0,0,1-1.9869-3.44583v-68.8858a1.99073,1.99073,0,0,1,2.988-1.72292l40.28687,23.31935a3.98145,3.98145,0,0,1,1.9869,3.44583v68.8858A1.99073,1.99073,0,0,1,450.39211,447.18031Z"/>
    </g>
    <g  data-aos="fade-right" data-aos-duration="2000" data-aos-delay="1400">
      <path class="cls-28" d="M539.95379,296.77141l-46.58133-26.96278a4.60354,4.60354,0,0,1-2.29734-3.98421V259.846a2.30176,2.30176,0,0,1,3.45486-1.99211l46.58133,26.96278a4.60354,4.60354,0,0,1,2.29734,3.98421v5.9784A2.30176,2.30176,0,0,1,539.95379,296.77141Z"/>
      <path class="cls-29" d="M619.52739,342.77842l-46.58133-26.96277a4.60353,4.60353,0,0,1-2.29733-3.98422V305.853a2.30176,2.30176,0,0,1,3.45486-1.9921l46.58133,26.96278a4.6035,4.6035,0,0,1,2.29733,3.98421v5.9784A2.30176,2.30176,0,0,1,619.52739,342.77842Z"/>
      <path class="cls-30" d="M699.101,388.78544l-46.58133-26.96278a4.60351,4.60351,0,0,1-2.29734-3.98421v-5.9784a2.30177,2.30177,0,0,1,3.45486-1.99211l46.58133,26.96278a4.60354,4.60354,0,0,1,2.29734,3.98421v5.97841A2.30176,2.30176,0,0,1,699.101,388.78544Z"/>
    </g>
    <g  data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">
      <g>
        <g>
          <path class="cls-31" d="M256.89482,308.344c2.93029-.2885,12.73216.81606,18.76557,7.543,4.51583,5.03489,19.62675,46.87653,19.62675,46.87653l12.17432-4.06774c7.58918-2.59392,12.24978,13.55853,7.14565,15.88868L294.21117,384.744a10.47939,10.47939,0,0,1-13.85592-4.33238l-17.35591-31.57629Z"/>
          <path class="cls-32" d="M277.74088,344.15388l8.15285,41.03435a12.43356,12.43356,0,0,1-5.9614-5.05578L275.99327,356.72Z"/>
          <polygon class="cls-33" points="259.53 610.677 131.656 613.807 213.903 566.321 250.539 566.321 239.654 577.827 261.73 587.651 259.53 610.677"/>
          <path class="cls-34" d="M233.31977,586.66759s-2.36736,8.25728,1.71269,11.40092a39.00105,39.00105,0,0,0,6.98764,3.8509c4.10348,1.944,7.02548,6.91423,13.88562,8.40556,9.63257,2.094,13.06551-5.36642,10.286-8.6753-3.16625-3.76926-11.38218-11.38739-16.6771-19.3307Z"/>
          <g>
            <path class="cls-34" d="M238.441,559.9522s-4.598,6.02167-3.49408,11.81427c.95935,5.034,7.07586,6.46959,10.66068,8.33049s7.53873,9.30912,15.39969,7.89162c9.33389-1.68309,9.10564-7.25758,4.78273-10.92776l-14.16666-11.74566Z"/>
            <path class="cls-35" d="M281.569,421.25671c0,10.67707-.93227,52.98986-3.80038,72.38708-1.58732,10.73517-23.63433,70.95847-23.63433,70.95847-2.13336,6.61344-13.31511,5.437-14.48069-1.54076l9.48716-71.77787-6.64919-58.621Z"/>
            <polygon class="cls-36" points="223.826 432.663 250.844 451.13 281.569 421.257 281.656 393.604 262.723 385.664 221.919 402.86 223.826 432.663"/>
            <path class="cls-32" d="M229.84364,419.30107c24.21481,8.73,48.70772-1.57138,51.77594-14.09313l.07819-11.146-54.465,13.28Z"/>
            <g>
              <path class="cls-37" d="M221.91949,402.72016s2.69777,8.98987,18.62689,13.14869c14.29473,3.7321,43.1675-4.843,41.56679-24.5728-.57457-7.51224-1.49262-10.2355-3.124-23.67429-1.93852-15.96868.111-34.31415-1.16506-40.69431-3.20924-16.0462-18.97819-18.5671-18.97819-18.5671-14.0322-1.01722-41.66106,17.95682-45.51024,28.04477l8.58382,44.726Z"/>
              <path class="cls-38" d="M232.639,359.04927c22.90341-4.13653,42.78266-14.89539,45.61293-21.37472l-.00113,20.30936c-6.89872,10.54793-26.21664,18.32835-47.98024,18.32835Z"/>
              <path class="cls-32" d="M261.25555,320.62126c0,3.79943-5.48943,6.87948-12.261,6.87948s-12.261-3.08005-12.261-6.87948V313.224h24.522Z"/>
              <ellipse class="cls-39" cx="248.99455" cy="313.22398" rx="12.261" ry="6.87948"/>
              <ellipse class="cls-40" cx="248.99455" cy="312.69896" rx="9.78539" ry="5.49044"/>
              <path class="cls-41" d="M255.61544,312.22714a9.25579,9.25579,0,0,0-1.0171,4.95628,15.37874,15.37874,0,0,1-5.6035.99859c-4.309,0-7.97057-1.57188-9.26512-3.73566l.44393-4.08693.22191-2.10826,1.84932.48085Z"/>
              <polygon class="cls-32" points="232.817 357.753 228.476 389.395 234.568 419.888 226.045 416.455 218.681 388.676 232.817 357.753"/>
            </g>
          </g>
          <path class="cls-36" d="M223.82565,432.66267c.06314,4.32841,9.14756,153.9142,9.14756,153.9142,0,8.55887,18.64324,5.58232,18.74751.18161l4.29988-154.09581Z"/>
          <path class="cls-42" d="M231.62842,434.19735l3.20167,4.10048,3.07349,7.42107a1.08543,1.08543,0,0,1-1.97415.79046l-2.01958-2.06987-.94991.16029,1.06885,5.7202,1.92492,3.393c.47286.78808-.74928,2.2969-1.68861,1.66307l-7.967-4.85632a4.06475,4.06475,0,0,1-1.88653-2.76019l-1.90071-10.705Z"/>
          <path class="cls-42" d="M235.93339,446.51577l-2.03145-2.084-.94565.17508,1.06826,5.7092,1.92637,3.3975c.57354.97632-.29769,1.66374-1.05073,1.80376a.844.844,0,0,1-.63044-.14l-.29779-.1752-2.83707-1.71622-.01753-.01753-1.22582-.753-2.29417-1.401-.73562-.43782-.56042-.35028a4.09206,4.09206,0,0,1-1.89131-2.76706L222.5186,437.0588l9.1067-2.8546,3.20477,4.09805,3.06474,7.42542C238.17508,446.88347,236.704,447.42636,235.93339,446.51577Z"/>
          <path class="cls-43" d="M234.90019,455.51728a.844.844,0,0,1-.63044-.14l-.29779-.1752-2.39925-3.08227a2.9806,2.9806,0,0,1-.5429-1.13827l-1.06825-4.39574a.261.261,0,0,1,.19261-.31522.25305.25305,0,0,1,.31522.19262l1.06836,4.39574a2.37292,2.37292,0,0,0,.45525.94565Z"/>
          <path class="cls-43" d="M231.11736,453.46831l-1.22582-.753-.59548-.7881a3.10016,3.10016,0,0,1-.47288-.92812l-1.45349-4.711a.25775.25775,0,1,1,.49031-.15766l1.47112,4.711a2.27629,2.27629,0,0,0,.38523.77057Z"/>
          <path class="cls-43" d="M227.59737,451.31427l-.73562-.43782-1.68116-4.72849a.29773.29773,0,0,1,.17508-.35028.27532.27532,0,0,1,.33275.15767l1.89142,5.32386C227.57984,451.29674,227.59737,451.29674,227.59737,451.31427Z"/>
          <path class="cls-32" d="M219.57047,434.77989l3.48274,4.98627c1.20669,1.7551,9.81554-1.72962,9.18938-4.81134l-1.04913-6.20834Z"/>
          <path class="cls-44" d="M213.01341,337.261s-12.26738,37.30681-9.93723,56.78311c1.93609,16.18267,13.58972,39.20717,13.58972,39.20717,2.3052,5.43715,16.78537.652,15.03778-6.08876L224.5468,391.5446l10.31437-40.4366C240.00188,337.03235,216.78445,325.37186,213.01341,337.261Z"/>
        </g>
        <path class="cls-45" d="M261.68392,282.97124c2.00779,6.01581-3.23214,10.1051-2.82636,14.4259l-.57816-9.29115Z"/>
        <path class="cls-46" d="M250.23711,314.05164c6.39052.71682,10.3174-5.41205,9.95266-11.27348l-.81733-13.13455A10.63359,10.63359,0,0,0,248.099,279.691l0,0a10.63358,10.63358,0,0,0-9.95264,11.27347l.81733,13.13455C239.32837,309.96043,243.84659,313.33483,250.23711,314.05164Z"/>
        <path class="cls-45" d="M262.99608,281.91634c-.06721,2.33076.1007,4.22066-3.03322,5.74267-1.14085,3.41134-11.69462,1.57268-15.44661,5.748-.7422,6.59476-5.15847,4.79738-5.15847,4.79738.3472,5.68267,2.06112,9.39952.48126,15.16384,0,0-2.64279-7.45972-7.03971-9.84177-3.0185-1.63529-2.61827-6.82048-4.063-9.51441a8.67744,8.67744,0,0,1-.42022-7.8197c1.28277-3.26544,3.52615-3.84706,4.939-8.18367,1.36119-4.178,6.22276-2.81327,10.72123-6.01635,4.69763-3.3449,7.21417,1.09446,11.25964.94132C260.16227,272.74715,263.08118,278.96555,262.99608,281.91634Z"/>
        <path class="cls-46" d="M239.29059,296.8311s-2.39994-2.11375-3.87073-.05046c-1.00156,1.405-.79712,5.09767,1.58047,7.13887a3.60362,3.60362,0,0,0,3.42026.42839Z"/>
        <path class="cls-45" d="M239.37649,297.399l.55166,3.66964c.3818,1.07312,2.06676,1.23252,2.66205-.62952a7.27964,7.27964,0,0,0,.04175-3.77732Z"/>
      </g>
      <path class="cls-32" d="M313.42983,372.76136l5.308-3.623c2.30492-1.76627-1.22424-10.59328-3.94389-10.01578l-6.15639,1.36355C304.01049,361.71319,308.61531,375.25022,313.42983,372.76136Z"/>
      <g>
        <path class="cls-42" d="M313.64335,360.05579l6.74307-5.79554a5.188,5.188,0,0,1,2.78436-1.219l10.87568-1.26044c1.91312-.23189,1.62326,2.52184-.04463,2.927l-5.73713,1.455-.76145,6.85306a2.91876,2.91876,0,0,1-2.11117,2.49321c-2.7811.78192-7.6782,2.168-7.99505,2.32159C314.25538,369.35331,310.56447,361.50592,313.64335,360.05579Z"/>
        <path class="cls-43" d="M327.12552,358.22125l-5.31364,1.09171-.03738-.9674,3.79883-1.03015a2.11052,2.11052,0,0,0,1.5654-2.16928,1.39068,1.39068,0,0,0-1.56068-1.13324h-.00283l-5.15257.21666-.74812.64255,5.91485-.27985h.00094c.569,0,.91527.22646.97471.63691a1.53768,1.53768,0,0,1-1.14927,1.52954l-4.37914,1.18608-.01887.2a6.96088,6.96088,0,0,1-2.17966,4.348l.38686.43215a4.19978,4.19978,0,0,0,1.0352-1.00652l1.10106.75176a1.66909,1.66909,0,0,0,1.51727,1.5937l3.05058.26892.02548.00094a.28976.28976,0,0,0,.02548-.57841l-3.05059-.26892a1.08874,1.08874,0,0,1-.98887-1.03888l-.025-.64717,4.97123-.387a.28962.28962,0,0,0-.04529-.57747l-4.94823.3851-.05874-1.52069,5.408-1.11106a.29.29,0,1,0-.117-.568Z"/>
      </g>
    </g>
    <g data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">
      <path class="cls-47" d="M364.12732,421.40561l2.4945-3.4549,9.67005-6.976,4.07411-2.77613c1.53862-.99459,3.29936-.80943,2.86595,1.04373l-1.7409,7.41846a4.35557,4.35557,0,0,1-1.33881,2.30789l-8.64639,7.798Z"/>
      <path class="cls-47" d="M383.23389,409.24841l-.7881,3.32748-.24521,1.05073-.42029,1.75128-.2452,1.06826-.05248.21015a4.3057,4.3057,0,0,1-1.331,2.3117l-8.65134,7.79323-7.37294-5.35892,2.48679-3.45,9.68465-6.97018,4.063-2.78448C381.9029,407.19944,383.67171,407.39206,383.23389,409.24841Z"/>
      <path class="cls-47" d="M366.62182,417.95071l1.79942-4.69613a2.41377,2.41377,0,0,1,.779-1.047l4.88122-3.76831c.894-.72657,2.6005-.10836,1.65055,1.51874l-2.73409,4.05578Z"/>
      <path class="cls-48" d="M383.25131,409.14334a.22315.22315,0,0,1-.01742.10507l-.22768.94566-6.09453,4.5534a.27932.27932,0,0,1-.21015.07.34367.34367,0,0,1-.19262-.63044Z"/>
      <path class="cls-48" d="M382.48288,412.41272l-.24521,1.05072-4.746,3.43256a.26331.26331,0,0,1-.19261.07.37371.37371,0,0,1-.29769-.14013.359.359,0,0,1,.08754-.49031Z"/>
      <path class="cls-48" d="M381.79014,415.3779l-.2452,1.06826-3.71271,2.78459a.35034.35034,0,0,1-.49031-.07.34261.34261,0,0,1,.07-.49042Z"/>
      <path class="cls-49" d="M360.68894,423.58185l3.82355-3.42174c3.39816-3.00545,10.6754,2.972,8.0995,6.98513l-2.99,4.9487Z"/>
      <polygon class="cls-50" points="314.381 682.024 181.346 682.035 181.346 638.329 272.641 638.329 275.43 661.301 339.382 663.885 314.381 682.024"/>
      <path class="cls-34" d="M278.46652,629.91745c1.5382-.93831,9.13319-2.91607,9.72648,5.19451.46655,6.37784-1.885,9.41806-5.06514,14.42921-1.11173,1.75181-2.79891,7.35786-4.79284,9.96138-2.43579,3.18048-9.33543,2.54-11.31254-.4257-1.66439-2.49658.16644-12.19165,1.47021-14.42471C270.39389,641.39583,278.46652,629.91745,278.46652,629.91745Z"/>
      <path class="cls-51" d="M268.85331,636.38567l-.45508,10.75789c-.012,4.334,7.02942,4.63772,9.77681.51022l1.41358-2.22492-1.01558-1.27363,1.43168-6.438Z"/>
      <path class="cls-34" d="M307.13488,666.03327s-3.1243,10.87581,1.71926,14.40324c4.85809,3.538,12.07914.2985,16.88519-1.081,4.80324-1.37866,10.1334-.36151,15.8478-3.85672,6.07859-3.718,4.28055-12.48091-.22192-12.59388-5.19255-.1303-13.981-.06-21.52167-1.05354C312.94651,662.50929,308.54586,662.3025,307.13488,666.03327Z"/>
      <path class="cls-51" d="M308.21612,656.33061l.52644,9.534c.11992,2.67172,4.33154,3.74485,8.30044,3.64369l12.06116-1.27732c-1.37277-2.91784-3.02242-4.56492-4.93676-4.99688l-4.43925-.49561.88767-6.824Z"/>
      <path class="cls-52" d="M273.30261,486.75706l-6.16917,148.519c-.44384,6.824,15.853,9.20453,17.18453,0l20.76278-124.12992Z"/>
      <path class="cls-53" d="M277.25188,439.3986l7.1265,19.74525-3.70591,13.158-16.98934-22.43929a20.50346,20.50346,0,0,1-3.53572-17.38467l9.5201-37.79473c2.94526-12.47168,14.56559-14.02724,18.60852-13.53948l.64775,24.03637Z"/>
      <polygon class="cls-49" points="277.252 439.399 266.591 453.703 276.594 466.915 278.829 460.412 280.747 449.082 277.252 439.399"/>
      <polygon class="cls-49" points="274.754 408.336 266.591 453.703 279.4 436.639 274.754 408.336"/>
      <path class="cls-54" d="M336.76042,506.40266l-23.02407,17.71957c-14.75759-9.07055-37.28233-24.272-40.43374-37.36517.59935-8.784,1.90795-15.358,5.1104-26.80888l14.367-10.8665,42.64514,25.02724C337.20206,484.38943,336.7604,494.3023,336.76042,506.40266Z"/>
      <path class="cls-55" d="M278.40623,460.008l-2.398,8.76676c.57438,19.266,33.51437,31.13685,60.3784,12.995a37.77223,37.77223,0,0,0-.96145-7.82118Z"/>
      <path class="cls-56" d="M335.42514,473.94851s-6.59658,8.16811-21.25132,9.55423c-19.48015,1.84254-35.79117-8.852-35.79117-23.28689l1.99727-17.60746-6.84945-41.72653c-2.01081-14.33573,9.70234-22.90829,18.779-18.69142,10.53017,4.20528,29.84666,13.11422,38.91759,25.93.27132,20.50846,1.52812,32.85092-.42582,45.98949C330.8012,454.11,334.68244,468.37778,335.42514,473.94851Z"/>
      <polygon class="cls-49" points="317.39 431.156 330.801 454.11 332.448 446.568 317.39 431.156"/>
      <path class="cls-54" d="M336.7604,506.40267c-.83856,31.37226-7.59667,83.66707-13.59251,147.90293-.90931,7.68424-18.08714,8.39332-18.08714-.41288l-4.09408-139.65818Z"/>
      <path class="cls-53" d="M331.227,408.12046l17.57951,25.6994,10.81082-10.30912c5.81287-4.73819,14.80057,4.58239,9.14165,11.46278l-17.76736,20.49882c-3.57844,3.78648-9.77829,3.57843-13.60638-.749l-24.0136-28.03951C302.95064,410.42544,325.48807,400.37683,331.227,408.12046Z"/>
      <rect class="cls-47" x="294.51799" y="353.20431" width="19.48501" height="31.54215" rx="9.7425" transform="translate(649.62041 699.79833) rotate(173.27772)"/>
      <path class="cls-57" d="M308.23237,392.27906l-13.56692-2.34113,2.84718-11.16538c2.499-4.465,8.57057-1.227,11.17028,5.0143A37.14107,37.14107,0,0,0,308.23237,392.27906Z"/>
      <path class="cls-58" d="M294.40531,380.31841c-.07576,3.35894,8.2836,7.85469,10.36581,1.34862,2.31738-.16068,8.21659-5.20046,8.463-8.95233,6.39784-2.18959,10.06617-14.23474-1.60376-20.86417-6.89072-4.23654-16.5965-1.09816-20.43729,3.92167-2.86286,3.74168-3.09079,7.53467-2.27381,13.4454S294.48817,376.64484,294.40531,380.31841Z"/>
      <path class="cls-47" d="M315.0341,371.48378c-.22193-1.2466-1.43145-2.2207-2.96341-.81625-1.04321.95638-1.6413,3.29453-.47419,6.02777a2.5314,2.5314,0,0,0,2.28972,1.5979S315.372,373.38152,315.0341,371.48378Z"/>
      <path class="cls-49" d="M314.35268,393.18211c-.32649-1.14079-1.28748-2.37766-3.19222-4.05991-2.34339-2.06966-14.955-9.72261-17.608-6.90321l-2.38079,3.01059C288.592,390.767,298.13763,397.5357,314.35268,393.18211Z"/>
      <ellipse class="cls-59" cx="294.01051" cy="367.35595" rx="7.02627" ry="6.35752" transform="translate(-113.43822 602.68149) rotate(-81.33719)"/>
      <path class="cls-58" d="M297.03505,373.40744c2.55941-5.55368-.96066-11.82376-5.72245-12.35672s-9.44721,1.7676-12.17928,8.10068c-2.74613,6.36563-8.51986,7.8093-10.58005,15.77095-1.58745,6.13471.04694,10.18682-3.1397,14.34331a12.38265,12.38265,0,0,0,.8322,16.03362c4.43837,4.688,13.73582,4.50092,19.1627,2.27466a21.75518,21.75518,0,0,0,12.07234-12.11535c3.2057-7.54111.58728-12.99239-.58254-18.25976C294.36,375.76949,297.03505,373.40744,297.03505,373.40744Z"/>
    </g>
    <g data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">
      <path class="cls-58" d="M562.589,417.42828c.16644-7.12913,1.08185-19.52883,13.08-22.85761,13.51809-3.75047,21.89991,8.86157,23.5643,16.61616,1.60182,7.46309.83219,10.40424,6.93495,13.648s13.21083,9.13428,14.09183,20.12c2.44111,30.43949-62.02843,3.44422-62.02843,3.44422C548.66316,430.2683,562.43326,424.09947,562.589,417.42828Z"/>
      <polygon class="cls-60" points="581.122 721.459 477.453 721.459 477.453 672.995 588.309 673.618 590.095 688.526 601.434 701.262 581.122 721.459"/>
      <path class="cls-34" d="M594.68678,676.22632s5.36513,6.34147,2.13046,9.73247c-1.44571,1.51558-5.30378,1.33488-8.92552,3.67676-4.93134,3.18868-7.34274,5.56482-14.12329,5.93908-8.30835.45858-8.41907-6.00079-5.43728-8.37655,8.356-6.65769,16.25554-14.20279,16.25554-14.20279Z"/>
      <path class="cls-57" d="M581.73818,667.79321l3.11611,7.17513-5.78783,5.18382c1.05412-.61027,2.702-.07128,4.55034,2.54411,2.06094-.897,9.9118-4.40948,10.17553-6.21863l-3.85619-11.93812Z"/>
      <path class="cls-35" d="M551.27805,522.49123s-4.7504,47.25643,2.96027,78.65562c4.00862,16.32377,21.87549,62.90314,21.87549,62.90314,1.53956,5.85231,15.10652,4.95535,15.50761-.93008l-.42907-119.83378Z"/>
      <path class="cls-61" d="M569.11439,429.84115c-5.49711,1.35163-13.32164,3.53754-17.39939,12.442-3.23216,7.058-10.5134,39.58473-10.5134,39.58473l-13.75789,20.47854c-4.76653,6.85527,8.7264,15.6819,13.22025,10.235,0,0,14.64493-16.04111,16.60381-19.00054s12.06162-33.61122,12.06162-33.61122Z"/>
      <path class="cls-62" d="M524.79872,510.03422l3.7117-5.745c2.30673-4.11881,13.61678,4.05143,9.25535,8.06519l-4.12649,3.56457Z"/>
      <path class="cls-62" d="M557.92141,445.26326c-8.98039,13.8025-6.40322,30.1625-3.00448,51.27828l2.35084-2.96061,4.53065-9.13323Z"/>
      <g>
        <path class="cls-63" d="M519.94426,513.00079l-3.32571,6.69631,11.2884,5.52061,6.05456-8.64389c3.18048-5.3273-3.24556-10.90515-8.28034-7.826C525.30261,508.9793,519.94426,513.00079,519.94426,513.00079Z"/>
        <path class="cls-47" d="M531.63327,522.88828c-1.83439,3.96465-7.06422,8.59161-11.43268,8.59161S513.94,528.179,513.94,523.81051s9.1061-11.27925,14.35016-8.78832S533.66534,518.49638,531.63327,522.88828Z"/>
        <path class="cls-48" d="M529.25577,519.40414l3.16606,1.64053-.26566.66822-3.15629-1.65568C528.67675,519.801,528.78033,519.21993,529.25577,519.40414Z"/>
        <path class="cls-47" d="M514.2345,522.34755l3.0612-9.88184,1.75356-2.08978c1.20506-1.79214,2.59363-.37406,2.4212.77769-.54093,1.97156-1.88992,3.77156-.15815,4.18045Z"/>
        <path class="cls-63" d="M526.37454,527.71706c.13315-1.22063-.3569-1.81109-.292-2.99276.1054-1.91765,2.92559-5.223,4.10241-4.79261a8.66691,8.66691,0,0,1,2.05236,1.8673,2.229,2.229,0,0,1-.00017,3.07249l-3.82809,5.134c-1.01251,1.00914-3.35735,1.07363-4.96114.7428a7.41122,7.41122,0,0,0,2.5718-1.5836A3.96825,3.96825,0,0,0,526.37454,527.71706Z"/>
        <path class="cls-64" d="M527.80184,528.262a.34177.34177,0,0,1-.1448.04817.29676.29676,0,0,1-.26543-.1688l-1.163-2.05151a4.29864,4.29864,0,0,1-.13008-1.53587l1.82391,3.29764A.30933.30933,0,0,1,527.80184,528.262Z"/>
        <path class="cls-64" d="M532.18436,524.90354l-.38607.50671-3.80334-4.25676c.14479-.1448.29646-.30986.44125-.43065Z"/>
        <path class="cls-64" d="M530.62181,527.0253l-.38608.50686-3.49231-4.66423c.09649-.193.21712-.36191.33791-.555Z"/>
        <path class="cls-63" d="M520.50151,514.1765l-.48917,4.44944c-.28532,1.16516,1.91052,2.10113,2.5587.80337.19163-.38367,2.44172-5.09832,2.44172-5.09832Z"/>
      </g>
      <path class="cls-36" d="M612.688,549.261l-24.38417,6.589-37.02578-33.35878c.749-11.313,4.27891-19.40237,5.81877-25.99146l18.96286-8.53036,36.47079,20.73532C614.65831,519.35046,614.76331,530.358,612.688,549.261Z"/>
      <path class="cls-62" d="M556.28867,499.59062l-2.625,8.91944c3.12337,26.726,48.51009,27.1731,59.85933,6.35251a31.92356,31.92356,0,0,0-.99249-6.15779Z"/>
      <path class="cls-65" d="M612.53046,508.70477c-3.42177,6.118-11.35763,10.06857-18.32368,12.16725-15.15614,4.03244-39.73935.44191-37.96875-20.80645.81193-8.02957,3.74431-14.939,2.05186-21.55781,0,0-8.34937-12.96555-.36846-33.24453-2.663-11.37333,5.74943-13.2854,9.18917-14.76949,18.74084-7.20691,43.496,6.22452,48.83654,17.90526L607.14,489.89778Z"/>
      <path class="cls-47" d="M626.50458,532.15345l-1.48855,9.78972a7.29534,7.29534,0,0,1-1.96143,3.80025l-.38534.33275-.753.63044-1.82129,1.57619h-.01753l-.753.648-2.0665,1.75128-.7881.683-.01753.01753a1.10906,1.10906,0,0,1-1.83883-1.10332l.96319-3.45008,1.26087-6.0769-.75357-.12565-.98513,2.18738c-.38523.98072-1.98781.66755-1.82127-.3406l.90946-5.12742a5.88268,5.88268,0,0,1,1.02183-1.99646l2.95967-4.99988Z"/>
      <path class="cls-48" d="M619.97232,542.76622a63.65943,63.65943,0,0,1-2.71446,7.91583l-.7881.683a82.31372,82.31372,0,0,0,3.01215-8.774.26036.26036,0,1,1,.49041.17509Z"/>
      <path class="cls-48" d="M622.21391,541.9607a45.49435,45.49435,0,0,1-2.119,6.3221h-.01753l-.753.648a43.29663,43.29663,0,0,0,2.38172-7.12774.27732.27732,0,0,1,.33275-.17508A.286.286,0,0,1,622.21391,541.9607Z"/>
      <path class="cls-48" d="M624.24546,541.06753a39.78386,39.78386,0,0,1-1.5762,5.00864l-.753.63044a41.45532,41.45532,0,0,0,1.8213-5.79675.2675.2675,0,0,1,.33275-.15755A.25824.25824,0,0,1,624.24546,541.06753Z"/>
      <path class="cls-62" d="M616.57071,528.20728l-.22187,3.55068c-.36067,4.05,9.70889,4.49386,10.09722,1.69214l.638-4.54209Z"/>
      <path class="cls-66" d="M616.77115,450.32687s14.47392,31.76295,15.80544,39.863-3.77581,37.211-3.77581,37.211c-.90292,5.16623-13.409,5.02284-13.12063-1.39967l.267-29.84888-18.49805-32.8152C592.7917,450.55221,612.43747,437.26226,616.77115,450.32687Z"/>
      <path class="cls-62" d="M598.09076,464.47546,609.2751,512.992a14.8024,14.8024,0,0,0,3.25539-4.28719l-5.39045-18.807,1.35289-6.37476Z"/>
      <path class="cls-67" d="M593.35926,431.12429l-14.97049-5.5423c-10.75876,10.10162-9.64361,19.077-7.39459,27.5187C584.45135,448.66233,593.57113,436.62541,593.35926,431.12429Z"/>
      <g>
        <path class="cls-68" d="M593.06814,432.061,580.52843,425.582c-9.24869,8.6838-9.5813,15.56018-7.64795,22.817C584.44885,444.58359,593.25027,436.79,593.06814,432.061Z"/>
        <path class="cls-69" d="M577.37755,429.436c-2.36033.89584-4.07964,6.3502-4.59035,9.196l-5.66911,1.40641a21.937,21.937,0,0,1,7.92877-11.98907Z"/>
        <path class="cls-70" d="M576.445,431.22885c.53513,2.13159.01391,4.0084-.48541,5.95019a16.95288,16.95288,0,0,0-.20368,4.817c5.99483-1.90667,10.72378-6.27623,14.37874-11.55781a16.31063,16.31063,0,0,1-1.46024-6.25681Z"/>
        <polygon class="cls-67" points="572.787 438.632 574.957 443.787 567.118 440.038 572.787 438.632"/>
        <polygon class="cls-67" points="574.957 443.787 579.201 440.203 585.851 446.203 574.957 443.787"/>
        <path class="cls-69" d="M588.98574,426.91365l4.37352,4.21064c.49473,4.08979-1.53181,10.92259-7.30236,15.25051l-6.85637-6.17174C584.89068,435.26389,588.835,431.03185,588.98574,426.91365Z"/>
        <path class="cls-71" d="M578.373,433.43417c4.30077-.97788,11.037-6.42659,10.67983-12.06683l-.45967-12.865a10.233,10.233,0,0,0-10.85933-9.56578h0a10.233,10.233,0,0,0-9.56578,10.85933l.80691,12.5696C569.68267,428.02114,573.57863,434.52426,578.373,433.43417Z"/>
        <path class="cls-58" d="M588.89633,423.34765s-3.4169,9.67862.739,14.08105c7.10464,7.52608,19.80623,0,25.20228,8.9461C612.53049,439.09309,598.46666,429.66417,588.89633,423.34765Z"/>
      </g>
      <path class="cls-34" d="M600.48161,692.80152s1.53515,6.23816.70736,10.01572c-.46944,2.14222-1.44139,3.51859-4.71868,6.16613-2.40094,1.93958-3.33315,8.71364-12.30331,11.84013-8.753,3.0508-13.016-3.56961-8.4531-10.19,3.60767-5.2345,9.62386-14.28225,12.54964-17.92216C591.02721,688.44469,599.92682,688.195,600.48161,692.80152Z"/>
      <path class="cls-47" d="M588.87079,681.6339l.87848,11.58977-3.77559,6.23541c1.6741-1.16507,5.35379-.1387,6.6957,1.80309,2.761-2.5375,5.34338-5.40927,5.50982-7.68393l1.50367-12.73793Z"/>
      <path class="cls-36" d="M576.50371,544.07643s.096,49.51434,1.75342,72.93989l4.425,62.54071c-.15341,7.60195,20.48272,7.24567,20.268.18018l2.26559-62.55032L612.688,549.261Z"/>
      <polygon class="cls-72" points="456.328 784.126 320.67 784.126 320.67 743.732 438.893 743.732 434.476 756.878 475.856 763.813 456.328 784.126"/>
      <path class="cls-34" d="M432.65808,731.21767c.45739,0,9.22477-3.00343,10.09191,5.38967.39416,3.81511-1.68556,9.295-4.9852,14.4944-1.15349,1.81762-3.04731,8.21339-5.24315,10.81254-2.28368,2.70314-9.1407,2.53553-11.09217-.60587-1.91084-3.076-.35876-15.20522-.35876-15.20522Z"/>
      <path class="cls-73" d="M427.18421,533.35843l6.46764,27.341L430.353,573.016,413.10312,542.8363a20.69208,20.69208,0,0,1-2.06062-15.6269l6.05475-21.48485,15.67555,8.545Z"/>
      <path class="cls-35" d="M426.59752,580.06688l-7.09109,168.676c-.53544,6.08206,17.29917,7.0233,18.44755.79584,8.0278-44.04789,14.65376-93.24267,19.26826-141.73195Z"/>
      <path class="cls-74" d="M444.51744,506.05891l-15.17368,19.92415c-5.74214,1.18585-18.24174-3.97594-15.96726-12.575,0,0,7.42944-25.783,13.27648-32.537a21.33966,21.33966,0,0,1,18.58572-7.04591Z"/>
      <path class="cls-32" d="M425.59925,501.93,424.187,525.38016a11.25313,11.25313,0,0,0,3.8642.73064Z"/>
      <path class="cls-36" d="M487.70246,601.14685l-31.39454,7.8804-29.7104-28.96037c.95393-13.08152,1.15256-18.65078,1.15256-18.65078l60.097,8.61084C487.84713,581.28374,488.02781,581.36328,487.70246,601.14685Z"/>
      <path class="cls-75" d="M487.84705,570.02694c.00007,7.38884-14.62359,16.6127-29.86492,15.69886-17.10083-1.02533-30.86145-11.76765-30.386-23.27183l1.16507-30.04223s-4.52414-39.02292-3.94044-44.96623c1.29828-13.21825,20.4179-13.62028,20.4179-13.62028,14.49106,2.199,34.84217,14.55123,41.298,24.43294l-.35406,42.97483Z"/>
      <polygon class="cls-32" points="468.469 515.952 487.444 563.048 485.596 532.427 468.469 515.952"/>
      <path class="cls-32" d="M443.3218,479.3185c-.17659,1.969,1.36944,6.7325,13.46639,7.54257,6.86345-.097,10.0575-2.57664,9.12143-4.16076s-4.80087-4.44246-9.12143-5.449S443.79139,474.04825,443.3218,479.3185Z"/>
      <path class="cls-70" d="M445.18363,478.764c-.1118,1.51568,2.004,4.99833,12.12071,5.6758,5.73995-.08111,7.55245-1.52277,6.76961-2.84758s-4.015-3.71525-7.62831-4.55707S445.562,475.50822,445.18363,478.764Z"/>
      <path class="cls-76" d="M457.81405,441.6856h0a10.233,10.233,0,0,1,10.233,10.233v12.66421a10.233,10.233,0,0,1-10.233,10.233h0a10.233,10.233,0,0,1-10.233-10.233V451.91861a10.233,10.233,0,0,1,10.233-10.233Z" transform="translate(943.67635 886.64923) rotate(176.37623)"/>
      <path class="cls-70" d="M462.583,482.31174l-16.50125-3.2733,1.2899-10.6236c2.36024-4.53989,12.42062-.74785,15.21135,5.41039A15.90232,15.90232,0,0,0,462.583,482.31174Z"/>
      <path class="cls-77" d="M446.08175,472.17283c1.83083,5.20122,9.45928,6.25533,13.84217,3.758,2.34263-1.33481,2.23432-8.93147,5.04865-11.70545a4.73458,4.73458,0,0,0,3.60424-.25462c1.7218-.85417,1.34732-5.94522,1.34732-5.94522,6.154-6.03866,5.7074-11.99047,1.20668-14.85469-4.18458-2.663-2.74624-10.81853-11.789-7.55412-4.19026,1.51267-7.07065,1.68069-10.76928,2.79828a11.85259,11.85259,0,0,0-8.47736,8.57743c-3.41724,7.82914-1.21384,13.07033,1.9088,16.73362C444.854,467.06945,444.978,469.03729,446.08175,472.17283Z"/>
      <path class="cls-78" d="M468.35127,460.19528c-.2825-1.34524-1.6275-2.36177-3.24284-.7866-1.1,1.07264-1.67078,3.62972-.313,6.55649a2.74807,2.74807,0,0,0,2.538,1.65728S468.78132,462.24319,468.35127,460.19528Z"/>
      <path class="cls-34" d="M452.54276,770.7307s-2.24695,9.56847,2.45147,12.31942c4.71251,2.75921,11.07643-.4629,15.29689-2.145,4.218-1.68115,10.95984-2.38484,14.38166-6.17377,4.33951-4.80507,1.37222-12.15526-2.66724-11.49885a91.11627,91.11627,0,0,1-17.80475,1.568C457.93379,764.62992,452.54276,770.7307,452.54276,770.7307Z"/>
      <path class="cls-36" d="M487.70246,601.14685s-1.10791,40.56689-4.95024,79.30776c-2.50508,25.258-11.39949,89.57458-11.39949,89.57458-.59989,8.04143-18.637,5.82582-18.79068.75489l1.42555-90.02164-4.52713-78.40978Z"/>
      <path class="cls-47" d="M506.98218,552.39759,523.05976,529.303c2.99592-3.05018-5.10413-8.47916-7.71167-6.31545l-12.93438,9.72858Z"/>
      <path class="cls-79" d="M490.041,508.76349l17.91751,34.68682c4.06625,8.67158-8.06866,18.32364-14.42863,9.00849l-18.18445-29.78759C468.46854,512.15979,484.39971,498.47517,490.041,508.76349Z"/>
      <path class="cls-80" d="M487.22386,499.34756l12.77323,25.0046c4.8788,10.40441-13.46794,22.87507-19.97267,11.66576L468.94327,516.6481C459.56725,503.89689,480.45534,487.00334,487.22386,499.34756Z"/>
      <path class="cls-58" d="M566.75794,403.16242l.02419,13.40067c2.65862-.48018,5.0442-3.32353,5.46567-7.09613.29972,8.69549,9.70312,8.0884,12.53012,7.90562,0,0-.67668,2.66325.90449,4.1612,1.71793,1.62751,5.39891-2.37831,7.9781-2.26328C590.91014,395.4357,578.70335,388.36933,566.75794,403.16242Z"/>
      <path class="cls-47" d="M587.55781,416.23739s1.86992-2.177,3.72987-.44117c1.26655,1.18205,1.7159,4.5827-.33436,6.69723-1.23359,1.27226-3.39551.74657-3.39551.74657Z"/>
    </g>
    <g data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">
      <path class="cls-53" d="M727.62143,465.20963c-11.69285.236-21.84329,7.42261-24.40322,16.54056s-13.43734,57.375-13.43734,57.375l-20.154,18.63123c-4.56056,4.59742,4.37522,14.83568,10.312,10.18155L704.913,552.43891l1.39807-24.57915Z"/>
      <polygon class="cls-49" points="706.673 489.086 700.71 511.49 707.479 509.271 706.673 489.086"/>
      <polygon class="cls-81" points="714.972 767.169 620.051 767.234 620.051 718.769 730.907 719.392 732.693 734.3 745.305 732.565 714.972 767.169"/>
      <path class="cls-34" d="M757.01783,706.10179s4.7998,4.12686,4.89927,9.0526c.08645,4.28069-4.33948,6.62595-6.94785,8.767s-8.96483,9.82105-14.18585,10.68691c-9.46455,1.56963-9.36709-5.40062-6.46832-9.14822,0,0,9.05893-7.86337,11.02845-13.68874C746.06669,709.63245,757.01783,706.10179,757.01783,706.10179Z"/>
      <path class="cls-52" d="M707.37691,579.971s.55614,43.65157,6.42184,65.57665S740.98593,714.427,740.98593,714.427c3.046,7.98586,19.91964-2.32277,18.1591-5.93949L737.09685,647.9714l6.45118-57.4768Z"/>
      <path class="cls-54" d="M766.2781,596.05828l-25.28972,10.19079L707.37691,579.971,704.56,550.04535l18.00834-11.582,42.96857,20.87485C766.7267,574.29679,767.983,582.93022,766.2781,596.05828Z"/>
      <path class="cls-82" d="M763.20991,560.60178,746.7637,572.41327c-14.24746,11.4845-39.40681-.00137-42.15755-21.9026,3.62215-16.10532,1.65845-57.23214,2.15129-65.762.72123-12.48292,17.56285-19.05875,17.56285-19.05875,9.34478-3.02215,42.07777,8.2957,47.54479,31.78232Z"/>
      <polygon class="cls-49" points="750.339 509.819 748.312 530.131 750.123 559.646 758.298 549.438 750.339 509.819"/>
      <path class="cls-49" d="M750.32164,478.59678c-3.85842,5.58794-21.67962,14.54009-21.67962,14.54009s-5.88523-16.03335-.95561-23.4192c2.50271-3.74972,5.78151-3.41029,12.27944-2.23838S754.029,473.22756,750.32164,478.59678Z"/>
      <path class="cls-69" d="M747.53,476.10773c-2.81794,4.08109-16.72155,11.07543-16.72155,11.07543s-3.41008-12.166.1902-17.56017c1.82783-2.73856,4.22246-2.49066,8.96814-1.63477S750.2376,472.1864,747.53,476.10773Z"/>
      <path class="cls-70" d="M747.34106,473.62123c-.58116,4.34253-8.70754,6.91863-14.04439,5.36168-2.48192-.72407-3.06981-5.4775-1.23867-8.542a7.86469,7.86469,0,0,1,9.58787-3.07C745.38593,468.86425,747.81461,470.08287,747.34106,473.62123Z"/>
      <path class="cls-70" d="M747.20909,471.461c-3.24988,4.48157-13.7804,5.28924-13.9627,1.44135l-4.87626-9.72095,16.924-1.25415A18.21614,18.21614,0,0,0,747.20909,471.461Z"/>
      <circle class="cls-83" cx="725.8341" cy="536.177" r="8.76902"/>
      <path class="cls-34" d="M738.09726,741.17532s1.92058,7.3883.12517,10.24654c-2.4362,3.87837-5.36959,5.35071-8.226,7.23648-2.668,1.76134-8.94659,8.46958-15.02462,8.51106-9.39679.06412-12.06052-4.98342-8.8536-9.89724,1.79485-2.75018,12.79559-12.08487,16.06465-19.09057Z"/>
      <path class="cls-54" d="M766.27811,596.0583c-1.22614,12.55527-28.03728,145.71243-28.03728,145.71243-1.28,6.01886-16.41273,5.30911-16.45507-.51659l4.61853-70.63684,5.77477-77.04474Z"/>
      <path class="cls-84" d="M664.915,531.93714l-.08973-.04263.93889,46.51383a4.23122,4.23122,0,0,0,2.20715,3.77334l38.69519,21.28147a7.72192,7.72192,0,0,0,3.88108.94259l-.02776-1.37558-.938-46.4694Z"/>
      <path class="cls-85" d="M709.58183,556.56076l.938,46.4694.02776,1.37558a7.97207,7.97207,0,0,0,3.83989-1.09844L752.455,580.29862a4.32349,4.32349,0,0,0,2.0564-3.68166l-.92674-45.9121Z"/>
      <path class="cls-86" d="M728.92082,558.88393l11.22618-6.49808a2.45327,2.45327,0,0,0,1.22428-2.12324h0a1.78766,1.78766,0,0,0-2.68321-1.54717l-11.22618,6.49807a2.45331,2.45331,0,0,0-1.22429,2.12325h0A1.78767,1.78767,0,0,0,728.92082,558.88393Z"/>
      <path class="cls-87" d="M666.91049,528.82349l38.06765-23.015a7.728,7.728,0,0,1,7.72643-.156l38.65714,21.2903a4.29334,4.29334,0,0,1,.15007,7.43476l-38.06766,23.015a7.728,7.728,0,0,1-7.72643.156l-38.65713-21.29029A4.29333,4.29333,0,0,1,666.91049,528.82349Z"/>
      <path class="cls-53" d="M771.86508,497.47221s9.09672,47.67619,7.1969,54.77432-24.64745,29.00886-24.64745,29.00886c-8.29259,7.67631-18.58224-2.43858-12.65925-9.70718l16.31937-24.37338-8.11-38.90192C746.085,490.876,768.51282,482.1281,771.86508,497.47221Z"/>
      <path class="cls-49" d="M751.01718,582.77773l-4.736,3.50156c-2.48646,1.49994-9.413-5.01114-7.8815-7.33157l3.51923-5.23216C744.75215,569.85705,756.35884,577.9994,751.01718,582.77773Z"/>
      <path class="cls-88" d="M751.42991,439.08855c1.97392,6.21493-.95158,10.7896-2.28931,14.6149l-1.2879-9.387Z"/>
      <path class="cls-89" d="M740.26353,471.23946c6.19665.57657,9.50881-5.6464,9.20253-11.4107l-.68633-12.91689a10.4519,10.4519,0,0,0-10.99175-9.88261h0a10.4519,10.4519,0,0,0-9.88261,10.99175l.68632,12.91689C728.898,466.7022,734.06688,470.6629,740.26353,471.23946Z"/>
      <path class="cls-88" d="M751.42991,439.08855c1.49686,4.398-3.76671,8.92725-11.03337,13.91106-4.48423,3.07548-11.48478,1.14777-11.48478,1.14777a25.22245,25.22245,0,0,0,.82023,11.7489s-7.9384-9.54937-9.51291-16.42393c-1.11625-4.87372-.92039-8.73469,5.79571-14.49189S749.20868,432.56221,751.42991,439.08855Z"/>
      <path class="cls-90" d="M728.9117,454.14739s-2.15986-1.959-3.52822-.09751c-.9318,1.26761-.79455,4.63686,1.3459,6.52943a3.28574,3.28574,0,0,0,3.11251.43607Z"/>
      <path class="cls-88" d="M728.63048,454.03108l1.7898,4.94746c.34334,1.0115,1.903.77474,1.771-.26561l-.18821-4.87471Z"/>
      <path class="cls-47" d="M738.87459,580.0818c4.81661-5.16871,8.71113,1.76636,7.467,4.73249a65.41265,65.41265,0,0,1-4.2324,6.39548,3.74482,3.74482,0,0,1-1.15385,1.04753l-2.99034,1.75149-9.05186,1.23583a1.41336,1.41336,0,0,1-1.54763-.80687,1.63217,1.63217,0,0,1,.30519-1.40778l5.15187-6.294Z"/>
      <path class="cls-47" d="M746.34487,584.81454a64.50284,64.50284,0,0,1-4.23808,6.39223,3.49655,3.49655,0,0,1-1.15591,1.05073l-2.97709,1.75128-.80563.10507-1.22592.17519h-.01753l-2.52185.35018h-.01742l-1.191.15766h-.01742l-2.64446.36781-.613.08754a1.50914,1.50914,0,0,1-.49031-.03506,1.28389,1.28389,0,0,1-1.06825-.7881,1.63821,1.63821,0,0,1,.31522-1.401l5.14877-6.28716,6.04184-6.65485C743.68288,574.91975,747.58832,581.85487,746.34487,584.81454Z"/>
      <path class="cls-47" d="M741.24266,578.41364l-6.37758,2.05512a3.70937,3.70937,0,0,0-2.05569,1.64334l-2.1051,3.562c-.48875,1.11462.41754,2.08259,1.8,1.3764Z"/>
      <g>
        <path class="cls-48" d="M735.46947,589.75317l-5.93688,5.41152-.613.08754a1.50914,1.50914,0,0,1-.49031-.03506l6.56732-5.98936a.35364.35364,0,0,1,.47288.52536Z"/>
        <path class="cls-48" d="M738.23642,590.22606l-4.83356,4.41316h-.01742l-1.191.15766,5.56917-5.07877a.347.347,0,0,1,.47278.50795Z"/>
        <path class="cls-48" d="M740.6532,590.944l-3.485,3.16982-1.22592.17519,4.23808-3.87037a.35363.35363,0,1,1,.47288.52536Z"/>
      </g>
    </g>
   
    <path class="cls-27" d="M359.93093,209.18133,319.64406,185.862a3.98147,3.98147,0,0,1-1.9869-3.44583v-5.17055a1.99073,1.99073,0,0,1,2.988-1.72292L360.932,198.842a3.98147,3.98147,0,0,1,1.98691,3.44583v5.17055A1.99073,1.99073,0,0,1,359.93093,209.18133Z"/>
    <path class="cls-27" d="M420.24647,272.63146,319.64406,214.5861a3.98145,3.98145,0,0,1-1.9869-3.44583v-5.17055a1.99073,1.99073,0,0,1,2.988-1.72292l100.60241,58.04536a3.98146,3.98146,0,0,1,1.9869,3.44583v5.17055A1.99073,1.99073,0,0,1,420.24647,272.63146Z"/>
    <path class="cls-27" d="M376.57607,267.734l-56.932-32.86432a3.98147,3.98147,0,0,1-1.9869-3.44583v-5.17055a1.99073,1.99073,0,0,1,2.988-1.72292l56.932,32.86432a3.98148,3.98148,0,0,1,1.9869,3.44584v5.17055A1.99073,1.99073,0,0,1,376.57607,267.734Z"/>
    <path  class="cls-91" d="M393.71917,99.17867,347.13785,72.21589a4.60352,4.60352,0,0,1-2.29734-3.98421v-5.9784a2.30176,2.30176,0,0,1,3.45486-1.99211L394.8767,87.224a4.60353,4.60353,0,0,1,2.29733,3.98421v5.9784A2.30176,2.30176,0,0,1,393.71917,99.17867Z"/>
   
    <g data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">
      <g>
        <g>
          <path class="cls-47" d="M788.62828,542.88737l-15.53362,22.41772-13.69751-4.858-3.60618,5.93633,15.12236,12.61554c4.17859,3.00553,7.41182,2.27316,11.39095-1.65476l11.19768-12.141Z"/>
          <path class="cls-58" d="M809.53923,492.98663c-1.821,8.76658-10.04634,16.92326-2.47336,19.16275-.18966,5.06785,5.50672,8.90994,7.46653,9.75577,6.94335,2.99667,29.51517.01979,29.51517.01979a6.48551,6.48551,0,0,0,3.093-4.34709c9.22461,1.08766-.686-14.32394-3.093-24.21686C839.4705,474.54891,813.70418,472.9356,809.53923,492.98663Z"/>
          <path class="cls-92" d="M816.51388,517.13877c-6.90251-1.59074-15.81854.88965-21.3504,9.99084l-15.12684,26.2878c-3.1232,4.72075,9.48195,17.36753,15.79337,11.72124l15.911-13.0751Z"/>
          <path class="cls-62" d="M799.804,533.54933c-10.07151,17.04136-11.17576,31.8596-11.17576,31.8596a11.34361,11.34361,0,0,0,5.52839,1.64712l1.52812-1.41025Z"/>
          <path class="cls-62" d="M781.90155,550.14714l-5.94867,9.675c-3.37183,5.62936,9.5936,15.78381,13.56312,12.08728l10.256-10.61031C791.46623,563.96194,782.04782,556.79789,781.90155,550.14714Z"/>
          <g>
            <g>
              <polygon class="cls-93" points="817.272 816.563 722.554 816.563 723.753 770.374 802.518 770.374 803.218 786.922 830.932 791.659 817.272 816.563"/>
              <path class="cls-34" d="M825.449,754.913s3.8539,6.79394,2.69151,9.90634c-1.72486,4.61846-4.5965,5.77628-6.91336,8.22223-2.164,2.28455-8.35866,11.371-14.0238,13.29444-7.45115,2.52984-10.66359-3.62724-8.25521-8.87674,4.30573-9.38514,9.43676-16.25554,11.05693-23.63433Z"/>
              <path class="cls-35" d="M793.502,592.96482s-18.22011,87.13845-16.20949,102.4347c1.5064,11.46038,30.51184,63.36661,30.51184,63.36661,3.94953,8.52849,17.17877-2.77584,15.88635-8.86549l-13.20416-51.818,13.71449-51.5183Z"/>
              <path class="cls-34" d="M837.191,790.57925s2.38543,6.92277.69327,9.78192c-2.511,4.24268-3.93773,5.12777-6.649,7.12764-2.53237,1.86791-5.62717,7.33576-11.80126,8.81822-5.81742,1.39681-13.69563-3.1693-10.17608-7.74864,1.614-2.1,10.8173-13.72228,14.75511-20.19371Z"/>
              <path class="cls-36" d="M853.03585,643.91115,838.38133,790.49254c-.28774,7.15821-15.48561,7.81666-15.96671,1.01784l-9.23008-152.7501Z"/>
            </g>
            <path class="cls-36" d="M854.052,644.28393l-30.79074,2.99889c-15.65647-11.03308-23.95755-25.84894-32.46093-41.03375l2.70168-13.28425,20.05577-7.55968,38.19577,16.12817C854.94775,609.94024,855.23928,624.38318,854.052,644.28393Z"/>
            <path class="cls-62" d="M793.502,592.96482l-2.32951,11.575c2.71578,23.76236,53.74307,28.00655,62.41975,4.21084a33.38086,33.38086,0,0,0-1.83871-7.2174Z"/>
            <path class="cls-94" d="M851.7535,601.53331c1.197,4.37078-12.09536,13.3906-21.40717,14.46952-15.907,1.33269-36.13017-2.86183-36.84437-23.038l3.76373-15.55759c-2.32569-13.781-5.63216-23.359,1.72385-39.24654l1.95838-11.08751c3.898-9.713,15.80509-10.04641,15.80509-10.04641,9.042.98509,37.26716,10.3456,41.335,26.37862l-11.537,44.46512Z"/>
            <path class="cls-62" d="M836.93155,518.17l-14.9705-5.54231c-10.75876,10.10163-9.6436,19.077-7.39459,27.51871C828.02364,535.708,837.14341,523.67109,836.93155,518.17Z"/>
            <path class="cls-68" d="M836.64043,519.10673l-12.53972-6.47906c-9.24869,8.68379-9.58129,15.56018-7.64794,22.817C828.02114,531.62928,836.82255,523.83573,836.64043,519.10673Z"/>
            <path class="cls-69" d="M820.94983,516.4817c-2.36032.89584-4.07963,6.3502-4.59034,9.196l-5.66911,1.40641a21.937,21.937,0,0,1,7.92877-11.98907Z"/>
            <path class="cls-70" d="M820.0173,518.27454c.53513,2.13159.01391,4.0084-.48541,5.95019a16.953,16.953,0,0,0-.20369,4.817c5.99484-1.90666,10.72379-6.27622,14.37875-11.5578a16.31063,16.31063,0,0,1-1.46024-6.25682Z"/>
            <polygon class="cls-67" points="816.359 525.678 818.53 530.832 810.69 527.084 816.359 525.678"/>
            <polygon class="cls-67" points="818.53 530.832 823.457 527.249 832.245 534.167 818.53 530.832"/>
            <path class="cls-69" d="M832.558,513.95933l4.37352,4.21065a22.78611,22.78611,0,0,1-4.68626,15.99746l-8.78816-6.91869C829.14727,522.30958,832.40724,518.07754,832.558,513.95933Z"/>
            <path class="cls-62" d="M837.96754,557.65033l7.07121,52.59327c4.01141-3.10377,7.49445-6.30539,6.71475-8.7103l-3.7992-12.94951,1.12514-9.39186Z"/>
          </g>
          <path class="cls-95" d="M827.737,617.31739l42.50422-21.41361a1.68359,1.68359,0,0,1,2.212,1.04161l6.8354,31.36631a2.065,2.065,0,0,1-1.13711,2.37107l-42.5042,21.41361a1.68361,1.68361,0,0,1-2.212-1.04161L826.6,619.68846A2.065,2.065,0,0,1,827.737,617.31739Z"/>
          <path class="cls-47" d="M858.59694,627.61366l-3.4507,2.56588a5.00992,5.00992,0,0,0-1.42215,1.64595l-3.18921,5.92544c-.63076,1.10875.72935,2.078,1.91151,1.00934l1.71676-1.58594,1.45687.49525-1.73938,5.247-1.53264.766s1.00858,1.37064,2.171,1.37064c.948,0,6.62364-3.58373,8.68549-4.906a2.66685,2.66685,0,0,0,1.06035-1.31659l2.87581-7.67715Z"/>
          <path class="cls-47" d="M867.14725,631.27174l-2.88955,7.67063a2.62751,2.62751,0,0,1-1.05083,1.33089c-.28016.17519-.648.40287-1.05073.64807-.28016.19262-.578.3677-.89317.57785h-.01753c-.6655.40287-1.36595.85811-2.084,1.29593l-.01743.01753c-.29779.15766-.578.33275-.85821.49041-.87565.52536-1.71623.99824-2.38172,1.331-.31522.15756-.59538.29769-.82306.38523h-.01753a1.54947,1.54947,0,0,1-.54289.15767c-1.17333,0-2.17157-1.38359-2.17157-1.38359l1.5236-.753,1.75129-5.25385-1.4536-.49031-1.71622,1.57609c-1.19086,1.06837-2.55691.10508-1.92648-.99824l3.18735-5.93677A4.98786,4.98786,0,0,1,855.151,630.291l3.45008-2.55691Z"/>
          <path class="cls-48" d="M859.14507,638.71909a64.54215,64.54215,0,0,1-3.23994,5.79674c-.31522.15756-.59537.29769-.823.38523a72.9432,72.9432,0,0,0,3.60764-6.44471.24764.24764,0,0,1,.3677-.08754A.25111.25111,0,0,1,859.14507,638.71909Z"/>
          <path class="cls-48" d="M861.49173,638.35128a42.13073,42.13073,0,0,1-2.32924,4.32562l-.01742.01753c-.29779.15766-.57795.33275-.85822.49041a39.45384,39.45384,0,0,0,2.74953-5.07876.26077.26077,0,0,1,.35028-.10508A.25.25,0,0,1,861.49173,638.35128Z"/>
          <path class="cls-48" d="M863.6633,637.86087c-.43782.98071-.94565,1.96154-1.50607,2.94225-.28016.19262-.578.3677-.89318.57785a34.648,34.648,0,0,0,1.92648-3.7652.27609.27609,0,0,1,.3677-.08765A.25624.25624,0,0,1,863.6633,637.86087Z"/>
          <path class="cls-47" d="M858.088,546.01408l11.6776,40.3428a26.70145,26.70145,0,0,1,.87928,10.46416l-3.50428,34.33246-8.5437-3.53984-1.86466-34.28288L841.07406,554.6029Z"/>
          <path class="cls-96" d="M858.83168,544.292l9.94278,31.67108c1.34277,4.91962-15.01582,12.594-17.00372,8.79477l-13.82055-28.58264C831.109,543.08869,854.123,531.03239,858.83168,544.292Z"/>
          <path class="cls-62" d="M848.84708,578.76461l3.74374,11.31693c2.44111,5.76988,20.67454.03694,18.01152-8.396l-3.57946-11.30185C866.503,577.57112,855.0031,581.91949,848.84708,578.76461Z"/>
        </g>
        <path class="cls-97" d="M823.37777,519.16033c6.66743-1.91405,9.62334-4.59391,9.78543-10.36406l.3632-12.93A10.4519,10.4519,0,0,0,823.37209,485.125l0,0A10.45192,10.45192,0,0,0,812.6308,495.2793l.14636,13.0625C812.61508,514.112,815.97968,521.28413,823.37777,519.16033Z"/>
        <path class="cls-58" d="M831.46146,508.29888c-1.09465,3.0168-2.14584,5.99045-2.12925,7.9007-.14256,3.48884,1.55172,5.89837,5.53962,7.38138,3.5336,1.31407,7.671-.63642,9.17575-1.656l-10.59741-14.71048Z"/>
        <path class="cls-58" d="M816.0997,492.98663c.749,8.52986,8.59576,13.06,18.03135,10.81307,0,0,1.00059-10.03256-1.026-15.32271s-7.52044-7.197-12.43107-6.77153c-4.18567.36263-8.56576,5.16259-9.81082,9.80625-1.084,4.0429-.749,5.24207-.749,5.24207C810.06525,503.29184,814.35209,499.56085,816.0997,492.98663Z"/>
        <path class="cls-58" d="M826.32853,503.6436c-.63779,2.28353,1.56753,5.15461,2.84273,4.18513l.711-3.614Z"/>
        <path class="cls-98" d="M830.17744,503.09522s2.22716-1.79015,3.457.11066c.83745,1.29438.50644,4.58212-1.69784,6.30842a3.21981,3.21981,0,0,1-3.07009.24479Z"/>
      </g>
      <path class="cls-47" d="M759.91731,560.62933l-1.89142,7.513-7.37283.59549-6.70744-.9107a3.654,3.654,0,0,1-1.96143-.87565,4.5035,4.5035,0,0,1-.61291-.68291,4.63191,4.63191,0,0,1-.35028-.64808l-.2452-.56031-.29769-.683-.52536-1.261-.2978-.68292-.08754-.19272a1.55851,1.55851,0,0,1,1.01577-2.119l3.95792-1.06836,6.19549-1.67033a2.84517,2.84517,0,0,1,1.85471.12909Z"/>
      <g>
        <path class="cls-48" d="M756.46733,563.23872a5.66672,5.66672,0,0,1-4.60588-.2452,2.16234,2.16234,0,0,1-.96318-1.6987l-.03506-.31521-4.76355-.08755c-.84058-.01753-1.29593-.42039-1.38347-.77057a.51872.51872,0,0,1,.31521-.56042l5.64828-2.16291-6.13858,1.655a1.18614,1.18614,0,0,0-.50794,1.20839,1.96294,1.96294,0,0,0,2.04908,1.331l.2747.00577-6.702.83492.2978.68292,8.53814-1.09188a.35083.35083,0,0,0,.31191-.37433l1.43.03015a2.83973,2.83973,0,0,0,1.22581,1.89141,4.85342,4.85342,0,0,0,2.71458.71809,8.69722,8.69722,0,0,0,2.48679-.38534.33971.33971,0,0,0,.2452-.42029A.35029.35029,0,0,0,756.46733,563.23872Z"/>
        <path class="cls-48" d="M749.217,564.11436a.32791.32791,0,0,0-.36781-.31521l-8.37108.57795.29769.683,8.10845-.578A.34112.34112,0,0,0,749.217,564.11436Z"/>
        <path class="cls-48" d="M749.81237,565.90071l-8.44109.36781a4.5035,4.5035,0,0,0,.61291.68291l7.86324-.35027a.33174.33174,0,0,0,.33275-.3677A.35227.35227,0,0,0,749.81237,565.90071Z"/>
      </g>
    </g>
    <path class="cls-27" d="M357.41692,328.6813,317.13005,305.362a3.98146,3.98146,0,0,1-1.9869-3.44583v-5.17055a1.99073,1.99073,0,0,1,2.988-1.72292L358.418,318.342a3.98147,3.98147,0,0,1,1.9869,3.44583v5.17055A1.99073,1.99073,0,0,1,357.41692,328.6813Z"/>
    <g>
      <ellipse class="cls-99" cx="122.15331" cy="595.17741" rx="6.75983" ry="3.89439" transform="translate(-454.3621 403.37657) rotate(-60)"/>
      <ellipse class="cls-99" cx="137.56829" cy="585.5794" rx="6.75983" ry="3.89439" transform="matrix(0.5, -0.86603, 0.86603, 0.5, -438.34249, 411.92734)"/>
      <ellipse class="cls-99" cx="152.69242" cy="576.71088" rx="6.75983" ry="3.89439" transform="translate(-423.10006 420.59096) rotate(-60)"/>
    </g>
    <path class="cls-100" d="M92.32384,240.904l46.58133-26.96278a4.6035,4.6035,0,0,0,2.29733-3.98421v-5.9784a2.30176,2.30176,0,0,0-3.45486-1.99211L91.16632,228.94931a4.60352,4.60352,0,0,0-2.29734,3.98421v5.9784A2.30176,2.30176,0,0,0,92.32384,240.904Z"/>
    <g>
      <ellipse class="cls-101" cx="870.85138" cy="430.5414" rx="3.89439" ry="6.75983" transform="translate(-98.59874 493.1073) rotate(-30)"/>
      <ellipse class="cls-101" cx="855.4364" cy="420.94339" rx="3.89439" ry="6.75983" transform="translate(-95.86495 484.11392) rotate(-30)"/>
      <ellipse class="cls-101" cx="840.31227" cy="412.07487" rx="3.89439" ry="6.75983" transform="translate(-93.45694 475.3637) rotate(-30)"/>
    </g>
    <polygon class="cls-102" points="499.118 835.275 521.572 822.31 544.019 835.258 521.569 848.274 499.118 835.275"/>
    <g>
      <ellipse class="cls-103" cx="692.10735" cy="915.624" rx="6.75983" ry="3.89439" transform="translate(-446.89997 1057.19455) rotate(-60)"/>
      <ellipse class="cls-104" cx="707.52233" cy="906.02599" rx="6.75983" ry="3.89439" transform="translate(-430.88036 1065.74531) rotate(-60)"/>
      <ellipse class="cls-105" cx="722.64647" cy="897.15747" rx="6.75983" ry="3.89439" transform="translate(-415.63793 1074.40893) rotate(-60)"/>
    </g>
  </g>
</svg>
</div>

</template>

<script>
export default {
   name: 'landingsvg',
   created(){

   }
}
</script>

<style scoped>
    #landingsvg{
      width: 45%;
    }
      #landingsvg .cls-1 {
        fill: url(#linear-gradient-landing);
      }

      #landingsvg .cls-2 {
        fill: url(#linear-gradient-landing-2);
      }

      #landingsvg .cls-3 {
        fill: url(#linear-gradient-landing-3);
      }

      #landingsvg .cls-100, #landingsvg .cls-4, #landingsvg .cls-5, #landingsvg .cls-6 {
        opacity: 0.7;
      }

      #landingsvg .cls-4 {
        fill: url(#linear-gradient-landing-4);
      }

      #landingsvg .cls-5 {
        fill: url(#linear-gradient-landing-5);
      }

      #landingsvg .cls-6 {
        fill: url(#linear-gradient-landing-6);
      }

      #landingsvg .cls-7 {
        opacity: 0.4;
        fill: url(#linear-gradient-landing-7);
      }

      #landingsvg .cls-12, #landingsvg .cls-13, #landingsvg .cls-8 {
        fill: none;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }

      #landingsvg .cls-8 {
        stroke: #c5e5ff;
        stroke-width: 2.3034px;
      }

      #landingsvg .cls-9 {
        fill: url(#linear-gradient-landing-8);
      }

      #landingsvg .cls-10 {
        fill: url(#linear-gradient-landing-9);
      }

      #landingsvg .cls-11 {
        fill: url(#linear-gradient-landing-10);
      }

      #landingsvg .cls-12, #landingsvg .cls-13 {
        stroke-width: 1.75128px;
      }

      #landingsvg .cls-12, #landingsvg .cls-13, #landingsvg .cls-33, #landingsvg .cls-50, #landingsvg .cls-60, #landingsvg .cls-72, #landingsvg .cls-81, #landingsvg .cls-91, #landingsvg .cls-93 {
        opacity: 0.5;
      }

      #landingsvg .cls-12 {
        stroke: url(#linear-gradient-landing-11);
      }

      #landingsvg .cls-13 {
        stroke: url(#linear-gradient-landing-12);
      }

      #landingsvg .cls-14 {
        fill: url(#linear-gradient-landing-13);
      }

      #landingsvg .cls-15 {
        fill: url(#linear-gradient-landing-14);
      }

      #landingsvg .cls-16 {
        fill: url(#linear-gradient-landing-15);
      }

      #landingsvg .cls-17 {
        fill: url(#linear-gradient-landing-16);
      }

      #landingsvg .cls-18 {
        fill: url(#linear-gradient-landing-17);
      }

      #landingsvg .cls-19 {
        fill: url(#linear-gradient-landing-18);
      }

      #landingsvg .cls-20 {
        fill: url(#linear-gradient-landing-19);
      }

      #landingsvg .cls-21 {
        fill: url(#linear-gradient-landing-20);
      }

      #landingsvg .cls-22 {
        fill: url(#linear-gradient-landing-21);
      }

      #landingsvg .cls-23 {
        fill: url(#linear-gradient-landing-22);
      }

      #landingsvg .cls-24 {
        fill: url(#linear-gradient-landing-23);
      }

      #landingsvg .cls-25 {
        fill: url(#linear-gradient-landing-24);
      }

      #landingsvg .cls-26 {
        fill: url(#linear-gradient-landing-25);
      }

      #landingsvg .cls-27 {
        fill: #2075e5;
        opacity: 0.2;
      }

      #landingsvg .cls-28 {
        fill: url(#linear-gradient-landing-26);
      }

      #landingsvg .cls-29 {
        fill: url(#linear-gradient-landing-27);
      }

      #landingsvg .cls-30 {
        fill: url(#linear-gradient-landing-28);
      }

      #landingsvg .cls-31 {
        fill: url(#linear-gradient-landing-29);
      }

      #landingsvg .cls-32, #landingsvg .cls-38 {
        fill: #86bcfc;
      }

      #landingsvg .cls-33 {
        fill: url(#linear-gradient-landing-30);
      }

      #landingsvg .cls-34 {
        fill: #253854;
      }

      #landingsvg .cls-35 {
        fill: #20314c;
      }

      #landingsvg .cls-36 {
        fill: #2b466d;
      }

      #landingsvg .cls-37 {
        fill: url(#linear-gradient-landing-31);
      }

      #landingsvg .cls-102, #landingsvg .cls-38, #landingsvg .cls-67 {
        opacity: 0.4;
      }

      #landingsvg .cls-39 {
        fill: url(#linear-gradient-landing-32);
      }

      #landingsvg .cls-40 {
        fill: #1c437c;
      }

      #landingsvg .cls-41 {
        fill: #af5a5a;
      }

      #landingsvg .cls-42 {
        fill: #d1726f;
      }

      #landingsvg .cls-43 {
        fill: #914d4d;
      }

      #landingsvg .cls-44 {
        fill: url(#linear-gradient-landing-33);
      }

      #landingsvg .cls-45 {
        fill: #12303f;
      }

      #landingsvg .cls-46 {
        fill: #c96969;
      }

      #landingsvg .cls-47 {
        fill: #f9a49d;
      }

      #landingsvg .cls-48 {
        fill: #d36c6c;
      }

      #landingsvg .cls-49 {
        fill: #28417f;
      }

      #landingsvg .cls-50 {
        fill: url(#linear-gradient-landing-34);
      }

      #landingsvg .cls-51 {
        fill: #e07e7e;
      }

      #landingsvg .cls-52 {
        fill: #355077;
      }

      #landingsvg .cls-53 {
        fill: #3660b3;
      }

      #landingsvg .cls-54 {
        fill: #476ba0;
      }

      #landingsvg .cls-55 {
        fill: url(#linear-gradient-landing-35);
      }

      #landingsvg .cls-56 {
        fill: url(#linear-gradient-landing-36);
      }

      #landingsvg .cls-57 {
        fill: #ea8b86;
      }

      #landingsvg .cls-58 {
        fill: #122c3d;
      }

      #landingsvg .cls-59 {
        fill: url(#linear-gradient-landing-37);
      }

      #landingsvg .cls-60 {
        fill: url(#linear-gradient-landing-38);
      }

      #landingsvg .cls-61 {
        fill: url(#linear-gradient-landing-39);
      }

      #landingsvg .cls-62 {
        fill: #3753b7;
      }

      #landingsvg .cls-63 {
        fill: #e08080;
      }

      #landingsvg .cls-64 {
        fill: #b55e5e;
      }

      #landingsvg .cls-65 {
        fill: url(#linear-gradient-landing-40);
      }

      #landingsvg .cls-66 {
        fill: url(#linear-gradient-landing-41);
      }

      #landingsvg .cls-67 {
        fill: #1f4c63;
      }

      #landingsvg .cls-68 {
        fill: #a1c8e2;
      }

      #landingsvg .cls-69 {
        fill: #b4dff9;
      }

      #landingsvg .cls-70 {
        fill: #d36e6e;
      }

      #landingsvg .cls-71 {
        fill: url(#linear-gradient-landing-42);
      }

      #landingsvg .cls-72 {
        fill: url(#linear-gradient-landing-43);
      }

      #landingsvg .cls-73 {
        fill: url(#linear-gradient-landing-44);
      }

      #landingsvg .cls-74 {
        fill: url(#linear-gradient-landing-45);
      }

      #landingsvg .cls-75 {
        fill: url(#linear-gradient-landing-46);
      }

      #landingsvg .cls-76 {
        fill: url(#linear-gradient-landing-47);
      }

      #landingsvg .cls-77 {
        fill: #5b312d;
      }

      #landingsvg .cls-78 {
        fill: url(#linear-gradient-landing-48);
      }

      #landingsvg .cls-79 {
        fill: url(#linear-gradient-landing-49);
      }

      #landingsvg .cls-80 {
        fill: url(#linear-gradient-landing-50);
      }

      #landingsvg .cls-81 {
        fill: url(#linear-gradient-landing-51);
      }

      #landingsvg .cls-82 {
        fill: url(#linear-gradient-landing-52);
      }

      #landingsvg .cls-83 {
        fill: #50acff;
      }

      #landingsvg .cls-84 {
        fill: url(#linear-gradient-landing-53);
      }

      #landingsvg .cls-85 {
        fill: url(#linear-gradient-landing-54);
      }

      #landingsvg .cls-86 {
        fill: #2a5093;
      }

      #landingsvg .cls-87 {
        fill: url(#linear-gradient-landing-55);
      }

      #landingsvg .cls-88 {
        fill: #38282f;
      }

      #landingsvg .cls-89 {
        fill: url(#linear-gradient-landing-56);
      }

      #landingsvg .cls-90 {
        fill: #eb9190;
      }

      #landingsvg .cls-91 {
        fill: #53b2f9;
      }

      #landingsvg .cls-92 {
        fill: url(#linear-gradient-landing-57);
      }

      #landingsvg .cls-93 {
        fill: url(#linear-gradient-landing-58);
      }

      #landingsvg .cls-94 {
        fill: url(#linear-gradient-landing-59);
      }

      #landingsvg .cls-95 {
        fill: #cbe5f4;
      }

      #landingsvg .cls-96 {
        fill: url(#linear-gradient-landing-60);
      }

      #landingsvg .cls-97 {
        fill: url(#linear-gradient-landing-61);
      }

      #landingsvg .cls-98 {
        fill: url(#linear-gradient-landing-62);
      }

      #landingsvg .cls-99 {
        fill: #84acf7;
      }

      #landingsvg .cls-100 {
        fill: url(#linear-gradient-landing-63);
      }

      #landingsvg .cls-101 {
        fill: #c0ddff;
      }

      #landingsvg .cls-102 {
        fill: url(#linear-gradient-landing-64);
      }

      #landingsvg .cls-103 {
        fill: url(#linear-gradient-landing-65);
      }

      #landingsvg .cls-104 {
        fill: url(#linear-gradient-landing-66);
      }

      #landingsvg .cls-105 {
        fill: url(#linear-gradient-landing-67);
      }
</style>