<template>
<div id="automationsvg">
<svg  data-name="Automation" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1000 1000">
  <defs>
    <linearGradient id="linear-gradient-automation" x1="740.55173" y1="560.11551" x2="863.8556" y2="560.11551" gradientUnits="userSpaceOnUse">
      <stop offset="0.12729" stop-color="#739be0" stop-opacity="0"/>
      <stop offset="0.65022" stop-color="#5a92e0"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-2" x1="790.42809" y1="298.42214" x2="823.02973" y2="260.0473" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#4e69f2"/>
      <stop offset="0.72822" stop-color="#3c88f2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-3" x1="845.52319" y1="228.2503" x2="862.07325" y2="228.2503" gradientTransform="translate(768.13846 -651.37008) rotate(69.47075)" xlink:href="#linear-gradient-automation-2"/>
    <linearGradient id="linear-gradient-automation-4" x1="834.50306" y1="354.73655" x2="842.99307" y2="248.10202" xlink:href="#linear-gradient-automation-2"/>
    <linearGradient id="linear-gradient-automation-5" x1="781.91298" y1="304.84083" x2="806.34912" y2="304.84083" xlink:href="#linear-gradient-automation-2"/>
    <linearGradient id="linear-gradient-automation-6" x1="875.2696" y1="360.14467" x2="861.978" y2="253.81188" xlink:href="#linear-gradient-automation-2"/>
    <linearGradient id="linear-gradient-automation-7" x1="844.95217" y1="219.59011" x2="826.84014" y2="238.60773" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f29191"/>
      <stop offset="1" stop-color="#f9a49d"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-8" x1="728.36837" y1="334.38589" x2="730.40597" y2="207.03574" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#6f9ddd"/>
      <stop offset="0.80306" stop-color="#7bb8f5"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-9" x1="715.37342" y1="179.57587" x2="739.19687" y2="179.57587" gradientTransform="translate(-11.89024 57.8757) rotate(-4.52015)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f9a49d"/>
      <stop offset="1" stop-color="#f29191"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-10" x1="734.36645" y1="183.26581" x2="740.22087" y2="183.26581" xlink:href="#linear-gradient-automation-7"/>
    <linearGradient id="linear-gradient-automation-11" x1="765.92049" y1="318.02993" x2="758.7514" y2="227.96823" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#6f9ddd"/>
      <stop offset="0.77927" stop-color="#7bb8f5"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-12" x1="648.82716" y1="239.78987" x2="702.71793" y2="204.8795" xlink:href="#linear-gradient-automation-11"/>
    <linearGradient id="linear-gradient-automation-13" x1="451.95374" y1="213.09844" x2="493.2446" y2="213.09844" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#3769cc"/>
      <stop offset="1" stop-color="#6198e6"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-14" x1="629.55127" y1="379.94325" x2="423.75342" y2="248.17828" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#8fc7ff"/>
      <stop offset="1" stop-color="#9cdeff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-15" x1="591.51497" y1="383.51886" x2="385.03791" y2="240.43388" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#3a68aa"/>
      <stop offset="1" stop-color="#2f4d8a"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-16" x1="419.94751" y1="282.99398" x2="429.99109" y2="248.66317" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#4174d9"/>
      <stop offset="0.72822" stop-color="#5596f2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-17" x1="408.26456" y1="229.21416" x2="450.49862" y2="229.21416" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#73b9ff"/>
      <stop offset="1" stop-color="#9cdeff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-18" x1="241.55565" y1="458.8209" x2="525.30271" y2="458.8209" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#73b6e6"/>
      <stop offset="1" stop-color="#6ba9ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-19" x1="280.98834" y1="550.84301" x2="452.14695" y2="292.74669" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#375ccc"/>
      <stop offset="1" stop-color="#6cb1ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-20" x1="334.13304" y1="214.45081" x2="403.59394" y2="112.88063" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#80bfff"/>
      <stop offset="1" stop-color="#8ccfff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-21" x1="375.80538" y1="289.50924" x2="497.60873" y2="189.44031" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#8fc8ff"/>
      <stop offset="1" stop-color="#a8e2ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-22" x1="299.70774" y1="595.51958" x2="403.00712" y2="595.51958" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#6ba9ff"/>
      <stop offset="1" stop-color="#73b6e6"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-23" x1="195.79192" y1="596.22204" x2="299.70774" y2="596.22204" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#577ef2"/>
      <stop offset="1" stop-color="#579ef2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-24" x1="195.79169" y1="518.46541" x2="403.00236" y2="518.46541" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#619ef2"/>
      <stop offset="1" stop-color="#8ccfff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-25" x1="241.44864" y1="522.74324" x2="357.34543" y2="522.74324" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#2b4780"/>
      <stop offset="1" stop-color="#345e99"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-26" x1="244.05261" y1="501.58352" x2="354.74144" y2="501.58352" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#359"/>
      <stop offset="1" stop-color="#4874b3"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-27" x1="236.52508" y1="701.89257" x2="726.45529" y2="461.90827" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#73b9ff"/>
      <stop offset="0.99909" stop-color="#73ade6"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-28" x1="272.82305" y1="776.20044" x2="389.04358" y2="776.20044" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#4174d9"/>
      <stop offset="1" stop-color="#3d7fcc"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-29" x1="289.04768" y1="696.88592" x2="735.96183" y2="411.62157" xlink:href="#linear-gradient-automation-25"/>
    <linearGradient id="linear-gradient-automation-30" x1="335.99212" y1="749.59956" x2="786.98148" y2="454.82639" xlink:href="#linear-gradient-automation-26"/>
    <linearGradient id="linear-gradient-automation-31" x1="338.52518" y1="810.68414" x2="772.53452" y2="484.66774" xlink:href="#linear-gradient-automation-17"/>
    <linearGradient id="linear-gradient-automation-32" x1="374.39114" y1="809.21313" x2="787.34526" y2="542.9664" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#599fff"/>
      <stop offset="1" stop-color="#72c4ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-33" x1="652.90413" y1="478.1195" x2="662.94772" y2="443.78869" xlink:href="#linear-gradient-automation-16"/>
    <linearGradient id="linear-gradient-automation-34" x1="641.22119" y1="424.33969" x2="683.45525" y2="424.33969" xlink:href="#linear-gradient-automation-17"/>
    <linearGradient id="linear-gradient-automation-35" x1="558.05202" y1="532.16117" x2="568.09561" y2="497.83035" xlink:href="#linear-gradient-automation-16"/>
    <linearGradient id="linear-gradient-automation-36" x1="546.36908" y1="478.38135" x2="588.60314" y2="478.38135" xlink:href="#linear-gradient-automation-17"/>
    <linearGradient id="linear-gradient-automation-37" x1="463.19991" y1="586.20283" x2="473.2435" y2="551.87201" xlink:href="#linear-gradient-automation-16"/>
    <linearGradient id="linear-gradient-automation-38" x1="451.51697" y1="532.42301" x2="493.75103" y2="532.42301" xlink:href="#linear-gradient-automation-17"/>
    <linearGradient id="linear-gradient-automation-39" x1="353.64316" y1="625.08399" x2="401.41362" y2="591.35035" xlink:href="#linear-gradient-automation-16"/>
    <linearGradient id="linear-gradient-automation-40" x1="355.77714" y1="592.93852" x2="404.5098" y2="583.0901" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#58a7fc"/>
      <stop offset="1" stop-color="#70b3fc"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-41" x1="377.09389" y1="167.50464" x2="499.9439" y2="167.50464" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#4e77f2"/>
      <stop offset="1" stop-color="#3cb5f2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-42" x1="389.67124" y1="181.29989" x2="438.51887" y2="181.29989" xlink:href="#linear-gradient-automation-21"/>
    <linearGradient id="linear-gradient-automation-43" x1="844.71764" y1="233.15299" x2="851.42197" y2="233.15299" xlink:href="#linear-gradient-automation-7"/>
    <linearGradient id="linear-gradient-automation-44" x1="508.0515" y1="336.31229" x2="518.09509" y2="301.98148" xlink:href="#linear-gradient-automation-16"/>
    <linearGradient id="linear-gradient-automation-45" x1="496.36856" y1="282.53247" x2="538.60262" y2="282.53247" xlink:href="#linear-gradient-automation-17"/>
    <linearGradient id="linear-gradient-automation-46" x1="333.68454" y1="526.55114" x2="309.23331" y2="454.10305" gradientUnits="userSpaceOnUse">
      <stop offset="0.00503" stop-color="#f7ac63"/>
      <stop offset="0.99822" stop-color="#ffa466"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-47" x1="281.64445" y1="524.97694" x2="271.68284" y2="466.56566" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#e6935c"/>
      <stop offset="0.99497" stop-color="#ea895e"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-48" x1="529.13769" y1="463.45337" x2="354.58307" y2="287.54035" xlink:href="#linear-gradient-automation-46"/>
    <linearGradient id="linear-gradient-automation-49" x1="496.90263" y1="459.11764" x2="340.68643" y2="321.23987" xlink:href="#linear-gradient-automation-47"/>
    <linearGradient id="linear-gradient-automation-50" x1="362.14599" y1="490.43128" x2="313.24353" y2="319.27267" xlink:href="#linear-gradient-automation-46"/>
    <linearGradient id="linear-gradient-automation-51" x1="348.34385" y1="502.71228" x2="279.74457" y2="328.83687" xlink:href="#linear-gradient-automation-47"/>
    <linearGradient id="linear-gradient-automation-52" x1="447.54444" y1="513.91809" x2="474.02013" y2="513.91809" gradientUnits="userSpaceOnUse">
      <stop offset="0.00503" stop-color="#ea7e4e"/>
      <stop offset="1" stop-color="#e68c50"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-53" x1="488.77258" y1="483.3992" x2="459.56695" y2="439.25115" xlink:href="#linear-gradient-automation-46"/>
    <linearGradient id="linear-gradient-automation-54" x1="470.77452" y1="498.72131" x2="449.71929" y2="451.85645" gradientTransform="translate(293.49255 -165.18017) rotate(29.68934)" xlink:href="#linear-gradient-automation-52"/>
    <linearGradient id="linear-gradient-automation-55" x1="372.87323" y1="456.04612" x2="349.7804" y2="368.65561" xlink:href="#linear-gradient-automation-47"/>
    <linearGradient id="linear-gradient-automation-56" x1="475.61416" y1="526.06118" x2="501.40375" y2="526.06118" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ff9c59"/>
      <stop offset="0.99497" stop-color="#f7a656"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-57" x1="-14836.68838" y1="238.48064" x2="-14689.28322" y2="238.48064" gradientTransform="matrix(-1, 0, 0, 1, -14174.38168, 0)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#3cb5f2"/>
      <stop offset="1" stop-color="#4e77f2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-automation-58" x1="531.93184" y1="259.33324" x2="642.67933" y2="259.33324" xlink:href="#linear-gradient-automation-21"/>
  </defs>
  <g>
    <path class="cls-1" d="M790.63681,468.54505,290.407,550.60142a45.47272,45.47272,0,0,0-14.48306,5.34206L48.88048,687.1276a22.64,22.64,0,0,0,.00206,39.20735L355.19659,903.27819a67.92009,67.92009,0,0,0,67.75688.10929l528.10068-302.791a22.64,22.64,0,0,0,.07286-39.23944Z"/>
    <polygon class="cls-2" points="202.898 598.137 676.174 669.168 362.917 874.037 302.836 874.037 136.794 778.249 288.821 690.465 168.364 618.091 202.898 598.137"/>
    <g>
      <g>
        <polygon class="cls-3" points="842.648 584.113 740.552 584.134 740.552 536.097 814.833 536.767 818.957 553.282 863.856 562.089 842.648 584.113"/>
        <path class="cls-4" d="M832.49075,539.5389s2.02641,8.93115-.64061,11.45338c-2.46443,2.33065-6.45462,1.33774-10.77711,1.95736-7.74807,1.11066-10.54756,2.54229-18.56056,2.75123-5.69254.14844-9.88821-6.89968-3.9628-9.0289,3.69417-1.32745,16.74488-8.09651,16.74488-8.09651Z"/>
        <path class="cls-5" d="M800.16158,354.88977c-2.45525,33.16525,5.35478,105.08054,15.07109,184.91185,1.537,6.84171,17.67344,4.56908,17.58206.26879l1.35943-160.69286Z"/>
        <path class="cls-4" d="M866.72636,555.47683a40.81166,40.81166,0,0,1,.789,9.55605c-.171,1.90114-.77761,4.661-6.23695,7.28886-6.09266,2.93272-6.67556,10.98482-18.63019,11.791-6.4041.43186-11.27272-5.64808-7.35177-10.44874,3.96059-4.8492,16.04147-20.10611,16.04147-20.10611Z"/>
        <path class="cls-6" d="M830.327,243.77c-7.47479-1.88225-19.65284,2.51152-25.73535,15.30039L785.6603,293.64064l17.55545,14.03724,21.15152-26.01564Z"/>
        <polygon class="cls-7" points="806.632 277.885 804.208 296.62 809.416 295.513 806.632 277.885"/>
        <g>
          <path class="cls-8" d="M869.15751,387.51044l-33.43535,3.72641-35.56058-36.34708c.55853-8.91879,1.91025-16.17347,2.97071-23.85893l21.37625-11.06426,41.02352,22.66978C867.72177,353.32561,869.85774,365.656,869.15751,387.51044Z"/>
          <path class="cls-7" d="M864.88886,341.93483l1.8508,10.87757c.84526,3.82007-15.72353,10.84744-29.17055,10.86533-28.46256.03787-37.82027-15.11256-35.68879-27.84943l1.40365-7.56415Z"/>
          <path class="cls-9" d="M851.79027,237.78166c-2.30179-6.81046,2.18486-13.13033,9.36676-13.39088,0,0,11.03491.42948,15.16371,9.40287,3.82146,8.30542-.13788,12.76262,3.15968,21.00073,1.32037,3.29859,10.19421,9.28485,12.96673,14.63547,3.28437,6.3384,1.87639,20.81839-9.969,27.78427-9.515,5.59549-21.31194,3.47327-28.005.096-7.31-4.34869-10.04443-8.9906-11.034-18.58755-.70909-6.87715,3.58252-17.54048,6.15646-22.884C855.78053,242.9987,851.79027,237.78166,851.79027,237.78166Z"/>
          <ellipse class="cls-10" cx="853.79822" cy="228.2503" rx="9.16224" ry="8.14042" transform="translate(340.62871 947.78255) rotate(-69.47075)"/>
          <path class="cls-11" d="M866.68,339.33744c.86018,6.31769-14.97879,13.923-25.13344,14.89235-17.33559,1.10059-38.33085-3.26407-38.33084-27.82089l6.85544-20.92068c-3.62526-15.87916-5.66786-36.10878.11742-49.723a20.63027,20.63027,0,0,1,14.11525-12.23738c18.31188-5.4722,44.86277,15.09464,50.86672,32.1772L862.7054,323.5171Z"/>
        </g>
        <path class="cls-12" d="M785.6603,293.64064c-8.42194,13.25675-1.631,24.09609,10.27969,22.17988l3.84242-.85715c6.07493-1.19861,10.51984-12.735,1.24012-19.06128Z"/>
        <path class="cls-8" d="M828.74371,382.342l19.2036,174.61713c.93375,6.65448,19.072,4.7577,18.94632-1.15791l2.26388-168.29082Z"/>
        <g>
          <path class="cls-13" d="M857.28505,354.13186l-5.461,4.93952.964,20.61108a1.64213,1.64213,0,0,0,2.19,1.49338l4.93951-1.74434a4.09683,4.09683,0,0,0,2.64211-3.02819l1.94061-14.63663Z"/>
          <path class="cls-14" d="M856.19894,371.01133,855.916,380.845l-.7736.28305.30193-10.1356a.40558.40558,0,0,1,.39625-.35858A.35164.35164,0,0,1,856.19894,371.01133Z"/>
          <path class="cls-14" d="M858.84033,370.50189l-.26417,9.39977-.77348.28305.28293-9.7017a.37948.37948,0,0,1,.39624-.35836A.3627.3627,0,0,1,858.84033,370.50189Z"/>
          <path class="cls-14" d="M861.34963,370.03023l-.22639,8.72059a2.99471,2.99471,0,0,1-.7736.49055l.24527-9.23a.37641.37641,0,0,1,.39625-.35836A.35156.35156,0,0,1,861.34963,370.03023Z"/>
          <path class="cls-13" d="M851.824,359.07138l-2.02339,2.5715a6.92243,6.92243,0,0,0-1.45221,3.63682l-.39338,4.21106a1.38446,1.38446,0,0,0,1.158,1.49555h0a1.38444,1.38444,0,0,0,1.5276-.91066l1.72883-4.95444Z"/>
        </g>
        <path class="cls-15" d="M875.17058,275.705,891.27794,325.899a16.27331,16.27331,0,0,1-4.85334,17.284l-22.13389,19.828c-2.97275,2.03494-11.80236-4.53429-9.85128-8.62752L868.87069,330.111l-16.8381-43.45746C846.94326,275.15918,870.94568,260.2937,875.17058,275.705Z"/>
      </g>
      <path class="cls-7" d="M849.13644,257.21793c-4.94759,5.4934-25.44115,12.66571-25.44115,12.66571s-4.11113-18.13419,2.24453-25.4189c3.2267-3.69838,6.71927-2.87321,13.571-.69887S853.89037,251.93955,849.13644,257.21793Z"/>
      <path class="cls-16" d="M846.47053,254.14c-3.61341,4.012-19.31524,9.42735-19.31524,9.42735s-2.26789-13.42118,2.37388-18.74148c2.35658-2.70106,4.90733-2.09841,9.9114-.51041S849.9425,250.285,846.47053,254.14Z"/>
      <path class="cls-17" d="M846.61444,251.429c-1.235,4.60724-10.35647,6.25661-15.90075,3.829-2.57837-1.129-2.56089-6.34825-.15513-9.40075a8.5623,8.5623,0,0,1,10.7813-1.97328C845.169,246.01943,847.62072,247.67491,846.61444,251.429Z"/>
      <path class="cls-17" d="M829.94986,250.02716c-1.115,5.81647,8.20368,5.2966,15.71932,0l-.00006-18.767-12.3965,1.89282Z"/>
      <path class="cls-18" d="M834.8347,244.95659c-6.17283.55387-9.17479-7.03086-8.66174-12.33l1.62361-12.98264c1.013-5.79671,7.17966-9.6816,12.97638-8.66862h0a10.655,10.655,0,0,1,8.66172,12.33005l-3.229,12.46959C845.19276,241.57164,841.28153,244.37813,834.8347,244.95659Z"/>
      <ellipse class="cls-19" cx="797.59793" cy="304.76209" rx="6.54519" ry="7.98959" transform="translate(-19.54691 57.14001) rotate(-4.0533)"/>
    </g>
    <g>
      <g>
        <path class="cls-9" d="M715.13067,479.61465s1.299,10.36857-1.52475,12.92763c-2.6093,2.36467-8.13531,1.90932-11.53309,2.2491-8.08561.80856-10.78785,3.13739-19.10308,2.81257-7.24858-.28314-9.87807-8.9913-3.71865-11.1796,7.75826-2.75631,17.24372-8.79174,17.24372-8.79174Z"/>
        <path class="cls-20" d="M691.88764,310.64286c-1.27588,59.1238.92274,114.5258,4.23489,167.41687.21564,5.28322,19.17574,5.07458,19.17574.92949l11.66661-146.39689Z"/>
        <path class="cls-9" d="M753.19231,503.202s1.27279,6.48106.05817,10.26051c-.57855,1.80021-1.64762,3.81946-7.138,6.32-6.12731,2.79065-6.71383,8.07639-15.52653,10.22911-7.41848,1.81214-16.20438-6.116-10.5131-11.04278,5.99936-5.19347,16.22784-16.53584,16.22784-16.53584Z"/>
        <polygon class="cls-21" points="756.275 335.426 732.838 348.11 694.725 310.569 695.426 293.765 756.275 312.676 756.275 335.426"/>
        <path class="cls-21" d="M715.91843,330.07915l18.13372,174.90566c.328,5.34151,19.9649,4.77226,19.79209,0l2.43044-169.5591Z"/>
        <path class="cls-22" d="M718.16255,194.32716c-7.99006-.00916-17.88153,6.7176-29.07861,15.64445l-17.94261,16.42057,5.38319,10.30516L711.44273,230.43Z"/>
        <path class="cls-23" d="M758.81448,312.59257c.55885,15.13691-14.08359,19.88616-31.27854,20.78454-11.814.61725-38.20453-3.7415-37.73731-23.61277,0,0,6.312-94.56839,7.16156-98.71135,3.45451-16.84534,20.1937-16.68361,20.1937-16.68361,20.13555-1.29778,48.11375,20.18006,51.81891,37.509l-4.60877,3.407-8.79019,42.56394Z"/>
        <path class="cls-22" d="M749.57294,254.19684l1.88428,32.85775-9.427,44.27942c11.35143-2.50857,20.75317-11.83248,21.19985-18.583l-4.41481-35.20524Z"/>
        <path class="cls-24" d="M725.72712,303.90271l40.97462-20.643a2.31592,2.31592,0,0,1,3.35769,2.036l.46194,33.18132a4.34392,4.34392,0,0,1-2.38907,3.93989L727.15768,343.06a2.31591,2.31591,0,0,1-3.35768-2.036l-.46194-33.18133A4.34393,4.34393,0,0,1,725.72712,303.90271Z"/>
        <polygon class="cls-20" points="711.529 333.432 709.426 302.821 703.065 330.099 711.529 333.432"/>
        <polygon class="cls-16" points="727.43 198.83 718.847 205.04 717.743 218.467 731.06 209.904 742.03 202.511 727.43 198.83"/>
        <path class="cls-25" d="M719.82452,196.59218c-4.43568,3.16261-10.5777,11.76-10.5777,11.76l6.53533-2.007L726.282,193.02539A24.04342,24.04342,0,0,0,719.82452,196.59218Z"/>
        <path class="cls-17" d="M736.70519,205.205c-4.06233,4.74726-15.42731,6.69056-15.28362,2.34511l.476-15.42888,15.31722-.71532Z"/>
        <path class="cls-25" d="M727.71094,211.54889l5.30264,7.78452c6.32823-5.50827,9.14789-11.13921,9.63044-17.73033l-5.55964-6.665C736.56357,200.37312,732.9363,206.34948,727.71094,211.54889Z"/>
        <path class="cls-26" d="M713.51892,166.51113c-2.25857,6.87015,1.25422,11.26749,2.69494,15.52464l1.21362-9.687Z"/>
        <path class="cls-27" d="M727.28513,161.19081h0a11.35727,11.35727,0,0,1,11.35727,11.35727v14.05558a11.35727,11.35727,0,0,1-11.35727,11.35727h0a11.35727,11.35727,0,0,1-11.35727-11.35727V172.54808a11.35728,11.35728,0,0,1,11.35728-11.35728Z" transform="translate(16.41442 -56.75862) rotate(4.52015)"/>
        <g>
          <path class="cls-26" d="M713.07113,166.72244c-.57409,5.12026,3.389,5.9341,11.39187,11.54234a12.47735,12.47735,0,0,0,10.53429,1.48373c.67667,4.47686,1.30176,11.49547,1.53029,14.89387,3.69329-5.92791,9.943-8.413,11.78389-18.07116,1.0382-5.44718-.31421-11.66866-7.69782-18.12841S714.20635,156.59748,713.07113,166.72244Z"/>
          <path class="cls-28" d="M734.99729,179.74851s2.52974-2.15629,4.36864-.02216c1.25223,1.45326,1.33851,5.25089-1.17222,7.33272-1.51065,1.2526-3.82726.41446-3.82726.41446Z"/>
          <path class="cls-26" d="M735.00988,179.70755l-.87733,5.17283c-.39207,1.11788-2.41213,1.34007-2.50146-.08934l-.2833-5.27086Z"/>
        </g>
        <polygon class="cls-22" points="733.014 219.333 726.889 215.637 719.646 217.243 727.711 211.549 733.014 219.333"/>
        <polygon class="cls-22" points="717.916 216.731 715.804 205.742 709.247 208.352 713.495 209.006 717.916 216.731"/>
        <path class="cls-25" d="M721.37149,203.42139c-2.131,3.74544-3.17459,9.85884-3.49412,13.41086l-2.09522-10.487,5.46482-7.251Z"/>
        <path class="cls-13" d="M760.38882,319.66773l-2.98941,8.87284a3.00135,3.00135,0,0,1-1.14593,1.56162c-.31276.21128-.72345.486-1.17334.78215-.312.23117-.64494.443-.99631.69564l-.02.00071c-.74346.48671-1.5247,1.03462-2.32679,1.56336l-.01918.02071c-.33359.192-.64635.40314-.95993.59442-.97841.635-1.91892,1.20871-2.6652,1.61538-.35349.19256-.66766.36382-.924.47293l-.02.00071a1.77017,1.77017,0,0,1-.61338.20185c-1.33942.04729-2.53472-1.4919-2.53472-1.4919l1.70892-.921,1.78742-6.0681-1.6791-.50112-1.89563,1.86835c-1.31637,1.26758-2.9146.223-2.2394-1.0619l3.39924-6.90556a5.69729,5.69729,0,0,1,1.573-1.9371l3.83538-3.05789Z"/>
        <path class="cls-14" d="M751.61312,328.57717a73.72511,73.72511,0,0,1-3.46492,6.74783c-.35348.19256-.66765.36382-.924.47293a83.325,83.325,0,0,0,3.85854-7.50234.28286.28286,0,0,1,.41622-.11475A.28683.28683,0,0,1,751.61312,328.57717Z"/>
        <path class="cls-14" d="M754.27711,328.06272a48.12237,48.12237,0,0,1-2.48459,5.03177l-.01918.02071c-.3336.192-.64635.40314-.95994.59442a45.06716,45.06716,0,0,0,2.934-5.90846.29786.29786,0,0,1,.39562-.13407A.28562.28562,0,0,1,754.27711,328.06272Z"/>
        <path class="cls-14" d="M756.7363,327.41537c-.46026,1.13718-1.00045,2.2773-1.60067,3.41942-.312.23117-.64494.443-.99631.69563a39.57729,39.57729,0,0,0,2.04741-4.37579.31537.31537,0,0,1,.41622-.11487A.29268.29268,0,0,1,756.7363,327.41537Z"/>
        <path class="cls-29" d="M769.36616,233.40054l10.34816,40.75079a23.47043,23.47043,0,0,1-1.916,16.58728l-14.87408,28.66292c-2.01486,4.9525-17.84226,1.56726-14.42245-7.59113L758.702,281.16818l-13.69722-40.52565C740.59,225.24731,764.75824,213.1066,769.36616,233.40054Z"/>
      </g>
      <path class="cls-30" d="M666.47007,215.69063c-13.61017,11.25468,3.03025,28.0083,13.48053,19.00646l28.69414-26.70645c4.63877-5.73631-6.54278-19.47127-15.03279-12.317Z"/>
      <ellipse class="cls-22" cx="701.49281" cy="201.687" rx="4.96559" ry="6.97074" transform="translate(21.82935 467.82115) rotate(-37.42423)"/>
    </g>
    <polygon class="cls-31" points="451.954 213.093 493.245 236.933 493.245 189.264 451.954 213.093"/>
    <g>
      <path class="cls-32" d="M367.53991,186.79734l15.21409-8.80008a8.41039,8.41039,0,0,1,8.45041.02165L638.49652,320.79638a25.48048,25.48048,0,0,1,12.73965,22.044l.0508,97.73171-14.93169,8.63367Z"/>
      <path class="cls-33" d="M636.35528,550.09967l-.05083-198.62561a25.48033,25.48033,0,0,0-12.73957-22.044L376.2727,186.65271a8.49344,8.49344,0,0,0-12.74035,7.35542V392.68167Z"/>
    </g>
    <polygon class="cls-33" points="566.789 346.579 443.939 417.415 583.485 497.785 706.335 427.19 566.789 346.579"/>
    <path class="cls-34" d="M353.67185,169.40735c4.53491-2.20951,10.28441-1.33148,16.03948,1.99343L617.42974,314.51592c6.05678,3.46272,12.11256,9.59835,16.72255,17.583,9.19011,15.9178,9.15406,33.14378-.08046,38.47527L532.1261,429.47573,251.00362,228.6456Z"/>
    <polygon class="cls-35" points="373.135 291.179 475.118 232.299 473.233 231.21 371.25 290.09 373.135 291.179"/>
    <g>
      <path class="cls-36" d="M457.79264,234.62675H400.97051v31.77217h.0024a3.16191,3.16191,0,0,0,1.59339,2.74791L424.588,281.8776a9.584,9.584,0,0,0,9.58871.00273l22.01855-12.71224a3.16454,3.16454,0,0,0,1.59709-2.76917h.00026Z"/>
      <path class="cls-37" d="M402.56853,231.84184l22.005-12.69754a9.5841,9.5841,0,0,1,9.58-.00006l22.04106,12.71793a3.1947,3.1947,0,0,1,.00068,5.5338l-22.01857,12.71221a9.5841,9.5841,0,0,1-9.5887-.00273l-22.02171-12.73074A3.1947,3.1947,0,0,1,402.56853,231.84184Z"/>
      <path class="cls-38" d="M429.36351,219.99106a7.46319,7.46319,0,0,1,3.72563.99791l22.04106,12.718a1.06479,1.06479,0,0,1,.00012,1.84448L433.1118,248.26367a7.45493,7.45493,0,0,1-7.45794-.002l-22.02162-12.73108a1.06467,1.06467,0,0,1,.00074-1.844L425.63813,220.989a7.46227,7.46227,0,0,1,3.72538-.99791m0-2.12981a9.57761,9.57761,0,0,0-4.78991,1.283l-22.005,12.69764a3.19458,3.19458,0,0,0-.00223,5.53272L424.588,250.10543a9.58426,9.58426,0,0,0,9.58875.00273l22.01852-12.71226a3.1946,3.1946,0,0,0-.00062-5.53371l-22.04106-12.718a9.57776,9.57776,0,0,0-4.79-1.283Z"/>
      <g>
        <path class="cls-37" d="M450.49861,229.21413H408.26455v9.00222l17.55414,10.12912a7.12352,7.12352,0,0,0,7.127.002l17.55294-10.11859Z"/>
        <path class="cls-39" d="M409.45231,227.14419l16.35563-9.43767a7.12358,7.12358,0,0,1,7.1205,0l16.3824,9.45282a2.37452,2.37452,0,0,1,.00051,4.1131L432.94566,240.721a7.12353,7.12353,0,0,1-7.127-.002l-16.368-9.46235A2.37452,2.37452,0,0,1,409.45231,227.14419Z"/>
      </g>
    </g>
    <path class="cls-35" d="M250.73722,229.66408c4.63429-2.6755,10.70441-1.84367,16.76377,1.65748L515.21781,374.4357c6.05678,3.46272,12.11255,9.59836,16.72254,17.58305,9.19011,15.9178,9.15407,33.14377-.08065,38.47546-4.63429,2.67551-10.7044,1.84368-16.76376-1.65747L267.37732,285.7216h0c-6.05618-3.46292-12.11116-9.59815-16.72075-17.58205C241.46646,252.22175,241.50251,234.99577,250.73722,229.66408Z"/>
    <ellipse class="cls-40" cx="513.9617" cy="403.99033" rx="12.57813" ry="21.83294" transform="translate(-133.13735 311.10529) rotate(-30)"/>
    <path class="cls-40" d="M256.90543,233.7434h-.00049a16.14528,16.14528,0,0,1,7.76465,2.479l247.73926,143.127c5.38916,3.08129,10.72168,8.73046,14.62988,15.49926,3.89648,6.749,6.12354,14.17188,6.11084,20.36573-.01074,5.09008-1.51269,8.8728-4.12012,10.37793a6.49837,6.49837,0,0,1-3.3374.82251,16.1452,16.1452,0,0,1-7.76465-2.479L270.18668,280.8081c-5.38867-3.08106-10.7207-8.73023-14.62842-15.4983-7.9043-13.69067-7.56982-27.52294-1.99072-30.74389a6.49889,6.49889,0,0,1,3.33789-.82251m-.00049-5.66016a12.17356,12.17356,0,0,0-6.16748,1.58081c-9.23486,5.33179-9.271,22.55762-.08105,38.47559,4.60986,7.98389,10.66455,14.11914,16.7207,17.582l247.71875,143.115a21.61706,21.61706,0,0,0,10.59619,3.23828,12.17343,12.17343,0,0,0,6.16748-1.58081c9.23486-5.33154,9.271-22.55762.08106-38.47534-4.61036-7.98462-10.666-14.12036-16.72266-17.583L267.50113,231.32152a21.617,21.617,0,0,0-10.59619-3.23828Z"/>
    <path class="cls-41" d="M241.55565,259.6552l15.21409-8.80007a8.41039,8.41039,0,0,1,8.45041.02164L512.51226,393.65425a25.48046,25.48046,0,0,1,12.73965,22.044l.0508,243.58257L510.371,667.91447Z"/>
    <path class="cls-42" d="M510.3202,424.33191a25.48057,25.48057,0,0,0-12.73951-22.044l-120.4868-69.5647,2.27072-97.66265L237.54809,153.38188V510.49663l272.823,157.418Z"/>
    <path class="cls-43" d="M348.46314,89.42817l-110.915,63.95371,141.81652,81.67869L499.9439,174.29319l-2.41062-6.81119L362.00993,89.425A13.56559,13.56559,0,0,0,348.46314,89.42817Z"/>
    <path class="cls-44" d="M493.35526,167.08625a7.207,7.207,0,0,0-.85306.34776l-108.709,62.47311a13.341,13.341,0,0,0-6.69926,11.57013v91.14141l6.69926-4.42971V252.43016L493.2446,189.26359V236.9332l6.69926,3.86791v-69.0606A4.94267,4.94267,0,0,0,493.35526,167.08625Z"/>
    <ellipse class="cls-37" cx="389.69272" cy="239.19833" rx="4.44155" ry="2.55881" transform="translate(-12.30547 457.08296) rotate(-60)"/>
    <ellipse class="cls-37" cx="399.82115" cy="232.89195" rx="4.44155" ry="2.55881" transform="translate(-1.77977 462.70124) rotate(-60)"/>
    <ellipse class="cls-37" cx="409.75847" cy="227.06488" rx="4.44155" ry="2.55881" transform="matrix(0.5, -0.86603, 0.86603, 0.5, 8.23529, 468.39368)"/>
    <g>
      <g>
        <path class="cls-45" d="M402.79518,517.1642l.20741-.10371v94.13337a9.87718,9.87718,0,0,1-4.97348,8.91054L308.72136,671.596a18.02576,18.02576,0,0,1-9.01362,2.38272v-97.2414Z"/>
        <path class="cls-46" d="M299.70774,576.73727v97.2414a18.60961,18.60961,0,0,1-9.01361-2.38272l-89.92936-51.90637a10.09265,10.09265,0,0,1-4.97285-8.49572V518.46541Z"/>
        <path class="cls-47" d="M397.99126,509.99129l-89.93-51.92108a18.03994,18.03994,0,0,0-18.04,0l-89.21853,51.51033a10.02222,10.02222,0,0,0,0,17.359l89.93,51.92109a18.04,18.04,0,0,0,18.04,0l89.21852-51.51033A10.02222,10.02222,0,0,0,397.99126,509.99129Z"/>
      </g>
      <g>
        <path class="cls-48" d="M356.9,509.61169l.01969.00933-2.47794-11.39155H244.35219L241.87464,509.621l.01944-.00972a19.84875,19.84875,0,0,0-.44544,4.09651c0,18.52873,25.94442,33.5492,57.9484,33.5492s57.94839-15.02047,57.94839-33.5492A19.85462,19.85462,0,0,0,356.9,509.61169Z"/>
        <ellipse class="cls-49" cx="299.39703" cy="501.58352" rx="55.34442" ry="32.0415"/>
      </g>
    </g>
    <g>
      <path class="cls-50" d="M674.10052,408.46067l-15.21409-8.80008a8.41039,8.41039,0,0,0-8.45041.02165L234.791,639.66975a25.48046,25.48046,0,0,0-12.73965,22.044l-.0508,130.83169,14.93169,8.63366Z"/>
      <path class="cls-51" d="M236.93225,801.17915l.05083-130.83173a25.48034,25.48034,0,0,1,12.73957-22.044L665.36773,408.316a8.49344,8.49344,0,0,1,12.74035,7.35542V546.55112Z"/>
      <polygon class="cls-52" points="272.823 704.862 272.823 780.602 389.044 847.539 389.044 771.999 272.823 704.862"/>
    </g>
    <g>
      <line class="cls-53" x1="632.81882" y1="378.72294" x2="674.10052" y2="425.55842"/>
      <line class="cls-53" x1="620.31017" y1="386.06627" x2="661.59186" y2="432.90175"/>
      <line class="cls-53" x1="607.80151" y1="393.4096" x2="649.08321" y2="440.24508"/>
      <line class="cls-53" x1="595.29285" y1="400.75293" x2="636.57455" y2="447.58841"/>
      <line class="cls-53" x1="582.7842" y1="408.09627" x2="624.06589" y2="454.93175"/>
      <line class="cls-53" x1="570.27554" y1="415.4396" x2="611.55724" y2="462.27508"/>
      <line class="cls-53" x1="557.76689" y1="422.78293" x2="599.04858" y2="469.61841"/>
      <line class="cls-53" x1="545.25823" y1="430.12626" x2="586.53992" y2="476.96174"/>
    </g>
    <path class="cls-54" d="M687.96858,391.07068c-4.53491-2.20951-10.28441-1.33148-16.03948,1.99343L255.85779,633.38928c-6.05678,3.46272-12.11256,9.59836-16.72255,17.58305-9.19011,15.9178-9.15406,33.14377.08046,38.47526l101.94573,58.9015L790.63681,450.30893Z"/>
    <path class="cls-35" d="M790.63681,450.30893c-4.63429-2.67551-10.7044-1.84368-16.76377,1.65747L357.80332,692.29058c-6.05677,3.46272-12.11255,9.59835-16.72254,17.583-9.19011,15.9178-9.15407,33.14378.08065,38.47547,4.63429,2.6755,10.7044,1.84368,16.76377-1.65748L773.99671,506.36644h0c6.05618-3.46292,12.11116-9.59815,16.72075-17.58205C799.90757,472.86659,799.87153,455.64062,790.63681,450.30893Z"/>
    <ellipse class="cls-55" cx="772.124" cy="482.16653" rx="21.83294" ry="12.57813" transform="translate(-31.50646 909.76227) rotate(-60)"/>
    <ellipse class="cls-56" cx="359.30208" cy="722.28568" rx="21.83294" ry="12.57813" transform="translate(-445.86671 672.30756) rotate(-60)"/>
    <path class="cls-57" d="M784.4693,454.38826a6.5,6.5,0,0,1,3.33737.8222c5.57917,3.22129,5.91311,17.05372-1.99077,30.74381-3.90772,6.768-9.23977,12.41719-14.65013,15.51113L355.09354,741.79092a16.14625,16.14625,0,0,1-7.76467,2.47878,6.5,6.5,0,0,1-3.33736-.82219c-5.57929-3.22129-5.91312-17.05349,1.991-30.74381,3.908-6.76894,9.24058-12.41812,14.65174-15.51205l416.0704-240.3246a16.14624,16.14624,0,0,1,7.76466-2.47879m.00012-5.66a21.617,21.617,0,0,0-10.5964,3.2381L357.80332,692.2905c-6.05683,3.46289-12.1125,9.59847-16.72254,17.58319-9.19014,15.91762-9.1541,33.14361.08061,38.47543a12.17455,12.17455,0,0,0,6.16748,1.58059,21.61689,21.61689,0,0,0,10.59628-3.2381L773.9967,506.36655c6.05613-3.46312,12.11111-9.59825,16.72081-17.582,9.19-15.91785,9.154-33.14384-.08072-38.47566a12.17417,12.17417,0,0,0-6.16737-1.58059Z"/>
    <g>
      <path class="cls-58" d="M800.08478,481.31853l-15.21409-8.80007a8.41039,8.41039,0,0,0-8.45041.02164l-415.645,239.98751a25.48045,25.48045,0,0,0-12.73964,22.044l-.05081,130.83168,14.93169,8.63367Z"/>
      <path class="cls-59" d="M362.91651,874.037l.05083-130.83174a25.48034,25.48034,0,0,1,12.73957-22.04395L791.352,481.1739a8.49344,8.49344,0,0,1,12.74035,7.35542V619.409Z"/>
    </g>
    <path class="cls-13" d="M697.06261,197.26934l7.37927-7.43166A4.4624,4.4624,0,0,1,709.254,188.834l3.0575,1.213.31808,9.54395-3.38275,4.94072-4.379-.7075-1.81394.88077a3.58452,3.58452,0,0,1-2.18462.34187C696.93721,204.33688,695.52731,199.48834,697.06261,197.26934Z"/>
    <path class="cls-17" d="M712.133,194.33382l-3.69058-2.50157a.38166.38166,0,0,1-.13257-.16964.39383.39383,0,0,1,.03234-.3613.36862.36862,0,0,1,.51348-.093l3.65863,2.47427Z"/>
    <path class="cls-17" d="M710.38972,197.29649l-3.772-2.54933a.55894.55894,0,0,1-.1499-.1621.38915.38915,0,0,1,.04967-.36883.35352.35352,0,0,1,.51348-.093l3.73992,2.52182Z"/>
    <path class="cls-17" d="M708.684,200.20239l-3.65122-2.45665a.49376.49376,0,0,1-.13258-.16963.37838.37838,0,0,1,.56333-.46143l3.60177,2.43645Z"/>
    <path class="cls-35" d="M354.33239,694.56947l-101.97791-58.8772c.59914-.44311,1.17468-.85213,1.70323-1.19391l101.97946,58.87831Z"/>
    <polygon class="cls-35" points="436.135 647.046 334.212 588.202 336.036 587.078 438.02 645.957 436.135 647.046"/>
    <polygon class="cls-35" points="518.172 599.661 416.19 540.78 418.075 539.692 520.057 598.572 518.172 599.661"/>
    <polygon class="cls-35" points="600.212 552.274 498.23 493.394 500.115 492.305 602.097 551.185 600.212 552.274"/>
    <polygon class="cls-35" points="682.251 504.888 580.267 446.009 582.152 444.92 684.136 503.799 682.251 504.888"/>
    <polygon class="cls-35" points="764.289 457.502 662.307 398.622 664.192 397.533 766.174 456.413 764.289 457.502"/>
    <g>
      <path class="cls-60" d="M690.74927,429.75228H633.92713v31.77217h.00241a3.16194,3.16194,0,0,0,1.59338,2.74791l22.02174,12.73077a9.584,9.584,0,0,0,9.58871.00273l22.01855-12.71225a3.16458,3.16458,0,0,0,1.59709-2.76916h.00026Z"/>
      <path class="cls-37" d="M635.52516,426.96736l22.005-12.69753a9.58411,9.58411,0,0,1,9.58-.00007l22.04105,12.71793a3.1947,3.1947,0,0,1,.00069,5.5338L667.13335,445.2337a9.5841,9.5841,0,0,1-9.5887-.00273l-22.02171-12.73074A3.1947,3.1947,0,0,1,635.52516,426.96736Z"/>
      <path class="cls-38" d="M662.32014,415.11659a7.46312,7.46312,0,0,1,3.72562.99791l22.04107,12.71795a1.0648,1.0648,0,0,1,.00012,1.84449L666.06843,443.3892a7.4549,7.4549,0,0,1-7.45794-.002l-22.02162-12.73108a1.06467,1.06467,0,0,1,.00074-1.844l22.00515-12.69764a7.46224,7.46224,0,0,1,3.72538-.99791m0-2.12982a9.57775,9.57775,0,0,0-4.78992,1.283l-22.005,12.69765a3.19457,3.19457,0,0,0-.00223,5.53271L657.54459,445.231a9.58427,9.58427,0,0,0,9.58874.00272l22.01853-12.71226a3.1946,3.1946,0,0,0-.00062-5.5337l-22.04106-12.718a9.57786,9.57786,0,0,0-4.79-1.283Z"/>
      <g>
        <path class="cls-37" d="M683.45524,424.33965H641.22118v9.00222L658.77532,443.471a7.12353,7.12353,0,0,0,7.127.002l17.553-10.11859Z"/>
        <path class="cls-61" d="M642.40894,422.26972l16.35563-9.43767a7.12354,7.12354,0,0,1,7.12049,0l16.38241,9.45283a2.37451,2.37451,0,0,1,.00051,4.11309l-16.3657,9.44858a7.12358,7.12358,0,0,1-7.127-.002l-16.368-9.46235A2.37452,2.37452,0,0,1,642.40894,422.26972Z"/>
      </g>
    </g>
    <g>
      <path class="cls-62" d="M595.89716,483.79394H539.075v31.77217h.00241a3.16192,3.16192,0,0,0,1.59338,2.74791l22.02174,12.73077a9.584,9.584,0,0,0,9.58871.00273l22.01855-12.71224a3.16458,3.16458,0,0,0,1.59709-2.76917h.00026Z"/>
      <path class="cls-37" d="M540.67305,481.009l22.005-12.69753a9.58411,9.58411,0,0,1,9.58-.00006l22.04106,12.71793a3.1947,3.1947,0,0,1,.00068,5.5338l-22.01857,12.7122a9.5841,9.5841,0,0,1-9.5887-.00273l-22.02171-12.73074A3.1947,3.1947,0,0,1,540.67305,481.009Z"/>
      <path class="cls-38" d="M567.468,469.15825a7.46319,7.46319,0,0,1,3.72563.99791l22.04106,12.71795a1.0648,1.0648,0,0,1,.00012,1.84449l-22.01852,12.71226a7.45493,7.45493,0,0,1-7.45794-.002l-22.02162-12.73109a1.06467,1.06467,0,0,1,.00074-1.844l22.00515-12.69764a7.46224,7.46224,0,0,1,3.72538-.99791m0-2.12982a9.57766,9.57766,0,0,0-4.78992,1.283l-22.005,12.69764a3.19457,3.19457,0,0,0-.00223,5.53271l22.02162,12.73084a9.58429,9.58429,0,0,0,9.58875.00272l22.01852-12.71226a3.19459,3.19459,0,0,0-.00062-5.5337l-22.04106-12.718a9.57777,9.57777,0,0,0-4.79-1.283Z"/>
      <g>
        <path class="cls-37" d="M588.60313,478.38132H546.36907v9.00222l17.55414,10.12912a7.1235,7.1235,0,0,0,7.127.002l17.553-10.11859Z"/>
        <path class="cls-63" d="M547.55683,476.31138l16.35563-9.43767a7.12356,7.12356,0,0,1,7.12049-.00005l16.38241,9.45283a2.37452,2.37452,0,0,1,.00051,4.1131l-16.36569,9.44857a7.12356,7.12356,0,0,1-7.127-.002l-16.368-9.46235A2.37452,2.37452,0,0,1,547.55683,476.31138Z"/>
      </g>
    </g>
    <g>
      <path class="cls-64" d="M501.04505,537.8356H444.22291v31.77217h.00241a3.16192,3.16192,0,0,0,1.59338,2.74791l22.02174,12.73077a9.584,9.584,0,0,0,9.58871.00273l22.01855-12.71224a3.16454,3.16454,0,0,0,1.59709-2.76917h.00026Z"/>
      <path class="cls-37" d="M445.82094,535.05069l22.005-12.69754a9.5841,9.5841,0,0,1,9.58-.00006L499.447,535.071a3.1947,3.1947,0,0,1,.00068,5.5338L477.42913,553.317a9.5841,9.5841,0,0,1-9.5887-.00273l-22.02171-12.73074A3.1947,3.1947,0,0,1,445.82094,535.05069Z"/>
      <path class="cls-38" d="M472.61592,523.19991a7.46319,7.46319,0,0,1,3.72563.99791l22.04106,12.718a1.06479,1.06479,0,0,1,.00012,1.84448l-22.01852,12.71226a7.45493,7.45493,0,0,1-7.45794-.002l-22.02162-12.73108a1.06467,1.06467,0,0,1,.00074-1.844l22.00515-12.69765a7.46224,7.46224,0,0,1,3.72538-.99791m0-2.12981a9.57761,9.57761,0,0,0-4.78991,1.283l-22.005,12.69764a3.19457,3.19457,0,0,0-.00223,5.53271l22.02162,12.73084a9.58422,9.58422,0,0,0,9.58875.00272l22.01852-12.71225a3.1946,3.1946,0,0,0-.00062-5.53371l-22.04106-12.718a9.57776,9.57776,0,0,0-4.79-1.283Z"/>
      <g>
        <path class="cls-37" d="M493.751,532.423H451.517v9.00222l17.55414,10.12912a7.1235,7.1235,0,0,0,7.127.002l17.553-10.11859Z"/>
        <path class="cls-65" d="M452.70472,530.353l16.35563-9.43767a7.12358,7.12358,0,0,1,7.1205,0l16.3824,9.45282a2.37452,2.37452,0,0,1,.00051,4.1131l-16.36569,9.44857a7.12356,7.12356,0,0,1-7.127-.002l-16.368-9.46235A2.37452,2.37452,0,0,1,452.70472,530.353Z"/>
      </g>
    </g>
    <g>
      <path class="cls-66" d="M406.19278,588.49305H349.37065v37.43217h.0024a3.162,3.162,0,0,0,1.59339,2.74792l22.02173,12.73076a9.584,9.584,0,0,0,9.58871.00273l22.01855-12.71224a3.16454,3.16454,0,0,0,1.59709-2.76917h.00026Z"/>
      <path class="cls-67" d="M350.96867,585.70813l22.005-12.69753a9.5841,9.5841,0,0,1,9.58-.00007l22.04106,12.71793a3.1947,3.1947,0,0,1,.00068,5.5338l-22.01857,12.71221a9.5841,9.5841,0,0,1-9.5887-.00273L350.96645,591.241A3.1947,3.1947,0,0,1,350.96867,585.70813Z"/>
      <path class="cls-68" d="M405.57214,603.18511a3.1334,3.1334,0,0,0,.466-.88829c.005-.0152.0137-.02856.01843-.04376a3.19282,3.19282,0,0,0,.14221-.82357l-.006-3.292a4.75124,4.75124,0,0,1-.30343,1.99123,3.12221,3.12221,0,0,1-.4408.69253c-.04572.05251-.10594.1041-.15719.15615a3.30229,3.30229,0,0,1-.69138.56287l-22.01739,12.735a9.59316,9.59316,0,0,1-9.60147,0l-22.0174-12.735a3.30248,3.30248,0,0,1-.69137-.56287c-.05125-.05205-.11147-.10364-.15719-.15615a3.12221,3.12221,0,0,1-.4408-.69253,4.75124,4.75124,0,0,1-.30343-1.99123l-.006,3.292a3.19751,3.19751,0,0,0,.14221.82357c.00472.01567.01359.02856.01854.044a3.13918,3.13918,0,0,0,.4658.88783c.03777.05136.07393.10088.11527.15016a3.1903,3.1903,0,0,0,.85524.75218l22.01751,12.71613a9.5991,9.5991,0,0,0,9.60469,0l22.01751-12.71613a3.19665,3.19665,0,0,0,.85536-.75218C405.49844,603.28553,405.53437,603.23647,405.57214,603.18511Z"/>
    </g>
    <g>
      <polygon class="cls-69" points="705.906 198.99 713.087 186.552 726.943 194.284 719.794 206.683 705.906 198.99"/>
      <ellipse class="cls-69" cx="719.96204" cy="190.52145" rx="4.70591" ry="7.93816" transform="translate(194.98486 718.7664) rotate(-60.00003)"/>
      <ellipse class="cls-16" cx="712.78092" cy="202.95952" rx="4.70591" ry="7.93816" transform="matrix(0.5, -0.86603, 0.86603, 0.5, 180.62236, 718.76614)"/>
    </g>
    <path class="cls-70" d="M499.9439,114.99037v35.6108a9.06584,9.06584,0,0,1-4.53366,7.85167L382.9216,223.3839a3.88536,3.88536,0,0,1-5.82771-3.365V184.4081a9.06582,9.06582,0,0,1,4.53366-7.85167l112.48864-64.93106A3.88536,3.88536,0,0,1,499.9439,114.99037Z"/>
    <path class="cls-71" d="M490.483,130.24533v14.16318a6.25782,6.25782,0,0,1-3.12942,5.41972l-97.986,56.55982a1.87526,1.87526,0,0,1-2.81274-1.62411V190.60076a6.25781,6.25781,0,0,1,3.12943-5.41972l97.986-56.55982A1.87525,1.87525,0,0,1,490.483,130.24533Z"/>
    <path class="cls-72" d="M438.51887,174.755l-46.47631,26.82721a1.581,1.581,0,0,1-2.37132-1.36923v-8.16717a5.27576,5.27576,0,0,1,2.63831-4.56919l46.20932-26.67309Z"/>
    <path class="cls-9" d="M828.65972,209.00309c-5.26312,4.86994-5.34486,13.90947-1.10035,15.69013,1.06753-.2468,3.09377-2.03277,4.69445-5.81342a12.13866,12.13866,0,0,0,9.93331,10.55592,7.31791,7.31791,0,0,0,.98862,4.87121c1.5771,2.1094,2.49343-1.15394,2.49343,9.8448,15.70538-8.82961,17.40338-20.36774,10.951-32.03564C851.567,202.97839,837.64421,200.68978,828.65972,209.00309Z"/>
    <path class="cls-73" d="M845.89385,229.18451s2.66216-2.35956,4.65483.08468c1.35694,1.66444,1.33759,5.91632-1.53553,8.16884-1.72871,1.3553-4.29551.34655-4.29551.34655Z"/>
    <path class="cls-74" d="M237.54809,161.742v-8.36015l8.35462-4.81728-2.70958,1.56234a11.27911,11.27911,0,0,0-5.645,9.77116Z"/>
    <polygon class="cls-35" points="438.02 652.493 436.135 653.582 436.135 647.046 438.02 645.957 438.02 652.493"/>
    <polygon class="cls-35" points="358.214 699.202 356.511 700.186 354.332 694.569 356.037 693.386 358.214 699.202"/>
    <polygon class="cls-35" points="516.786 375.344 619.689 315.932 617.898 314.789 514.902 374.255 516.786 375.344"/>
    <polygon class="cls-35" points="444.455 333.554 547.384 274.048 545.567 272.999 442.57 332.465 444.455 333.554"/>
    <g>
      <path class="cls-75" d="M545.89664,287.94506H489.0745v31.77217h.00241a3.16194,3.16194,0,0,0,1.59338,2.74792L512.692,335.19591a9.584,9.584,0,0,0,9.58871.00273l22.01855-12.71224a3.16458,3.16458,0,0,0,1.59709-2.76917h.00026Z"/>
      <path class="cls-37" d="M490.67253,285.16015l22.005-12.69754a9.5841,9.5841,0,0,1,9.58-.00006l22.04106,12.71793a3.1947,3.1947,0,0,1,.00068,5.5338l-22.01857,12.71221a9.5841,9.5841,0,0,1-9.5887-.00273L490.67031,290.693A3.1947,3.1947,0,0,1,490.67253,285.16015Z"/>
      <path class="cls-38" d="M517.46751,273.30937a7.46319,7.46319,0,0,1,3.72563.99791l22.04106,12.718a1.06479,1.06479,0,0,1,.00012,1.84448L521.2158,301.582a7.45493,7.45493,0,0,1-7.45794-.002l-22.02162-12.73108a1.06467,1.06467,0,0,1,.00074-1.844l22.00515-12.69765a7.46224,7.46224,0,0,1,3.72538-.99791m0-2.12981a9.57767,9.57767,0,0,0-4.78992,1.283l-22.005,12.69764a3.19458,3.19458,0,0,0-.00223,5.53272L512.692,303.42374a9.58426,9.58426,0,0,0,9.58875.00273l22.01852-12.71226a3.1946,3.1946,0,0,0-.00062-5.53371l-22.04106-12.71795a9.57776,9.57776,0,0,0-4.79-1.283Z"/>
      <g>
        <path class="cls-37" d="M538.60261,282.53244H496.36855v9.00222l17.55414,10.12912a7.1235,7.1235,0,0,0,7.127.002l17.553-10.11859Z"/>
        <path class="cls-76" d="M497.55631,280.4625l16.35563-9.43766a7.12356,7.12356,0,0,1,7.1205,0l16.3824,9.45282a2.37452,2.37452,0,0,1,.00051,4.1131l-16.36569,9.44857a7.12353,7.12353,0,0,1-7.127-.002l-16.368-9.46234A2.37453,2.37453,0,0,1,497.55631,280.4625Z"/>
      </g>
    </g>
    <polygon class="cls-35" points="520.057 605.117 518.172 606.205 518.172 599.669 520.057 598.58 520.057 605.117"/>
    <polygon class="cls-35" points="602.097 557.713 600.212 558.801 600.212 552.265 602.097 551.176 602.097 557.713"/>
    <polygon class="cls-35" points="766.172 462.943 764.287 464.031 764.287 457.495 766.172 456.406 766.172 462.943"/>
    <polygon class="cls-35" points="684.136 510.329 682.251 511.418 682.251 504.882 684.136 503.793 684.136 510.329"/>
    <g>
      <g>
        <path class="cls-77" d="M333.58493,441.36877c-4.34577-2.50926-8.45774-2.94407-11.54812-1.37891l-45.80932,26.463,20.02908,18.59656,8.855,45.67473,45.30764-26.19487V470.405C350.41921,459.73652,342.88212,446.73656,333.58493,441.36877Z"/>
        <path class="cls-78" d="M339.82276,476.53764l1.94755,33.016-23.916,13.81107V489.24854c0-10.6781-7.54331-23.65837-16.84992-29.04645-4.335-2.49815-8.44947-2.939-11.53538-1.3715l21.96863-12.68639c3.08591-1.56747,7.2004-1.15116,11.53523,1.37149C332.2796,452.87926,339.82276,465.88405,339.82276,476.53764Z"/>
        <polygon class="cls-79" points="317.854 510.355 333.174 501.675 317.854 492.474 317.854 510.355"/>
        <path class="cls-80" d="M248.37484,497.96714l25.87-29.859c3.11833-3.59917,8.38756-3.80363,14.03254-.5445l0,0c9.29726,5.36778,16.83418,18.36779,16.83418,29.03634V530.7241Z"/>
      </g>
      <ellipse class="cls-81" cx="284.96037" cy="488.8551" rx="5.64878" ry="9.80507" transform="translate(-206.2501 207.97435) rotate(-30)"/>
      <ellipse class="cls-82" cx="286.1336" cy="488.17774" rx="4.29405" ry="7.45355" transform="translate(-205.75424 208.47022) rotate(-30)"/>
      <g>
        <path class="cls-83" d="M518.7824,408.34128,376.46012,282.86112c-9.72917-8.57781-19.61555-10.725-26.17744-6.79737l-26.30454,15.14906L503.703,482.66306l28.08381-16.17821a15.77378,15.77378,0,0,0,6.11645-9.3256C541.71453,442.86856,533.15383,421.012,518.7824,408.34128Z"/>
        <path class="cls-84" d="M317.45924,300.8314l0,0c3.81125-14.29067,18.5512-15.60389,32.92261-2.93317L492.70419,423.37834c14.3714,12.67072,22.9321,34.52725,19.12085,48.81791l0,0c-3.81126,14.29067-18.55121,15.60389-32.92262,2.93317L336.58009,349.64931C322.20869,336.97859,313.648,315.12206,317.45924,300.8314Z"/>
        <path class="cls-82" d="M487.271,436.438c8.13056,4.69418,14.7217,16.15807,14.72168,25.60541,0,4.6619-1.60958,7.95393-4.2124,9.53652l.00435.00783-.08693.05-.03861.02226L484.2206,479.4l-20.40627-36.43468L476.703,435.549c.119-.07408.24079-.14275.36408-.20951l.24566-.14136.01048.0187C479.94441,433.931,483.43027,434.22051,487.271,436.438Z"/>
        <path class="cls-82" d="M337.2812,298.70343c8.13055,4.69418,14.72169,16.15807,14.72168,25.60541,0,4.6619-1.60958,7.95393-4.2124,9.53652l.00434.00783-.08692.05-.03862.02226-13.4385,7.73993-20.40627-36.43468,12.88867-7.41628c.119-.07408.2408-.14275.36408-.20951l.24567-.14135.01048.01869C329.9546,296.19645,333.44046,296.486,337.2812,298.70343Z"/>
        <g>
          <path class="cls-85" d="M378.423,466.87858,351.58463,340.15c-3.95693-18.606-18.695-36.66516-32.91843-40.33659h-.00013c-4.583-1.183-8.54113-.70839-11.68547,1.07451l-.00739-.0219-27.01492,15.67456,64.89688,191.79421,26.54862-15.41186-.00091-.00246C378.10188,489.21935,381.12129,479.566,378.423,466.87858Z"/>
          <path class="cls-86" d="M333.25119,509.338l0,0c-14.22347-3.6714-28.96158-21.73069-32.91849-40.33655L273.49427,342.27276c-3.95691-18.60586,4.36578-30.71264,18.58925-27.04124l0,0c14.22347,3.6714,28.96158,21.73069,32.91849,40.33655l26.83839,126.72863C355.79736,500.90257,347.47466,513.00935,333.25119,509.338Z"/>
        </g>
        <ellipse class="cls-81" cx="326.31376" cy="480.81849" rx="8.30002" ry="14.40705" transform="matrix(0.86603, -0.5, 0.5, 0.86603, -196.69149, 227.57435)"/>
        <ellipse class="cls-82" cx="327.88622" cy="479.91063" rx="6.4843" ry="11.25535" transform="translate(-196.02689 228.23895) rotate(-30)"/>
        <ellipse class="cls-81" cx="296.02918" cy="344.12811" rx="8.30002" ry="14.40705" transform="translate(-132.40366 194.11901) rotate(-30)"/>
        <ellipse class="cls-82" cx="297.55128" cy="343.24932" rx="6.54244" ry="11.35627" transform="translate(-131.76035 194.76233) rotate(-30)"/>
        <g>
          <path class="cls-87" d="M455.63915,528.64453l-7.61948-13.47328a7.09867,7.09867,0,0,1,1.19016-7.12169l7.27613-8.15041,17.53417-.7075-16.5,13.92362,1.343,13.66891Z"/>
          <path class="cls-82" d="M482.13416,511.09748c-5.93036,3.5355-15.65439,3.65411-21.71927.26485-2.99277-1.67249-4.52872-3.88679-4.61093-6.12547l-.00658,0-.00094-.07376-.00044-.03277-.14769-23.35894,30.71066-.02914.13718,22.88985c.00486.103.00525.2058.00388.30889l.00261.20842-.01576,0C486.372,507.29343,484.93556,509.42737,482.13416,511.09748Z"/>
          <path class="cls-88" d="M474.02013,513.65574l0-4.7a3.02714,3.02714,0,0,1,1.48656-2.60578l9.67944-5.72261a.85517.85517,0,0,1,1.29038.731l.01963,3.27371A16.20182,16.20182,0,0,1,474.02013,513.65574Z"/>
          <path class="cls-89" d="M477.40039,435.53054c11.67513,6.74064,21.13972,23.20229,21.1397,36.76827-.00006,6.69428-2.31129,11.42149-6.04882,13.694l.00624.01124-.12482.07185-.05546.032-19.2971,11.1142L443.7176,444.90347,462.22518,434.254c.17088-.10637.34577-.205.5228-.30084l.35277-.203.015.02684C466.87971,431.93062,471.88525,432.34638,477.40039,435.53054Z"/>
          <ellipse class="cls-90" cx="458.35332" cy="471.07412" rx="17.31426" ry="29.99036" transform="translate(-173.15068 288.86191) rotate(-29.68934)"/>
          <ellipse class="cls-81" cx="458.35332" cy="471.07412" rx="10.45804" ry="18.11457" transform="translate(-173.15068 288.86191) rotate(-29.68934)"/>
          <ellipse class="cls-82" cx="460.62295" cy="469.7801" rx="7.84544" ry="13.58923" transform="translate(-172.21181 289.81618) rotate(-29.68934)"/>
        </g>
        <path class="cls-91" d="M355.34132,357.88874l-7.22745,12.64644a9.62618,9.62618,0,0,0-1.05971,6.77074l13.64889,64.44891a9.62615,9.62615,0,0,0,6.99423,7.32182l7.36115,1.91456Z"/>
      </g>
    </g>
    <polygon class="cls-92" points="493.245 189.264 479.875 229.214 383.793 285.026 383.793 252.43 493.245 189.264"/>
    <polygon class="cls-35" points="514.902 381.06 516.787 382.461 516.787 375.339 514.902 374.25 514.902 381.06"/>
    <polygon class="cls-35" points="442.57 339.278 444.455 340.679 444.455 333.554 442.57 332.468 442.57 339.278"/>
    <g>
      <ellipse class="cls-93" cx="182.09288" cy="493.08356" rx="6.80114" ry="3.91819" transform="translate(-335.97645 404.23884) rotate(-60)"/>
      <ellipse class="cls-93" cx="197.60205" cy="483.42691" rx="6.80114" ry="3.91819" transform="translate(-319.85896 412.84185) rotate(-60)"/>
      <ellipse class="cls-93" cx="212.81859" cy="474.5042" rx="6.80114" ry="3.91819" transform="translate(-304.52339 421.55841) rotate(-60)"/>
    </g>
    <path class="cls-94" d="M476.9184,508.858l7.49887-4.40821a1.99371,1.99371,0,0,1,2.49564.3888l13.0977,14.62786a5.463,5.463,0,0,1,.54321,6.57056L488.147,545.5954l-4.07415,2.35217,5.18457-20.95828-12.9443-14.06164A2.64371,2.64371,0,0,1,476.9184,508.858Z"/>
    <polygon class="cls-22" points="708.127 208.473 696.149 219.62 695.519 227.745 708.127 208.473"/>
    <path class="cls-13" d="M797.98248,300.0443l-2.13178.74983c-1.83581.64573-2.91188,2.0452-2.69276,3.50206l.87,5.78464a2.914,2.914,0,0,0,1.32823,1.92672l2.62628,1.79057Z"/>
    <g>
      <path class="cls-16" d="M797.65859,298.46333v16.91575c0,2.09073,3.654,3.78567,8.16144,3.78567s8.16144-1.69494,8.16144-3.78567V298.46333Z"/>
      <ellipse class="cls-95" cx="805.81999" cy="298.4633" rx="8.16144" ry="3.78563"/>
      <path class="cls-96" d="M812.87837,300.36025c-1.40381,1.13655-4.04446,1.88875-7.05273,1.88875-3.025,0-5.66565-.7522-7.06946-1.88875,1.40381-1.13636,4.04445-1.90508,7.06946-1.90508C808.83391,298.45517,811.47456,299.22389,812.87837,300.36025Z"/>
      <path class="cls-16" d="M805.82,295.51334c4.19288,0,7.32581,1.55744,7.32581,2.95,0,1.39239-3.13293,2.94983-7.32581,2.94983s-7.3258-1.55744-7.3258-2.94983c0-1.39259,3.13293-2.95,7.3258-2.95m0-.83563c-4.50746,0-8.16144,1.69494-8.16144,3.78566s3.654,3.78547,8.16144,3.78547,8.16145-1.69474,8.16145-3.78547-3.654-3.78566-8.16145-3.78566Z"/>
    </g>
    <polygon class="cls-7" points="853.018 289.197 859.1 346.545 865.701 335.442 862.705 323.517 864.128 317.871 853.018 289.197"/>
    <g>
      <ellipse class="cls-97" cx="756.76115" cy="625.73478" rx="6.80114" ry="3.91819" transform="translate(-163.52165 968.24177) rotate(-60)"/>
      <ellipse class="cls-97" cx="772.27032" cy="616.07813" rx="6.80114" ry="3.91819" transform="matrix(0.5, -0.86603, 0.86603, 0.5, -147.40415, 976.84478)"/>
      <ellipse class="cls-97" cx="787.48686" cy="607.15542" rx="6.80114" ry="3.91819" transform="translate(-132.06859 985.56134) rotate(-60)"/>
    </g>
    <g>
      <path class="cls-98" d="M514.90154,163.33559v66.99723a10.87788,10.87788,0,0,0,5.43984,9.42105l134.97277,77.90942a4.662,4.662,0,0,0,6.99254-4.0376V246.62847a10.87788,10.87788,0,0,0-5.43984-9.421L521.89408,159.298A4.662,4.662,0,0,0,514.90154,163.33559Z"/>
      <path class="cls-99" d="M642.67933,254.39723c-24.4018-13.33061-27.91658-12.15915-39.30654-11.76218-9.7982.34149-17.145,6.80717-27.02324,1.524-7.48164-4.00139-11.351-14.49663-18.98327-18.29869s-25.43444,3.89584-25.43444,3.89584L642.67933,293.861Z"/>
      <path class="cls-100" d="M531.93184,229.7562s17.80218-7.69791,25.43444-3.89584,11.50163,14.2973,18.98327,18.29869c9.87826,5.28317,17.225-1.18251,27.02324-1.524,11.39-.397,14.90474-1.56843,39.30654,11.76218"/>
      <path class="cls-101" d="M548.12671,192.071l-22.19634-12.848a2.19362,2.19362,0,0,1-1.0947-1.89851v-3.97871a1.0968,1.0968,0,0,1,1.64626-.94925l22.19635,12.848a2.19362,2.19362,0,0,1,1.09469,1.8985v3.97871A1.09681,1.09681,0,0,1,548.12671,192.071Z"/>
    </g>
  </g>
</svg>
</div>
</template>

<script>
export default {
   name: 'automationsvg',
   created(){
   }
}
</script>

<style scoped>

      #automationsvg .cls-1 {
        fill: #c3defc;
      }

      #automationsvg .cls-2 {
        fill: #5a92e0;
        opacity: 0.2;
      }

      #automationsvg .cls-101, #automationsvg .cls-3 {
        opacity: 0.5;
      }

      #automationsvg .cls-3 {
        fill: url(#linear-gradient-automation);
      }

      #automationsvg .cls-4 {
        fill: #253854;
      }

      #automationsvg .cls-5 {
        fill: #20314c;
      }

      #automationsvg .cls-6 {
        fill: url(#linear-gradient-automation-2);
      }

      #automationsvg .cls-7 {
        fill: #3753b7;
      }

      #automationsvg .cls-8 {
        fill: #2b466d;
      }

      #automationsvg .cls-9 {
        fill: #12303f;
      }

      #automationsvg .cls-10 {
        fill: url(#linear-gradient-automation-3);
      }

      #automationsvg .cls-11 {
        fill: url(#linear-gradient-automation-4);
      }

      #automationsvg .cls-12 {
        fill: url(#linear-gradient-automation-5);
      }

      #automationsvg .cls-13 {
        fill: #f9a49d;
      }

      #automationsvg .cls-14 {
        fill: #d36c6c;
      }

      #automationsvg .cls-15 {
        fill: url(#linear-gradient-automation-6);
      }

      #automationsvg .cls-16 {
        fill: #b4dff9;
      }

      #automationsvg .cls-17 {
        fill: #d36e6e;
      }

      #automationsvg .cls-18 {
        fill: url(#linear-gradient-automation-7);
      }

      #automationsvg .cls-19 {
        fill: #1f4c63;
      }

      #automationsvg .cls-20 {
        fill: #355077;
      }

      #automationsvg .cls-21 {
        fill: #476ba0;
      }

      #automationsvg .cls-22 {
        fill: #5681bf;
      }

      #automationsvg .cls-23 {
        fill: url(#linear-gradient-automation-8);
      }

      #automationsvg .cls-101, #automationsvg .cls-24 {
        fill: #cbe5f4;
      }

      #automationsvg .cls-25 {
        fill: #9ec7ef;
      }

      #automationsvg .cls-26 {
        fill: #282f33;
      }

      #automationsvg .cls-27 {
        fill: url(#linear-gradient-automation-9);
      }

      #automationsvg .cls-28 {
        fill: url(#linear-gradient-automation-10);
      }

      #automationsvg .cls-29 {
        fill: url(#linear-gradient-automation-11);
      }

      #automationsvg .cls-30 {
        fill: url(#linear-gradient-automation-12);
      }

      #automationsvg .cls-31 {
        fill: url(#linear-gradient-automation-13);
      }

      #automationsvg .cls-32 {
        fill: url(#linear-gradient-automation-14);
      }

      #automationsvg .cls-33 {
        fill: #4278c6;
      }

      #automationsvg .cls-34 {
        fill: url(#linear-gradient-automation-15);
      }

      #automationsvg .cls-35, #automationsvg .cls-92 {
        fill: #243b5e;
      }

      #automationsvg .cls-36 {
        fill: url(#linear-gradient-automation-16);
      }

      #automationsvg .cls-37 {
        fill: #4177d8;
      }

      #automationsvg .cls-38 {
        fill: #70b3fc;
      }

      #automationsvg .cls-39 {
        fill: url(#linear-gradient-automation-17);
      }

      #automationsvg .cls-40 {
        fill: #426db2;
      }

      #automationsvg .cls-41 {
        fill: url(#linear-gradient-automation-18);
      }

      #automationsvg .cls-42 {
        fill: url(#linear-gradient-automation-19);
      }

      #automationsvg .cls-43 {
        fill: url(#linear-gradient-automation-20);
      }

      #automationsvg .cls-44 {
        fill: url(#linear-gradient-automation-21);
      }

      #automationsvg .cls-45 {
        fill: url(#linear-gradient-automation-22);
      }

      #automationsvg .cls-46 {
        fill: url(#linear-gradient-automation-23);
      }

      #automationsvg .cls-47 {
        fill: url(#linear-gradient-automation-24);
      }

      #automationsvg .cls-48 {
        fill: url(#linear-gradient-automation-25);
      }

      #automationsvg .cls-49 {
        fill: url(#linear-gradient-automation-26);
      }

      #automationsvg .cls-50 {
        fill: url(#linear-gradient-automation-27);
      }

      #automationsvg .cls-51 {
        fill: #5389e0;
      }

      #automationsvg .cls-52 {
        fill: url(#linear-gradient-automation-28);
      }

      #automationsvg .cls-100, #automationsvg .cls-53 {
        fill: none;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }

      #automationsvg .cls-53 {
        stroke: #76b0e5;
        stroke-width: 5.66001px;
      }

      #automationsvg .cls-54 {
        fill: url(#linear-gradient-automation-29);
      }

      #automationsvg .cls-55 {
        fill: #5074b4;
      }

      #automationsvg .cls-56 {
        fill: #3e5f9c;
      }

      #automationsvg .cls-57 {
        fill: url(#linear-gradient-automation-30);
      }

      #automationsvg .cls-58 {
        fill: url(#linear-gradient-automation-31);
      }

      #automationsvg .cls-59 {
        fill: url(#linear-gradient-automation-32);
      }

      #automationsvg .cls-60 {
        fill: url(#linear-gradient-automation-33);
      }

      #automationsvg .cls-61 {
        fill: url(#linear-gradient-automation-34);
      }

      #automationsvg .cls-62 {
        fill: url(#linear-gradient-automation-35);
      }

      #automationsvg .cls-63 {
        fill: url(#linear-gradient-automation-36);
      }

      #automationsvg .cls-64 {
        fill: url(#linear-gradient-automation-37);
      }

      #automationsvg .cls-65 {
        fill: url(#linear-gradient-automation-38);
      }

      #automationsvg .cls-66 {
        fill: url(#linear-gradient-automation-39);
      }

      #automationsvg .cls-67 {
        fill: url(#linear-gradient-automation-40);
      }

      #automationsvg .cls-68 {
        fill: #2e5daf;
      }

      #automationsvg .cls-69 {
        fill: #cfefff;
      }

      #automationsvg .cls-70, #automationsvg .cls-98 {
        opacity: 0.7;
      }

      #automationsvg .cls-70 {
        fill: url(#linear-gradient-automation-41);
      }

      #automationsvg .cls-71 {
        fill: #24335b;
      }

      #automationsvg .cls-71, #automationsvg .cls-92, #automationsvg .cls-99 {
        opacity: 0.4;
      }

      #automationsvg .cls-72 {
        fill: url(#linear-gradient-automation-42);
      }

      #automationsvg .cls-73 {
        fill: url(#linear-gradient-automation-43);
      }

      #automationsvg .cls-74 {
        fill: #f5f9fc;
      }

      #automationsvg .cls-75 {
        fill: url(#linear-gradient-automation-44);
      }

      #automationsvg .cls-76 {
        fill: url(#linear-gradient-automation-45);
      }

      #automationsvg .cls-77 {
        fill: url(#linear-gradient-automation-46);
      }

      #automationsvg .cls-78 {
        fill: #254a7f;
      }

      #automationsvg .cls-79 {
        fill: #3e75c6;
      }

      #automationsvg .cls-80 {
        fill: url(#linear-gradient-automation-47);
      }

      #automationsvg .cls-81 {
        fill: #b26242;
      }

      #automationsvg .cls-82 {
        fill: #2a4570;
      }

      #automationsvg .cls-83 {
        fill: url(#linear-gradient-automation-48);
      }

      #automationsvg .cls-84 {
        fill: url(#linear-gradient-automation-49);
      }

      #automationsvg .cls-85 {
        fill: url(#linear-gradient-automation-50);
      }

      #automationsvg .cls-86 {
        fill: url(#linear-gradient-automation-51);
      }

      #automationsvg .cls-87 {
        fill: url(#linear-gradient-automation-52);
      }

      #automationsvg .cls-88 {
        fill: #1d2e47;
      }

      #automationsvg .cls-89 {
        fill: url(#linear-gradient-automation-53);
      }

      #automationsvg .cls-90 {
        fill: url(#linear-gradient-automation-54);
      }

      #automationsvg .cls-91 {
        fill: url(#linear-gradient-automation-55);
      }

      #automationsvg .cls-93 {
        fill: #8ac1ff;
      }

      #automationsvg .cls-94 {
        fill: url(#linear-gradient-automation-56);
      }

      #automationsvg .cls-95 {
        fill: #92c1ef;
      }

      #automationsvg .cls-96 {
        fill: #5b434f;
      }

      #automationsvg .cls-97 {
        fill: #a7daff;
      }

      #automationsvg .cls-98 {
        fill: url(#linear-gradient-automation-57);
      }

      #automationsvg .cls-99 {
        fill: url(#linear-gradient-automation-58);
      }

      #automationsvg .cls-100 {
        stroke: #c5e5ff;
        stroke-width: 2.48147px;
      }
    </style>